<template>
  <div class="STOCK">
    <Nav />
    <div class="container">
      <div class="row rowPro">
        <div class="col-sm-6">
          <div class="swiper-container gallery-top">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in proDetail.img_url"
                :key="index"
              >
                <img :src="item" alt="" />
              </div>
              <!-- <div class="swiper-slide">
                <img src="../assets/stock/4.jpg" alt="" />
              </div>

              <div class="swiper-slide">
                <img src="../assets/stock/5.jpg" alt="" />
              </div> -->
            </div>
            <!-- Add Arrows -->
            <div class="swiper-button-next swiper-button-white"></div>
            <div class="swiper-button-prev swiper-button-white"></div>
          </div>
          <div class="swiper-container gallery-thumbs">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(items, index) in proDetail.img_url"
                :key="index"
              >
                <img :src="items" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 proshow_right" v-loading="loading">
          <h5>
            {{ proDetail.goods_name }}
          </h5>
          <span class="descP">{{ proDetail.selling_point }}</span>
          <el-button
            type="warning"
            class="loginTo"
            v-show="!showPrices"
            @click="loginPro"
            >Login to view prices and pay</el-button
          >
          <b class="priceGoods" v-show="showPrices">${{ priceGoods }}</b>
          <div class="customSize" @click="dialogFormVisible = true">
            Customize your hair systems <i class="el-icon-edit"></i>
          </div>
          <el-dialog
            title="Customize Your Products"
            :visible.sync="dialogFormVisible"
          >
            <el-form :model="form">
              <el-row :gutter="20">
                <el-col :span="12"
                  ><el-form-item>
                    <el-input
                      v-model="form.name"
                      autocomplete="off"
                      placeholder="Name *"
                    ></el-input> </el-form-item
                ></el-col>
                <el-col :span="12"
                  ><el-form-item>
                    <el-input
                      v-model="form.email"
                      autocomplete="off"
                      placeholder="Email Address *"
                    ></el-input> </el-form-item
                ></el-col>
                <el-col :span="12"
                  ><el-form-item>
                    <el-select v-model="form.country" placeholder="Country">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.label"
                      >
                      </el-option>
                    </el-select> </el-form-item
                ></el-col>
                <el-col :span="12"
                  ><el-form-item>
                    <el-input
                      v-model="form.phone"
                      autocomplete="off"
                      placeholder="Phone/Whatsapp *"
                    ></el-input> </el-form-item
                ></el-col>
                <el-col :span="24"
                  ><el-form-item>
                    <el-input
                      v-model="form.content"
                      placeholder="Please let us know if you have your own brand/instagram/facebook/youtube/website, we have a great discount for wholesale orders now!  *"
                      type="textarea"
                      autocomplete="off"
                    ></el-input> </el-form-item
                ></el-col>
              </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button class="cancelCustom" @click="dialogFormVisible = false"
                >取 消</el-button
              >
              <el-button
                class="submitCustom"
                type="primary"
                @click="submitCustom"
                >确 定</el-button
              >
            </div>
          </el-dialog>
          <div>
            <b>Payment method:</b>
            <ul>
              <!-- PayPal Logo -->
              <table border="0" cellpadding="10" cellspacing="0" align="center">
                <tr>
                  <td align="center"></td>
                </tr>
                <tr>
                  <td align="center">
                    <a
                      href="https://www.paypal.com/c2/webapps/mpp/paypal-popup"
                      title="How PayPal Works"
                      onclick="javascript:window.open('https://www.paypal.com/c2/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"
                      ><img
                        src="https://www.paypalobjects.com/webstatic/mktg/logo/PP_AcceptanceMarkTray-NoDiscover_243x40.png"
                        alt="Buy now with PayPal"
                    /></a>
                  </td>
                </tr>
              </table>
              <!-- PayPal Logo -->
            </ul>
          </div>
          <div class="serverLi">
            <b>Service:</b>
            <li><i class="el-icon-ship"></i>Worldwide Shipping</li>
            <li>
              <i class="el-icon-shopping-cart-full"></i>30-Day Free Return
            </li>
            <li><i class="el-icon-bank-card"></i>Secure Payment</li>
          </div>

          <div class="typeGoods">
            <!-- <div class="typeDiv" v-for="(item, findex) in divs" :key="findex">
              <h5>{{ item.spec_name }}</h5>
              <ul>
                <li
                  @click="typeClick(items.spec_value, findex, sindex)"
                  v-for="(items, sindex) in item.spec_values"
                  :key="sindex"
                  :class="items.active == true ? 'active' : ''"
                >
                  {{ items.spec_value }}
                </li>
              </ul>
            </div> -->
            <div
              class="item-type"
              v-for="(item, findex) in proDetail.spac"
              :key="findex"
            >
              <b style="display: flex">
                <p style="margin-right: 10px">{{ item.spec_name }}:</p>
                <p :style="item.trueValue ? '' : 'opacity:.5'">
                  {{ item.trueValue ? item.trueValue : "No selection" }}
                </p>
              </b>
              <ul>
                <li
                  v-for="(items, sindex) in item.spec_values"
                  @click="typeClick(items.spec_value, findex, sindex)"
                  :key="sindex"
                  :class="items.active == true ? 'active' : ''"
                >
                  {{ items.spec_value }}
                  <!-- <img src="../assets/thum/1.jpg" alt="" />

                  <div class="bigThum">
                    <img src="../assets/thum/1.jpg" alt="" />
                    <b>#1</b>
                  </div> -->
                </li>
              </ul>
            </div>

            <div class="item-type">
              <b style="display: flex">
                <p style="margin-right: 10px">Hair Color:</p>
                <p
                  :style="this.colorName == 'No selection' ? 'opacity:.5' : ''"
                >
                  {{ colorName ? colorName : "No selection" }}
                </p>
              </b>
              <ul>
                <li
                  class="colorLi"
                  v-for="(item, index) in skuData"
                  :key="index"
                  @click="specClick(index, item.stock_num)"
                  :class="
                    item.active == true
                      ? 'active'
                      : item.stock_num <= 0
                      ? 'noInven'
                      : ''
                  "
                >
                  <img
                    :src="item.file_url + '/' + item.file_name"
                    :alt="item.file_name"
                  />
                  <div class="bigThum">
                    <img
                      :src="item.file_url + '/' + item.file_name"
                      :alt="item.file_name"
                    />
                    <b>{{ item.file_name }}</b>
                  </div>
                </li>
              </ul>
            </div>
            <div class="countAdd">
              <el-input-number
                v-model="num"
                :disabled="disabled"
                :min="minNum"
                :max="maxNum"
                size="small"
                @change="changeNum"
              ></el-input-number>
              <!-- <el-button
                type="warning"
                class="paypalNow"
                :disabled="paydisabled"
                @click="payPal"
                >PayPal</el-button
              > -->
            </div>
            <PayPal
              v-show="showPaypal && this.priceArray !== '' && isLogin"
              :amount="goodsPrice"
              currency="USD"
              locale="en_US"
              :client="credentials"
              env="sandbox"
              :button-style="buttonStyle"
              @payment-authorized="paymentAuthorized"
              @payment-completed="paymentCompleted"
              @payment-cancelled="paymentCancelled"
              class="paypalClass"
            >
            </PayPal>
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <h5>Description</h5>

        <div style="white-space: pre-wrap" v-html="this.resContent"></div>
        <p style="white-space: pre-wrap" v-html="this.resContent"></p>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { getGoodsDetailsApi, addOrderListApi } from "../api/index";
import countryJson from "../utils/country";
import { escape } from "../utils/validate";
// Vue.component("paypal-checkout", PayPal);
import PayPal from "vue-paypal-checkout";
export default {
  name: "productshow",
  components: {
    Nav,
    Footer,
    PayPal,
  },
  data() {
    return {
      galleryThumbs: null, //缩略图
      galleryTop: null, //组图
      priceGoods: "", //价格
      disabled: false, //是否展示计数器 （库存）
      num: 1,
      minNum: 1,
      maxNum: 15,
      paydisabled: false, // 展示是否可付款 检测是否登录
      loading: false, //更换属性加载动画
      dialogFormVisible: false, //定制信息提交  弹出框
      form: {
        name: "",
        email: "",
        country: "",
        phone: "",
        content: "",
      },
      showPrices: false,
      divs: [],
      proConent: "", //产品详情
      proDetail: "", //产品详情 对象
      colorName: "", // 所选择颜色
      chooseGoods: {}, //（颜色）所选择对象
      skuData: [], //颜色对象list
      idsString: "",
      chooseSkuId: "", //选择规格 id
      // paypal 配置项
      credentials: {
        sandbox:
          "AQJGjta1IBh-KiN7YV_i24EyoH6NsEfwIGqiYUJQd48BiIZyeqECQazrvWjI9epzPWSnjp2a1n0n67XQ",
        production:
          "AQJGjta1IBh-KiN7YV_i24EyoH6NsEfwIGqiYUJQd48BiIZyeqECQazrvWjI9epzPWSnjp2a1n0n67XQ",
      },

      buttonStyle: {
        label: "pay",
        size: "large",
        shape: "rect",
        color: "gold",
      },
      showPaypal: false,
      priceArray: [],
      resContent: "",
      isLogin: false,
    };
  },
  created() {
    let token = localStorage.getItem("token");
    if (token) {
      this.showPrices = true;
    } else {
      this.showPrices = false;
    }
    // this.getSpec(12); //测试 获取全部 多规格
    this.options = countryJson; // 国家对象
    if (localStorage.getItem("token")) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  computed: {
    goodsPrice: function () {
      if (this.chooseGoods.goods_price) {
        return Math.imul(this.num, this.chooseGoods.goods_price).toString();
      } else {
        return "0";
      }
    },
  },
  mounted() {
    this.getGoodsDetails(this.$route.query.id); // 获取产品详情
    this.postGoodsSpecSearch(this.idss); //获取全部 hair color
  },
  watch: {},
  beforeRouteUpdate(to, from, next) {
    this.categoryId = to.query.id;
    this.parentId = to.query.parentId;
    next();
  },
  methods: {
    // 数量 绑定值被改变时触发
    changeNum(currentValue, oldValue) {
      this.num = currentValue;
    },
    paymentAuthorized(data) {
      // 授权完成的回调，可以拿到订单id
    },
    paymentCompleted(data) {
      // 用户支付完成的回调，可以拿到订单id
      let user_data = data.payer.payer_info.shipping_address;
      let addOrderData = {};
      addOrderData.token = localStorage.getItem("token");
      addOrderData.goods_id = this.$route.query.id;
      addOrderData.price = Math.imul(this.num, this.chooseGoods.goods_price);
      addOrderData.num = this.num;
      addOrderData.sku_id = this.chooseSkuId;
      addOrderData.order_no = data.id;
      addOrderData.address = user_data.city + " " + user_data.line1;
      // if (!this.chooseSkuId) {
      //   this.$message.error("Please select Specifications!");
      //   return;
      // }
      addOrderListApi(addOrderData).then((res) => {
        if (res && res.code && res.code == 1) {
          this.$message.success(
            "Order success, we will arrange delivery for you as soon as possible! Please check the order details in the personal center"
          );
        }
      });
      // .catch((error) => {
      //   if (error.code == "007") {
      //     this.$message.error(
      //       "Please visit the Personal center to add shipping address!"
      //     );
      //   }
      // });
    },

    paymentCancelled(data) {
      // 用户取消交易的回调
      // console.log(data, 789);
      this.$message.error("You canceled the deal!");
    },
    // 支付
    payPal() {},
    /**
     * 规格点击
     */
    specClick(index, num) {
      let that = this;
      if (num <= 0) {
        this.$message("Inventory is zero!");
        return;
      } else {
        that.skuData.forEach((v, vindex) => {
          if (index == vindex) {
            if (!v.active) {
              v.active = true;
              that.maxNum = num;
              that.chooseGoods = v;
              that.chooseSkuId = v.goods_sku_id;
              that.colorName = v.goods_no;
              this.showPaypal = true;
              this.priceGoods = v.goods_price;
            } else {
              v.active = false;
              that.colorName = "No selection";
              that.maxNum = 1;
              that.chooseSkuId = "";
              this.showPaypal = false;
              this.priceGoods =
                this.priceArray[0] +
                " - $" +
                this.priceArray[this.priceArray.length - 1];
            }
          } else {
            v.active = false;
          }
        });
      }
    },
    // 实例化 swiper
    swiperTrue() {
      this.galleryThumbs = new Swiper(".gallery-thumbs", {
        spaceBetween: 10,
        slidesPerView: 4,
        loop: true,
        freeMode: true,
        loopedSlides: 5, //looped slides should be the same
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });
      this.galleryTop = new Swiper(".gallery-top", {
        spaceBetween: 10,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: this.galleryThumbs,
        },
      });
    },

    /**
     * 规格点击
     */
    typeClick(id, findex, sindex) {
      this.showPaypal = false;
      // this.specId = id;
      let that = this;
      this.proDetail.spac.map((res, b1index) => {
        if (findex == b1index) {
          res.spec_values.map((resSecond, b2index) => {
            if (sindex == b2index) {
              if (!resSecond.active) {
                resSecond.active = true;
                res.trueValue = resSecond.spec_value;
                // this.priceGoods = resSecond
              } else {
                resSecond.active = false;
                res.trueValue = "";
              }
            } else {
              resSecond.active = false;
              //
            }
          });
        }
      });

      let idss = []; //选中的规格id
      this.proDetail.spac.forEach((item) => {
        item.spec_values.forEach((val) => {
          if (val.active) {
            idss.push(val.spec_value_id);
          }
        });
      });
      this.postGoodsSpecSearch(idss);
      // this.specId = idss;
      // this.getGoodsList(this.categoryId, this.specId, this.page); //获取产品列表信息
    },

    /**
     * 商品检索
     */
    postGoodsSpecSearch(ids) {
      if (ids) {
        if (ids.length > 1) {
          ids.sort(function (a, b) {
            return a - b;
          });
        }
        this.idsString = ids.join("_");
      } else {
        this.idsString = "";
      }

      this.$axios({
        // 默认请求方式为get
        method: "post",
        url: "http://www.trustwigs.com/api/goods/sku",
        // 传递参数
        data: {
          gid: this.$route.query.id,
          kid: this.idsString,
        },
        // responseType: "json",
      })
        .then((res) => {
          // 请求成功
          if (res && res.data && res.data.code && res.data.code == 1) {
            res.data.data.map((res) => {
              res.active = false;
            });
            this.skuData = res.data.data;
            let newArray = [];
            this.skuData.map((res) => {
              newArray.push(res.goods_price);
            });
            newArray.sort((a, b) => b - a); //=>[111, 222, 33, 4]
            this.priceArray = newArray;
            this.priceGoods =
              this.priceArray[0] +
              " - $" +
              this.priceArray[this.priceArray.length - 1];
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((error) => {
          // 请求失败，
          console.log(error);
        });
    },

    /**
     * 提交定制表单
     */

    submitCustom() {
      if (!this.form.name || !this.form.phone || !this.form.email) {
        this.$message("A required field was not filled in!");
        return;
      }
      const regEmail =
        /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!regEmail.test(this.form.email)) {
        this.$message.error("Please enter the correct email address!");
        return;
      }
      this.form.goods_id = this.$route.query.id;
      this.$axios({
        method: "post",
        url: "http://www.trustwigs.com/api/goods/single",
        // 传递参数
        data: this.form,
      })
        .then((res) => {
          // 请求成功
          if (res && res.data && res.data.code && res.data.code == 1) {
            this.$message.success(
              "Submit successfully, we will contact you as soon as possible!"
            );
            setTimeout(() => {
              this.$router.go();
            }, 1500);
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((error) => {
          // 请求失败，
          console.log(error);
        });
    },
    /**
     * 产品中心 登录
     */
    loginPro() {
      this.$router.push({ name: "usercenter" });
    },
    /**
     * 获取商品详细信息
     */
    getGoodsDetails(id) {
      let that = this;
      getGoodsDetailsApi(id).then((res) => {
        if (res && res.code && res.code == 1) {
          res.data.spac.map((res) => {
            res.trueValue = "";
            res.spec_values.map((ress) => {
              ress.active = false;
            });
          });
          res.data.goods.map((res) => {
            res.active = false;
            res.colorName = "";
          });
          that.proDetail = res.data;
          // that.resContent = escape(res.data.content);
          that.resContent = res.data.content;
          that.resContent = that.resContent
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&amp;/g, "&")
            .replace(/&quot;/g, '"')
            .replace(/&apos;/g, "'");
          // debugger;
          that.$nextTick(() => {
            that.swiperTrue();
          });
        }
      });
    },
  },
};
</script>
<style lang="less" >
.container {
  max-width: 1280px;
  font-family: "VisbyCF", Sans-serif;
  padding: 2rem 0;
  .rowPro {
    max-height: 780px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .col-sm-6 {
    .swiper-container {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      img {
        // max-width: 100%;
        width: 100%;
      }
    }
    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
    .gallery-top {
      height: 80%;
      width: 100%;
      max-height: 66%;
    }
    .gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0;
    }
    .gallery-thumbs .swiper-slide {
      height: 100%;
      opacity: 0.4;
    }
    .gallery-thumbs .swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  .proshow_right {
    // max-height: 850px;
    // overflow-x: hidden;
    // overflow-y: auto;
    h5 {
      font-family: "VisbyCF", Sans-serif;
      font-weight: 700;
      font-size: 1.875rem;
    }
    span {
      &.descP {
        font-weight: 500;
        line-height: 1.8rem;
        color: #3e3e3e;
        display: block;
      }
    }
    .loginTo {
      display: block;
      background-color: #f29d35;
      text-transform: uppercase;
      width: 100%;
      margin: 15px 0px;
      &:hover {
        background-color: #012340;
        border-color: #012340;
      }
    }
    b {
      &.priceGoods {
        font-size: 22px;
        color: #f29d35;
      }
    }
    .customSize {
      color: #f29d35;
      i {
        color: #012340;
        font-weight: bold;
      }
      &:hover {
        cursor: pointer;
      }
      .el-textarea {
        .el-textarea__inner {
          min-height: 108px !important;
        }
      }
      .el-input__inner {
        color: #212529;
      }
      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #212529;
      }
      input:-moz-placeholder,
      textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #212529;
      }
      input::-moz-placeholder,
      textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #212529;
      }
      input:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #212529;
      }
      .submitCustom {
        background-color: #f29d35;
        border-color: #f29d35;
      }

      .el-button:focus,
      .el-button:hover {
        background-color: #f9cc94;
        border-color: #f9cc94;
        color: #fff;
      }
    }
    .serverLi {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        list-style: none;
        text-decoration: none;
        font-size: 14px;
        i {
          margin-right: 5px;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
    .typeGoods {
      .item-type {
        margin-top: 8px;
        b {
          font-weight: 700;
          margin-bottom: 8px;
          display: block;
        }
        ul {
          margin: 0px;
          li {
            display: inline-block;
            margin: 0px 10px 10px 0px;
            list-style: none;
            text-decoration: none;
            padding: 5px 13px;
            border-radius: 5px;
            cursor: pointer;

            color: #666;
            cursor: pointer;
            &.active {
              background-color: #f29d35;
              color: #fff;
              border: 1px solid #f29d35;
            }
            &.colorLi {
              padding: 0px;
              border: 2px solid transparent;
              position: relative;
              img {
                max-width: 50px;
              }
              .bigThum {
                position: absolute;
                width: 500px;
                top: -1060%;
                right: 0;
                z-index: 999;
                background: #353c4e;
                padding: 10px 10px 5px 10px;
                text-align: right;
                border-radius: 5px;
                display: none;
                // top: 0px;
                img {
                  max-width: 100%;
                  width: auto;
                }

                b {
                  color: #fff;
                }
              }
              &:hover {
                .bigThum {
                  display: block;
                }
              }
            }
            &.noInven {
              &::after {
                content: "";
                width: 100%;
                height: 100%;
                background-color: #fff;
                display: block;
                position: absolute;
                top: 0;
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
    .countAdd {
      margin: 5px 0px;
      .paypalNow {
        width: 120px;
        margin-left: 20px;
        font-weight: 700;
        background-color: #f29d35;
        &:hover {
          background-color: #012340;
          border-color: #012340;
        }
      }
    }
    .paypalClass {
      margin: 15px 0px;
    }
  }
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #e6a23c !important;
}
.el-input-number__decrease:hover,
.el-input-number__increase:hover {
  color: #f29d35 !important;
}
.el-input-number__decrease:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled),
.el-textarea__inner:focus {
  border-color: #f29d35 !important;
}
.el-input-number--small {
  width: 108px;
}
.el-loading-spinner .path {
  stroke: #f29d35;
}
.el-dialog__body .el-select {
  width: 100%;
}
</style>