 <template>
  <div class="toupees">
    <Nav />
    <div class="toupees_content">
      <div v-for="(item, index) in list" :key="index" style="padding: 40px 0">
        <!-- <img :src="item.img" alt="" />
        <h3>{{ item.h3 }}</h3> -->
        <p>{{ item.h5 }}</p>
      </div>
      <div class="content" v-for="(item, index) in content" :key="index">
        <h3>{{ item.title }}</h3>
        <p>{{ item.contents }}</p>
        <h3>{{ item.title1 }}</h3>
        <p>{{ item.contents1 }}</p>
        <img :src="item.img" alt="" />
        <p>{{ item.contents2 }}</p>
        <h3>{{ item.title2 }}</h3>
        <p>{{ item.contents3 }}</p>
        <h3>{{ item.title3 }}</h3>
        <p>{{ item.contents4 }}</p>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      list: [
        {
          img: require("../assets/index/20220624150843.png"),
          h3: "Toupees, wigs, hair extensions, hairpieces: Our hair systems are for everyone!",
          h5: "As a leading manufacturer and supplier for hair replacements, WigsZone caters to customers of all genders, backgrounds, ethnicities and hair types. No one hair system or wig will fit everyone and their needs, though, so it’s imperative for us at WigsZone that we’re able to provide hair replacement options for every possible client.",
        },
      ],
      content: [
        {
          title: "Wigs for all",
          contents:
            "Contrary to popular opinion, wigs aren’t only for women or those who identify as women. While most of our male identifying clients usually go for toupees ⁠— hair systems that cover the main areas of hair loss caused by male pattern baldness ⁠— not everyone can wear them. For one, toupees generally blend with the wearer’s remaining hair, usually on the sides and back of their heads. This helps create a natural looking, full head of hair. However, in some cases, the wearer is completely bald or has very little remaining hair.Our toupees are also generally sized 8’’x10’’ or smaller, so someone requiring a bigger size will likely need a custom order which can be more expensive. In other cases, some people prefer to have more length with their hair so a regular toupee won’t fit their needs. That’s where our full cap wigs come in. These are wigs that not only cover and fit the entire head, but also provide the hair length you desire if you prefer to have those luscious locks with your look.No matter what you identify as, we are confident we’ll have a wig that fits your head size and gives you the beautiful hair you desire.",
          title1: "Different hair types, textures and hairstyles",
          contents1:
            "While the two most popular types of hair we offer in Chinese and Indian Remy human hair are very versatile and can fit most hair types, this doesn’t apply to everyone. Let’s not forget that there are many different types of hair textures as well, so a regular Indian Remy human hair system won’t suit someone with afro-textured hair. That is why we not only offer different types of hair, but textures as well.",
          img: require("../assets/index/20220624164606.png"),
          contents2:
            "Need a perm? We have hair systems that come with a curly hair texture. Need afro-textured hair? We offer systems like the AFRO hair. Prefer silkier European hair with your toupee or wig? We offer European hair as an option as well!Even when it comes to hairstyles, we will do our best to accommodate you. Whether you want a slicked back hairstyle, spiky hair, or even the ability to style it in a bun, our production design team can help you achieve the look you desire whether you’re coming in for an installation or would like your hairpiece to be pre-styled.",
          title2: "Hair toppers and hair extensions",
          contents3:
            "A large number of our clientele opt for human hair wigs. These wigs are ideal for those who have suffered from total hair loss, are currently undergoing chemotherapy, or those who simply want to experiment with different looks and hairstyles. With that said, wigs are not for everyone. Someone dealing with thinning hair or a bald patch in a certain area won’t really benefit by purchasing a wig as it wouldn’t fit their requirements.Enter our wide range of human hair toppers and premium hair extensions. These hairpieces are perfect for concealing hair loss in specific areas or providing additional length and volume. All while blending naturally with your existing hair and not having to shell out for a full cap wig! In addition, all our hairpieces can be further downsized so even those who have petite-sized heads can make use of them.",
          title3: "Custom orders",
          contents4:
            "For everything else, you can always opt for a custom made wig or a custom made hair system. While these are more expensive than our stock options, you can customize your hairpiece down to every detail from the texture, base color, type of hairline and so much more! At WigsZone, you are sure to find the hairpiece that is right for you. Our hairpieces are for everyone!",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.toupees {
  text-align: left;
  width: 100%;
  .toupees_content {
    width: 75%;
    margin: 0 auto;
    .content{
        p{
            padding: 30px 0;
        }
    }
  }
}
</style>