<template>
  <div class="helpones">
    <Nav @gethelpslist="gethelpslist" />
    <div class="helponescont">
      <div class="cont_left">
        <ul style="margin-bottom: 0px">
          <li
            style="
              font-size: 25px;
              color: #fff;
              background-color: #012340;
              padding: 8px 5%;
            "
          >
            HELP CENTER & NEWS
          </li>
        </ul>
        <el-row class="tac">
          <el-col :span="12" style="width: 100%">
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
            >
              <el-submenu
                :index="index + ''"
                v-for="(item, index) in helpList"
                :key="index"
              >
                <template slot="title">
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    :index="index + '-' + sindex"
                    v-for="(items, sindex) in item.list"
                    :key="sindex"
                    @click="niin(items.article_id)"
                    >{{ items.article_title }}</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="cont_right">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/helptemplate' }"
            >HELP CENTER</el-breadcrumb-item
          >
          <el-breadcrumb-item>TEMPLATES AND SAMPLES</el-breadcrumb-item>
          <el-breadcrumb-item style="color: red">{{
            title
          }}</el-breadcrumb-item>
        </el-breadcrumb> -->
        <h4 style="margin: 20px 0">
          {{ title == "" ? "The website is being updated" : title }}
        </h4>
        <div>
          <p v-html="content"></p>
          <p v-if="content == ''">The website is being updated</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
// import getArticleOneApi from "../../api/index ";
import { getArticleOneApi } from "../../api/index";
export default {
  components: { Footer, Nav },
  data() {
    return {
      title: "",
      id: null,
      content: "",
      helpList: [],
    };
  },
  created() {
    let id = this.$route.query.id;
    this.getArticleOne(id);
    this.gethelpslist();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.query.id;
    next();
  },
  watch: {
    id(newVal) {
      if (newVal !== null) {
        this.getArticleOne(newVal);
        debugger;
      }
    },
  },
  methods: {
    niin(id) {
      this.$router.push({ name: "Attaching", query: { id: id } });
    },
    /**
     * 从nav组建中获取已经得到的新闻导航 数据
     */
    gethelpslist(e) {
      this.helpList = e;
    },
    getArticleOne(id) {
      getArticleOneApi(id).then((res) => {
        if (res && res.code && res.code == 1 && res.data && res.data != "") {
          this.title = res.data.article_title;
          this.content = res.data.article_content;
        }
      });
    },
    handleOpen() {},
    handleClose() {},
  },
};
</script>
<style lang="less" scoped>
a {
  list-style: none;
  text-decoration: none;
  color: #000;
}
.helpones {
  width: 100%;
  .helponescont {
    width: 80%;
    margin: 50px auto;
    display: flex;
    justify-content: space-around;
    .cont_left {
      width: 26%;
      h4 {
        border-bottom: 1px solid #e5e5e5;
        width: 85%;
        padding-bottom: 7px;
      }
      h6 {
        padding: 5px 0;
      }
      .el-menu {
        border-right: none;
      }
      .el-submenu .el-menu-item {
        height: 41px;
        line-height: 41px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .cont_right {
      width: 71%;
    }
  }
}
</style>