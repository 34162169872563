<template>
  <div class="Lace">
    <Nav />
    <div class="Lace_content">
      <div class="custom_img">
        <img src="../../../src/assets/index/slick.png" alt="" />
      </div>
      <collapse>
        <div class="p">
          Thin skin hair systems have become more popular and commonly used
          across the globe as a practical hair system option. Thin skin hair
          systems have a base design made of soft and silky polyurethane, that
          emulates the appearance of human skin.
          <span v-show="isActive">
            <p>
              Our ultra-thin skin hair systems are user-friendly as they are
              easy to wash and clean off the glue on the base. Glue gives
              wearers an extra layer of security knowing they don’t need to
              stress about their skin toupee slipping or falling off. We offer a
              wider range of thin skin hair systems categorized by skin
              thickness. In general, the thinner the skin base, the more
              natural-looking the skin base toupee appears and the more
              comfortable it feels.
            </p>
            <p>
              As one of the top men’s skin toupee manufacturers, New Times Hair
              is home to a wide range of thin skin hair systems with both stock
              and custom options, with the thinnest: 0.03mm HS25-V (an all-time
              favorite) followed by HS1, HS1-V, Ins and Sean.
            </p>
            <p>
              At New Times Hair, aside from a large number of pure thin skin
              hair systems models, there are many thin skin hybrid designs
              combining thin skin and other kinds of base material for you and
              your customers to choose from. If you want to know more about our
              thin skin hair systems, please feel free to contact us now!
            </p>
          </span>
        </div>
      </collapse>
      <div
        v-if="showBtn"
        class="btning"
        @click="isActive = !isActive"
        style="
          color: #3e4c6a;
          text-align: right;
          padding-right: 20%;
          width: 100%;
          font-size: 16px;
        "
      >
        More
      </div>
      <div id="produce">
        <div class="produce_left">
          <ul>
            <li style="font-size: 25px; color: #fff; background-color: #012340">
              Product Categories
            </li>
            <router-link to="/Stock"> <li>Stock</li></router-link>
            <router-link to="/Custom"> <li>Custom</li></router-link>
            <router-link to="/Lace"><li>Lace</li></router-link>
            <router-link to="/skin"><li>skin</li></router-link>
            <router-link to="/Mono"><li>Mono</li></router-link>
            <router-link to="/SilkTop"><li>Silk Top</li></router-link>
            <router-link to="/Frontal"><li>Frontal & Partial</li></router-link>
            <router-link to="/Crown"><li>Crown</li></router-link>
            <router-link to="/FullCap"><li>Full Cap</li></router-link>
            <router-link to="/Afro"><li>Afro</li></router-link>
            <router-link to="/Repair"><li>Repair</li></router-link>
            <router-link to="/Duplidete"><li>Duplicate</li></router-link>
          </ul>
        </div>
        <div class="produce_right">
          <div class="right_divs">
            <div v-for="(item, index) in divs" :key="index">
              <p style="font-weight: 600; padding-left: 5%">{{ item.title }}</p>
              <ul style="display: flex; justify-content: left">
                <li v-for="(item, index) in ullis" :key="index">
                  {{ item.lis }}
                </li>
              </ul>
            </div>
            <button class="Apply">Apply fifter</button>
          </div>
          <div class="disnav">
            <div class="navimgs" v-for="(item, index) in navimgs" :key="index">
              <img :src="item.laceimg" alt="" class="laceimg" />
              <img :src="item.laceimg1" alt="" class="laceimg1" />
              <p style="font-size: 14px">{{ item.fonts }}</p>
              <button>{{ item.but1 }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="videoss">
        <img src="../../../src/assets/index/bofang@2x.png" alt="" />
      </div>
      <!-- <div class="gs">
        <div class="goodss">
          <p>
            AFRO BUZZ CUT is Available Now For All Stock Hair Systems. It Only
            Takes $40 for Hair Perming and Cutting.
          </p>
          <el-carousel :interval="4000" type="card" height="400px">
            <el-carousel-item
              v-for="(item, index) in imggoods"
              :key="index"
              class="imggood"
            >
              <img :src="item.imgs" />
            </el-carousel-item>
          </el-carousel>
          <button class="btnss">Learn More</button>
        </div>
      </div> -->
      <div class="before">
        <!-- <h2>Before&After</h2>
        <p>
          See how New Times Hair helps hair stylists transform their clients and
          gain their confidence back!
        </p>
        <div class="imgss">
          <div v-for="(item, index) in imgs" :key="index">
            <img :src="item.img" alt="" />
          </div>
        </div>
        <div class="disnav2">
          <div class="navimgs2" v-for="(item, index) in navimgs2" :key="index">
            <img :src="item.laceimg" alt="" class="laceimg" />
            <img :src="item.laceimg1" alt="" class="laceimg1" />
            <p style="font-size: 14px">{{ item.fonts }}</p>
            <button>{{ item.but1 }}</button>
            <button>{{ item.but2 }}</button>
          </div>
        </div>
        <div class="imgss">
          <div v-for="(item, index) in imgs" :key="index">
            <img :src="item.img" alt="" />
          </div>
        </div> -->
        <div class="disnav2">
          <div class="navimgs2" v-for="(item, index) in navimgs2" :key="index">
            <img :src="item.laceimg" alt="" class="laceimg" />
            <img :src="item.laceimg1" alt="" class="laceimg1" />
            <p style="font-size: 14px">{{ item.fonts }}</p>
            <button>{{ item.but1 }}</button>
            <button>{{ item.but2 }}</button>
          </div>
        </div>
        <div class="BECOMES">
          <div class="Become">
            <div class="Become_dis">
              <img
                src="../../../src/assets/index/Company-Album41024x800.jpg"
                style="height: 500px; width: 100%"
              />
              <div
                class="Become_bg"
                style="background-color: #fff; opacity: 0.7"
              >
                <h2>Become a brand ambassador</h2>
                <ul>
                  <li
                    v-for="(item, index) in listli"
                    :key="index"
                    style="opacity: 1"
                  >
                    {{ item.li }}
                  </li>
                </ul>
                <p style="opacity: 1">
                  And most importantly – be a part of a supportive and uplifting
                  community of hair replacement specialists worldwide!
                </p>
                <p style="text-align: center; font-weight: 600">
                  It’s a rare opportunity, so don’t hesitate to get in before
                  your competition!
                </p>
                <button>Apply now</button>
              </div>
            </div>
          </div>
          <div class="Become">
            <div class="Become_dis">
              <img
                src="../../../src/assets/index/Album3.jpg"
                style="height: 500px; width: 100%"
              />
              <div class="Become_bg">
                <h2>Join us as an exclusive wholesaler</h2>
                <p
                  style="
                    text-align: center;
                    font-weight: 600;
                    color: #f29d35;
                    font-size: 22px;
                  "
                >
                  Why join?
                </p>
                <ul>
                  <li
                    style="font-weight: 600; opacity: 0.9; font-size: 20px"
                    v-for="(item, index) in list"
                    :key="index"
                  >
                    {{ item.li }}
                  </li>
                </ul>
                <p style="opacity: 1">
                  And most importantly – be a part of a supportive and uplifting
                  community of hair replacement specialists worldwide!
                </p>

                <button>Apply now</button>
              </div>
            </div>
          </div>
        </div>
        <div class="FAQ">
          <h2>FAQ</h2>
          <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What are the differences between a toupee and a wig?
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  With more and more people choosing non-surgical hair loss
                  solutions, the men’s hair piece wholesale market has seen a
                  significant increase in the types of stock hairpieces that are
                  designed to cater to people’s needs more precisely and
                  specifically. Therefore, many terms in the male hairpiece
                  world often get mistaken for one another. The most common pair
                  of terms that people confuse with one another is “toupee” and
                  “wig.”<br />

                  Both toupee and wig products are supposed to be worn on
                  people’s heads for the purpose of hiding hair loss, but there
                  are quite a few ways in which they differ from one another. A
                  toupee is usually a small hair piece made of real human hair
                  or synthetic hair that is worn at the top of the wearer’s
                  scalp to cover a bald area, while a wig is meant to offer
                  complete coverage of the whole head. That is why toupees are
                  typically a lot smaller than wigs. Due to the differences in
                  hair loss patterns between men and women, toupees are more
                  commonly used by men to cover the top area of the head, which
                  is more prone to baldness, while wigs are more popular among
                  women who experience hair thinning problems.<br />

                  Generally, it takes a lot more hair material and hair knotting
                  work to produce a wig than it does for a toupee. Therefore,
                  men’s professional wigs are undoubtedly more expensive.
                  However, wigs are commonly seen as a more user-friendly option
                  in the sense that you do not need to shave your head to attach
                  one because tape and glue are not necessary to secure a wig to
                  your head.<br />

                  One other thing to note is that more and more people,
                  including those without hair loss problems, are choosing to
                  wear wigs for fashion purposes. Ultimately, whether you should
                  buy a wig or a toupee for men largely depends on your hair
                  loss pattern. Wholesale men’s toupees are more of an ideal
                  option for most types of partial loss, and wholesale men’s
                  wigs are typically recommended for those with general hair
                  thinning problems or complete baldness.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What is the best men's stock hair systems wholesale?
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  Some placeholder content for the second accordion panel. This
                  panel is hidden by default.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    How canl get the right measurements for my clients?
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  And lastly, the placeholder content for the third and final
                  accordion panel. This panel is hidden by default.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingFOUR">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFOUR"
                    aria-expanded="false"
                    aria-controls="collapseFOUR"
                  >
                    How canl get the right measurements for my clients?
                  </button>
                </h2>
              </div>
              <div
                id="collapseFOUR"
                class="collapse"
                aria-labelledby="headingFOUR"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  How should l install a toupee for my clients?
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingFive">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Why should l purchase men's stock hair systems wholesale
                    from New Times Hair?
                  </button>
                </h2>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  And lastly, the placeholder content for the third and final
                  accordion panel. This panel is hidden by default.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- input框 -->
      <div style="width: 100%; background-color: #f2f2f2">
        <!-- input框 -->
        <div class="CONTACT">
          <h2>INQUIRIES & QUESTIONS</h2>
          <div class="inputs">
            <!-- <input id="type" type="text" placeholder="请选择"> -->

            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.label }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>
          <button @click="submit">Submit</button>
        </div>
        <!-- input框 -->
        <div class="CONTACTs">
          <h2>INQUIRIES & QUESTIONS</h2>
          <div class="inputs">
            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.label }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>

          <button @click="submits">Submit</button>
        </div>
      </div>
      <div class="top" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
      <div class="tops" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      isActive: false,
      showBtn: true,
      one: "",
      divs: [
        {
          title: "BASE SIZE",
        },
        {
          title: "BASE DESIGN",
        },
        {
          title: "MEN'S STOCK HAIR COLOR",
        },
      ],
      ullis: [
        { lis: "8'×11'(1)" },
        { lis: "6'×8'(1)" },
        { lis: "6'×9'(1)" },
        { lis: "8'×11'(1)" },
      ],
      listli: [
        { li: " FREE Hair Systems and Exciting Coupon Codes " },
        { li: " Exclusive WHOLESALE Discounts " },
        { li: "  Business Start-Up Guidance " },
        { li: "  Gain greater Social Media Exposure " },
      ],
      list: [
        { li: "Get the best wholesale prices" },
        { li: "Special offer for sample orders" },
        { li: "Access to product experts" },
      ],
      imggoods: [
        { imgs: require("../../../src/assets/index/goods.png") },
        { imgs: require("../../../src/assets/index/goods.png") },
        { imgs: require("../../../src/assets/index/goods.png") },
        { imgs: require("../../../src/assets/index/goods.png") },
        { imgs: require("../../../src/assets/index/goods.png") },
        { imgs: require("../../../src/assets/index/goods.png") },
      ],
      navimgs: [
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HS7 Full Lace Men’s Hair Piece With Realistic Hairline Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts: "No-Shine Hair Extension Tape Rolls 1/4″ x 6 Yards",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HS7 Full Lace Men’s Hair Piece With Realistic Hairline Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts: "No-Shine Hair Extension Tape Rolls 1/4″ x 6 Yards",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/ns656.jpg"),
          laceimg1: require("../../../src/assets/index/nw1.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      imgs: [
        {
          img: require("../../../src/assets/index/MEN.jpg"),
        },
        {
          img: require("../../../src/assets/index/MEN.jpg"),
        },
        {
          img: require("../../../src/assets/index/MEN.jpg"),
        },
      ],
      country: [],
      navimgs2: [
        {
          laceimg: require("../../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts: "No-Shine Hair Extension Tape Rolls 1/4″ x 6 Yards",
          but1: "Quick View",
          but2: "Login to view prices",
        },
      ],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    showMore() {
      this.showBtn = false;
    },
    submit() {},
    submits() {},
    displiog() {},
  },
};
</script>
<style lang="less" scoped>
.Lace_content {
  .custom_img {
    img {
      width: 100%;
    }
  }
  .p {
    width: 70%;
    text-align: left;
    margin: 20px auto;
    font-size: 17px;
  }
  #produce {
    li {
      list-style: none;
    }
    width: 70%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    .produce_left {
      width: 30%;
      // border: 2px solid #000;
      ul {
        // background-color: #F2F2F2;
        li {
          padding: 8px 5%;
          list-style: none;
          color: #000;
          background-color: #f2f2f2;
        }
        li:hover {
          background-color: #fff;
          width: 100%;
          border-bottom: none;
        }
      }
    }
    .produce_right {
      .right_divs {
        border: 15px solid #f2f2f2;
        padding: 10px 0;
        cursor: pointer;
      }
      width: 68%;

      a {
        // text-decoration: none;
        color: #000;
      }
      ul {
        li {
          padding: 0 1%;
          font-size: 13px;
        }
      }
      .disnav {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .navimgs {
          width: 30%;
          .laceimg1 {
            display: none;
            width: 100%;
          }
          .laceimg {
            width: 100%;
          }
          p {
            height: 40px;
          }
          button {
            color: #fff;
            border: 1px solid #012340;
            background-color: #012340;
            width: 100%;
            padding: 8px 0;
            margin: 5px 0;
          }
          button:hover {
            background-color: #f29d35;
            border: 1px solid #f29d35;
          }
        }

        .navimgs:hover {
          .laceimg {
            display: none;
          }
          .laceimg1 {
            display: block;
          }
        }
      }
    }
  }
  .videoss {
    height: 500px;
    margin: 100px 0;
    background: url("../../../src/assets/index/banner_7@2x.png");
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    img {
      width: 3%;
      margin-left: 45%;
      margin-top: 220px;
    }
    img:hover {
      transform: scale(1.5); //放大图片
      cursor: pointer; //变小手
      transition: all 0.7s;
    }
  }
  .gs {
    width: 100%;
    height: 600px;
    background: url(../../../src/assets/index/banner_1@2x.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .goodss {
      margin: 40px auto;
      width: 70%;
      padding-top: 70px;
      text-align: center;
      p {
        color: #fff;
        font-size: 17px;
        font-weight: 500;
      }
      .el-carousel__item {
        margin: 0 auto;
        img {
          width: 70%;
        }
        .el-carousel__mask {
          background: none;
        }
      }
      .btnss {
        background-color: #09161f;
        color: #fff;
        border: 3px solid #fff;
        padding: 8px 3%;
      }
      .btnss:hover {
        background-color: #09161f;
        color: #f29d35;
        border: 3px solid #f29d35;
      }
    }
    .imggood {
      padding-left: 10%;
    }
  }
  .before {
    width: 70%;
    margin: 40px auto;
    text-align: center;
    .imgss {
      display: flex;
      justify-content: space-around;
      img {
        width: 99%;
      }
    }
  }
  .disnav2 {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .navimgs2 {
      width: 30%;
      .laceimg1 {
        display: none;
        width: 100%;
      }
      .laceimg {
        width: 100%;
      }
      p {
        height: 40px;
      }
      button {
        color: #fff;
        border: 1px solid #012340;
        background-color: #012340;
        width: 100%;
        padding: 8px 0;
        margin: 5px 0;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
      }
    }

    .navimgs2:hover {
      .laceimg {
        display: none;
      }
      .laceimg1 {
        display: block;
      }
    }
  }
  .BECOMES {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .Become {
      // height: 500px;
      width: 49%;
      margin-top: 20px;
      // background: url();
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      // color: #012340;
      .Become_dis {
        position: relative;
      }
      .Become_bg {
        position: absolute;
        top: 25px;
        left: 5%;

        width: 90%;
        padding-top: 50px;
        button {
          border: none;
          border: 1px solid #0a2840;
          background-color: #0a2840;
          color: #fff;
          padding: 5px 3%;
          margin: 20px 0;
          // margin-left: 40%;
          text-align: center;
          border-radius: 18px;
          opacity: 1;
        }
        button:hover {
          background-color: #f29d35;
          border: 1px solid #f29d35;
          border-radius: 18px;
        }
        h2 {
          text-align: center;
          padding: 20px 0;
          opacity: 1;
        }
      }
    }
  }
  .FAQ {
    h2 {
      padding: 20px 0;
      text-align: center;
    }
    button {
      color: #000;
      font-weight: 600;
    }
  }
  .CONTACTs {
    display: none;
  }
  .CONTACT {
    text-align: center;

    padding: 50px 0;
    width: 80%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      input {
        width: 45%;
        height: 50px;
        margin: 20px 0;
        border: none;
        border: 1px solid #cfd5db;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }
  .Apply {
    background-color: #012340;
    border: 1px solid #012340;
    color: #fff;
    padding: 7px 2%;
    margin: 10px 5%;
  }
  .Apply:hover {
    background-color: #f29d35;
    border: 1px solid #f29d35;
  }
  .top {
    img {
      width: 3%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 50px;
    }
  }
  .tops {
    display: none;
  }
}
</style>