<template>
  <div class="all">
      <!-- Swiper -->
        <div class="swiper-container gallery-top">
            <div class="swiper-wrapper">
            <div class="swiper-slide" style="border:1px solid #f00;background-image:url('../assets/index/20220624171957.png');background-size:100% 100%;width:100%;height:400px">
                <!-- <img src="../assets/index/20220624171957.png" alt=""> -->
            </div>
            <div class="swiper-slide" style="border:1px solid #f00;background-image:url('../assets/index/20220624171957.png');background-size:100% 100%;width:100%;height:400px">
                <!-- <img src="../assets/index/20220624171957.png" alt=""> -->
            </div>
            <div class="swiper-slide" >
                <!-- <img src="../assets/index/20220624171957.png" alt=""> -->
            </div>
            <div class="swiper-slide" >
                <!-- <img src="../assets/index/20220624171957.png" alt=""> -->
            </div>
            <div class="swiper-slide" >
                <!-- <img src="../assets/index/20220624171957.png" alt=""> -->
            </div>
            </div>
            <!-- Add Arrows -->
            <div class="swiper-button-next swiper-button-white"></div>
            <div class="swiper-button-prev swiper-button-white"></div>
        </div>
        <div class="swiper-container gallery-thumbs" >
            <div class="swiper-wrapper">
            <div class="swiper-slide " style="border:1px solid #fff">1</div>
            <div class="swiper-slide"  style="border:1px solid #fff">2</div>
            <div class="swiper-slide"  style="border:1px solid #fff">3</div>
            <div class="swiper-slide"  style="border:1px solid #fff">4</div>
            <div class="swiper-slide"  style="border:1px solid #fff">5</div>
            </div>
        </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  data() {
    return {
      // swiper实例  实例上有各种方法
      swiperInstance: null,
    };
  },
  mounted() {
    var swiperInstance = new Swiper(".swiper-container", {
      // config...
    });

var galleryThumbs = new Swiper('.gallery-thumbs', {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
    var galleryTop = new Swiper('.gallery-top', {
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: galleryThumbs
      }
    });


  },
};
</script>
<style lang="less">
 
    .all {
      background: #000;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 14px;
      color:#fff;
      height: 600px;
      margin: 0;
      padding: 0;
    }
    .swiper-container {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
    .gallery-top {
      height: 80%;
      width: 100%;
    }
    .gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0;
    }
    .gallery-thumbs .swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
    }
    .gallery-thumbs .swiper-slide-thumb-active {
      opacity: 1;
    }
</style>