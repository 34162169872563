<template>
  <div class="meetteam">
    <Nav />
    <div class="meetcenter">
      <div class="contentbanner">
        <img src="../assets/index/853.png" />
        <div class="position">
          <!-- <h1>NEW TIMES HAIR</h1> -->
        </div>
      </div>
      <div class="NewTimes">
        <h2>New Times Hair Team</h2>
        <p class="p">
          Our friendly and professional team is on hand to help you before,
          during and after your ordering experience. Allow us to introduce
          ourselves.
        </p>
        <div class="disphots">
          <div v-for="(item, index) in photo" :key="index">
            <img :src="item.img" alt="" />
            <h3>{{ item.name }}</h3>
            <p style="color: #8ac1e6">{{ item.detail }}</p>
          </div>
        </div>
      </div>
      <div class="NewTimesing">
        <h2>New Times Hair Team</h2>
        <p class="p">
          Our friendly and professional team is on hand to help you before,
          during and after your ordering experience. Allow us to introduce
          ourselves.
        </p>
        <div class="disphots">
          <div v-for="(item, index) in photo" :key="index">
            <img :src="item.img" alt="" />
            <h3>{{ item.name }}</h3>
            <p style="color: #8ac1e6">{{ item.detail }}</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      photo: [
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
        {
          img: require("../../src/assets/index/team-8@2x-2.png"),
          name: "Rosie Wang",
          detail: "Sales Representative",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.NewTimesing{display: none;}
.meetteam {
  width: 100%;
  text-align: center;
  .meetcenter {
    .contentbanner {
      position: relative;
      img {
        width: 100%;
        // height: 600px;
      }
      .position {
        text-shadow: #01244b 3px 3px;
        position: absolute;
        left: 35%;
        top: 250px;
        color: #fff;
        h1 {
          font-weight: 700;
        }
        p {
          font-size: 24px;
        }
      }
    }

    .NewTimes {
      padding-top: 50px;
      h2 {
        font-weight: 700;
      }
      .p {
        width: 26%;
        margin: 0 auto;
      }
      .disphots {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 80%;
      margin: 50px auto;

      div {
        width: 25%;
        img {
          width: 90%;
        }
      }
    }
    }
    
  }
}
@media screen and (max-width: 390px) {
  .NewTimes {
    padding-top: 50px;
    h2 {
      font-weight: 700;
    }
    .p {
      width: 80%;
      margin: 0 auto;
    }
  }
  .NewTimes {
    display: none;
  }
  .NewTimesing {
    display: block;
    padding-top: 50px;
    h2 {
      font-weight: 700;
    }
    .p {
      width: 80%;
      margin: 0 auto;
    }
    display: block;

    .disphots {
      width: 80%;
      margin: 50px auto;

      div {
        width: 99%;
        img {
          width: 99%;
        }
      }
    }
  }
}
</style>