<template>
  <div class="supplies">
    <Nav />
    <div class="supplies_content">
      <div class="Extensionspc">
        <div
          v-for="(item, index) in hair"
          :key="index"
          class="HairExtensions_harr"
        >
          <router-link to="/"><img :src="item.imgs" alt="" /></router-link>
          <h4>{{ item.title }}</h4>
          <p class="optic">{{ item.conton }}</p>
        </div>
      </div>
      <p style="width: 70%; margin: 50px auto; text-align: left">
        At New Times Hair, we offer a large variety of highly customizable hair
        system accessories to better navigate your hair system business. With
        the assistance of our vast array of accessories, your business
        performance will surely step up to the next level!
      </p>
      <div class="disnav">
        <div class="navimgs" v-for="(item, index) in navimgs" :key="index">
          <img :src="item.laceimg" alt="" class="laceimg" />
          <img :src="item.laceimg1" alt="" class="laceimg1" />
          <p>{{ item.fonts }}</p>
          <button>{{ item.but1 }}</button>
          <button>{{ item.but2 }}</button>
        </div>
      </div>
      <div class="Hairbox">
        <div v-for="(item, index) in Hairbox" :key="index">
          <img :src="item.img" alt="" />
          <p>{{ item.title }}</p>
          <button>{{ item.but1 }}</button>
          <button>{{ item.but2 }}</button>
        </div>
      </div>
      <div class="disnav">
        <div class="navimgs" v-for="(item, index) in navs" :key="index">
          <img :src="item.laceimg" alt="" class="laceimg" />
          <img :src="item.laceimg1" alt="" class="laceimg1" />
          <p>{{ item.fonts }}</p>
          <button>{{ item.but1 }}</button>
          <button>{{ item.but2 }}</button>
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item active" aria-current="page">
            <span class="page-link">1</span>
          </li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
      <div class="menwomen">
        <img src="../../src/assets/index/smen.jpg" alt="" />
        <img src="../../src/assets/index/women9.jpg" alt="" />
      </div>

      <!-- input框 -->
      <div style="width: 100%; margin-top: 80px; background-color: #f2f2f2">
        <div class="CONTACT">
          <h2>INQUIRIES & QUESTIONS</h2>
          <div class="inputs">
            <!-- <input id="type" type="text" placeholder="请选择"> -->
            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Addres*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              <option v-for="(item, index) in country" :key="index">
                {{ item.label }}
              </option>
            </datalist>
            <input type="text" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name*" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role ... *"
            />
            <datalist id="inputlist">
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Pice tusAknowt cutove yorcw browdrnstayom foceboowdlycuicelvetrate,ay of tem wil tep p.eduptheopcro d poces, ondwehave a greatdsoutfto wholesdle crdes now."
          ></textarea>
          <button @click="submit">Submit</button>
        </div>
        <!-- input框 -->
        <div class="CONTACTs">
          <h2>INQUIRIES & QUESTIONS</h2>
          <div class="inputs">
            <!-- <input id="type" type="text" placeholder="请选择"> -->
            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Addres*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              <option v-for="(item, index) in country" :key="index">
                {{ item.name }}
              </option>
            </datalist>
            <input type="text" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name*" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role ... *"
            />
            <datalist id="inputlist">
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>

          <button @click="submits">Submit</button>
        </div>
      </div>
    </div>
    <Footer />

    <div class="top" @click="toTop">
      <img
        style="width: 3%; height: 50px"
        src="../../src/assets/index/xiala_un@2x.png"
        alt=""
      />
    </div>
    <div class="tops" @click="toTop">
      <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      one: "",
      hair: [
        {
          imgs: require("../../src/assets/index/Ordering-Tools.png"),
          title: "Hair Extension",
          path: "/",
          conton: "Ordering Tools",
        },
        {
          imgs: require("../../src/assets/index/s-Others-1@2x.png"),
          title: "Bulk Hair",
          conton: "Tapes",
        },
        {
          imgs: require("../../src/assets/index/s-Others-1@2x.png"),
          title: "Pre-Bonded Hair",
          conton: "Others",
        },
      ],
      navimgs: [
        {
          laceimg: require("../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts: "No-Shine Hair Extension Tape Rolls 1/4″ x 6 Yards",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
      ],
      Hairbox: [
        {
          img: require("../../src/assets/index/Hair-Curl.jpg"),
          title: "Hair Curl & Wave Ring",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../src/assets/index/Hair-Curl.jpg"),
          title: "Hair Curl & Wave Ring",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../src/assets/index/Hair-Curl.jpg"),
          title: "Hair Curl & Wave Ring",
          but1: "Quick View",
          but2: "Login to view prices",
        },
      ],
      navs: [
        {
          laceimg: require("../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts: "No-Shine Hair Extension Tape Rolls 1/4″ x 6 Yards",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
      listli: [
        { li: " FREE Hair Systems and Exciting Coupon Codes " },
        { li: " Exclusive WHOLESALE Discounts " },
        { li: "  Business Start-Up Guidance " },
        { li: "  Gain greater Social Media Exposure " },
      ],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    submits() {},
    submit() {},
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  img {
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
  }
}
.tops {
  display: none;
}
.supplies {
  .supplies_content {
    .Extensionspc {
      display: flex;
      justify-content: space-around;
      text-align: center;
      .HairExtensions_harr {
        position: relative;
        width: 30%;
        img {
          width: 100%;
        }
        .optic {
          background-color: #ccc;
          opacity: 0.5;
          width: 99%;
          height: 70px;
          line-height: 70px;
          position: absolute;
          bottom: 100px;
          left: 0;
          display: none;
        }
      }
      .HairExtensions_harr:hover {
        .optic {
          display: block;
        }
      }
    }
    .disnav {
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .navimgs {
        width: 30%;
        .laceimg1 {
          display: none;
          width: 90%;
        }
        .laceimg {
          width: 90%;
        }
        p {
          height: 40px;
        }
        button {
          color: #fff;
          border: 1px solid #012340;
          background-color: #012340;
          width: 100%;
          padding: 8px 0;
          margin: 5px 0;
        }
        button:hover {
          background-color: #f29d35;
          border: 1px solid #f29d35;
        }
      }

      .navimgs:hover {
        .laceimg {
          display: none;
        }
        .laceimg1 {
          display: block;
        }
      }
    }
    .Hairbox {
      display: flex;
      justify-content: space-around;
      width: 70%;
      margin: 0 auto;
      div {
        width: 30%;
        button {
          color: #fff;
          border: 1px solid #012340;
          background-color: #012340;
          width: 100%;
          padding: 8px 0;
          margin: 5px 0;
        }
        button:hover {
          background-color: #f29d35;
          border: 1px solid #f29d35;
        }
      }
    }
    .article,
    aside,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    nav,
    section {
      // margin-left: 5%;
      width: 60%;
      margin: 60px auto;
    }
    .page-item.active .page-link {
      background-color: #012340;
      color: #fff;
    }
    .menwomen {
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      img {
        width: 48%;
      }
    }

    .CONTACTs {
      display: none;
    }
    .CONTACT {
      text-align: center;
      padding: 50px 0;
      width: 80%;
      margin: 0 auto;
      h2 {
        font-weight: 600;
      }
      .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        input {
          width: 45%;
          height: 50px;
          margin: 8px 0;
          border: none;
          border: 1px solid #cfd5db;
        }
      }
      textarea {
        border: none;
        border: 1px solid #cfd5db;
        width: 95%;
        height: 200px;
      }
      button {
        margin-top: 30px;
        background-color: #304e6a;
        border: 1px solid #304e6a;
        padding: 10px 1.5%;
        border-radius: 5px;
        color: #fff;
      }
      button:hover {
        background-color: #f9bd5a;
        border: 1px solid #f9bd5a;
      }
    }
  }
}
@media screen and (max-width: 390px) {
  .top {
    display: none;
  }

  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
  .CONTACT {
    display: none;
  }
  .CONTACTs {
    text-align: center;
    display: block;
    padding: 50px 0;
    width: 90%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      input {
        width: 100%;
        height: 50px;
        border: none;
        border: 1px solid #cfd5db;
        margin: 10px 0;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }
}
</style>