<template>
  <div>
    <div class="swiper6">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in bannerList"
          :key="index"
        >
          <img :src="item.src" :alt="item.title" :title="item.title" />
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <!-- <div class="swiper-pagination"></div> -->
      <!-- 如果需要导航按钮 -->
      <div v-if="!this.bannerList">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <!-- 如果需要滚动条 -->
      <!-- <div class="swiper-scrollbar"></div> -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  props: {
    bannerList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      mySwiper: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getSwiper();
    });
    // console.log(this.bannerList);
  },
  created() {},
  methods: {
    getSwiper() {
      this.mySwiper = new Swiper(".swiper6", {
        loop: true, // 循环模式选项
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: true, //可选选项，自动滑动
      });
    },
  },
};
</script>
<style scoped lang="less">
.swiper6 {
  // width: 100%;
  // overflow: hidden;
  img {
    width: 100%;
    max-height: 500px;
  }
}
</style>