<template>
  <div class="VideoGallery">
    <Nav />
    <div class="VideoGallery_content">
      <div>
        <h2>Leave a Reply</h2>
        <p>
          Your email address will not be published. Required fields are marked *
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.VideoGallery {
  .VideoGallery_content {
    width: 100%;
  }
}
</style>