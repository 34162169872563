// Help
<template>
  <div class="about">
    <Nav />
    <div class="aboutcontent">
      <div class="contentbanner">
        <img src="../assets/index/0220623112142.png" />
        <div class="position">
          <h1>NEW TIMES HAIR</h1>
          <p>Your Dedicated Business Partner In Your Success</p>
        </div>
      </div>
      <div class="contentbanners">
        <img src="../assets/index/20220622085219.png" />
        <div class="position">
          <h5>NEW TIMES HAIR</h5>
          <p>Your Dedicated Business Partner In Your Success</p>
        </div>
      </div>
      <p class="pos_fixed">
        <img src="../../src/assets/index/banner_4@2x.png" alt="" />
      </p>
      <div style="background-color: #fff; width: 100%">
        <div class="aboutNews" v-for="(item, index) in aboutnew" :key="index">
          <div style="padding-top: 50px">
            <h2>{{ item.h2 }}</h2>
            <h5>{{ item.h5 }}</h5>
          </div>
          <div class="container">
            <div class="row" style="background-color: #fff">
              <div class="col-sm">
                <div class="conts">
                  <div class="video">
                    <div class="video-s"></div>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div class="cont">
                  <div class="cont-t">{{ item.cont }}</div>
                  <div class="cont-t">{{ item.cont2 }}</div>
                  <div class="cont-t">{{ item.cont3 }}</div>
                  <div class="cont-t">{{ item.cont4 }}</div>
                  <div class="cont-btn">
                    <!-- <router-link to="/Newtimehair"
                      ><button type="button" class="btn btn-outline-warning">
                        FACTORY VR TOUR
                      </button>
                    </router-link> -->
                    <router-link to="/About">
                      <button type="button" class="btn btn-outline-warning">
                        MORE ABOUT US
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="aboutNewsing">
          <div style="padding-top: 50px">
            <h2>About New Times Hair</h2>
            <h5>Wholesale Toupee & Hair System Manufacturer</h5>
          </div>
          <div class="container">
            <div class="row" style="background-color: #fff">
              <div class="col-sm">
                <div class="conts">
                  <div class="video">
                    <div class="video-s"></div>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div class="cont">
                  <div class="cont-t">
                    New Times Hair International Industries Co. LTD is located
                    in Qingdao, China, the global hub for high-end hair system
                    manufacturing. With the business growing for over 10 years,
                    we’re proud to have become one of the leading high-end hair
                    system manufacturers and wholesale toupee supplier. With
                    more than a decade of experience in export trade, our market
                    scope now covers Europe, North America, the Middle East, and
                    India. Being the official supplier of the leading hair
                    replacement distributors in the US, New Times Hair is
                    dedicated to providing them with different hair replacement
                    system products including men’s toupees, women’s wigs,
                    toppers and hair extensions on a long-term basis. We cater
                    to both large and small wholesalers, including online shop
                    owners, salon owners, hair stylists and regional wig and
                    hair system distributors. We welcome you to choose New Times
                    Hair to expand your market and boost your profit.
                  </div>
                  <div class="cont-btn">
                    <router-link to="/Newtimehair"
                      ><button type="button" class="btn btn-outline-warning">
                        FACTORY VR TOUR
                      </button>
                    </router-link>
                    <router-link to="/About">
                      <button type="button" class="btn btn-outline-warning">
                        MORE ABOUT US
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="optaicys">
        <div
          style="
            width: 70%;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;" >
          <div
            v-for="(item, index) in optaicying"
            :key="index"
            class="optaicys_div"
          >
            <img :src="item.imgs" alt="" />
            <h2>{{ item.number }}</h2>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div> -->
      <div class="TsingTaoHair">
        <div class="Tsingbox1" v-for="(item, index) in TaoHair" :key="index">
          <h3>{{ item.h3 }}</h3>
          <p>{{ item.content }}</p>
        </div>
        <div class="Tsingboxdis">
          <div class="Tsingbox2" v-for="(item, index) in Hairicon" :key="index">
            <img :src="item.img" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </div>
        <router-link to="/Lordhair"><button>View More</button></router-link>
      </div>
      <div class="optaicysing">
        <div style="width: 100%">
          <div
            v-for="(item, index) in optaicying"
            :key="index"
            class="optaicys_div"
          >
            <img :src="item.imgs" alt="" />
            <h2>{{ item.number }}</h2>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="ABOUTUS">
        <h2>ABOUT US</h2>
        <div class="ABOUTUS_center">
          <!-- 左文右图 1-->
          <div
            class="center_left"
            v-for="(item, index) in fontsleft"
            :key="index"
          >
            <p>{{ item.title }}</p>
            <p>
              As one of the largest hair system and wig manufacturers for
              distributors, our factory covers an area of 4000 km². We employ
              over 100 skilled craftspeople. Our experienced factory team works
              with the most modern equipment to produce high-quality products.
              Our investment in our factory has allowed us to achieve a high
              level of production to produce over 15,000 hair systems monthly.
            </p>
            <p>
              This allows us to meet all of our clients’ product needs. Each of
              our hairpieces goes through a series of quality control processes.
              This includes six rounds of quality inspections in the production
              process to ensure each hair system meets the highest standards.
              Our clients count on our factory consistently producing the
              highest quality hair systems for their businesses.
            </p>
          </div>
          <div class="center_right">
            <img src="../../src/assets/index/about2.jpg" alt="" />
          </div>
          <!-- 右文左图2 -->
          <div class="center_right">
            <img src="../../src/assets/index/s-about-us2.jpg" alt="" />
          </div>
          <div class="center_left">
            <p>2.Headquarters</p>
            <p>
              Our head office consists of many departments including quality
              control, client service, design, technical support, supply chain,
              and an operations team. Our highly specialized client service team
              is available around the clock. Our team is fluent in English,
              Spanish, French, and German. You can expect fast, friendly service
              when you reach out to them.
            </p>
          </div>
          <!-- 左文右图 3-->
          <div class="center_left">
            <p>
              We value our international clients, so it’s important that we are
              able to communicate with our clients regardless of their time zone
              or location. Our professional staff will ensure you find the hair
              systems you need and the order is carried out efficiently. We have
              an excellent inspection team that guarantees consistent high
              quality throughout production. By offering our products to your
              customers, your brand will be respected and celebrated over time.
            </p>
          </div>
          <div class="center_right">
            <img src="../../src/assets/index/s-about-us-img2.jpg" alt="" />
          </div>
          <!-- 右文左图4 -->
          <div class="center_right">
            <img src="../../src/assets/index/s-about-us-img3.jpg" alt="" />
          </div>
          <div class="center_left">
            <p>
              In addition, we have a team of professional hairstylists in our
              hair service studio who can provide comprehensive toupee services.
              All of our departments are transparent with one another when it
              comes to sharing information. This allows the customer to have a
              seamless experience when working with us. Staff members are fully
              qualified to handle any questions before and after each sale.
            </p>
          </div>
          <!-- 左文右图5 -->
          <div class="center_left">
            <p>3.Warehouse</p>
            <p>
              New Times Hair stocks more than 80,000 hair systems in over 40
              different models and over 50 colors that are ready for immediate
              shipment. There’s a large variety of hair system designs for your
              customers including lace, skin, mono toupees for men, as well as
              full cap wigs, hair toppers, hair integrations, and hair
              extensions for women.
            </p>
            <p>
              We also have many kinds of accessories like tapes, color rings,
              hair volume charts, hair density charts, and even mannequins to
              help you better navigate your business. Everything can be
              delivered to you within 3-5 business days of the shipment date.
            </p>
          </div>
          <div class="center_right">
            <img src="../../src/assets/index/182.jpg" alt="" />
          </div>
          <!-- 右文左图 6-->
          <div class="center_right">
            <img src="../../src/assets/index/s-about-us-img4-1.jpg" alt="" />
          </div>
          <div class="center_left">
            <p>4. Team</p>
            <p>
              We have over 100 members on our team. While dedicated to providing
              our clients with the most professional and considerate services,
              all New Times Hair members are like family. In such a highly
              cooperative and synergistic team, we take great pride in helping
              one another grow and become better. We prioritize a friendly,
              supportive environment and believe that bringing joy and positive
              energy to each member of our team is essential for a business to
              thrive.
            </p>
          </div>
        </div>
      </div>
      <div class="ABOUTUSing">
        <h2>ABOUT US</h2>
        <div class="ABOUTUS_center">
          <div class="center_right">
            <img src="../../src/assets/index/about2.jpg" alt="" />
          </div>
          <div
            class="center_left"
            v-for="(item, index) in fontsleft"
            :key="index"
          >
            <p>{{ item.title }}</p>
            <p>
              As one of the largest hair system and wig manufacturers for
              distributors, our factory covers an area of 4000 km². We employ
              over 100 skilled craftspeople. Our experienced factory team works
              with the most modern equipment to produce high-quality products.
              Our investment in our factory has allowed us to achieve a high
              level of production to produce over 15,000 hair systems monthly.
            </p>
            <p>
              This allows us to meet all of our clients’ product needs. Each of
              our hairpieces goes through a series of quality control processes.
              This includes six rounds of quality inspections in the production
              process to ensure each hair system meets the highest standards.
              Our clients count on our factory consistently producing the
              highest quality hair systems for their businesses.
            </p>
          </div>

          <!-- 右文左图2 -->
          <div class="center_right">
            <img src="../../src/assets/index/s-about-us2.jpg" alt="" />
          </div>
          <div class="center_left">
            <p>2.Headquarters</p>
            <p>
              Our head office consists of many departments including quality
              control, client service, design, technical support, supply chain,
              and an operations team. Our highly specialized client service team
              is available around the clock. Our team is fluent in English,
              Spanish, French, and German. You can expect fast, friendly service
              when you reach out to them.
            </p>
          </div>
          <!-- 左文右图 3-->
          <div class="center_right">
            <img src="../../src/assets/index/s-about-us-img2.jpg" alt="" />
          </div>
          <div class="center_left">
            <p>
              We value our international clients, so it’s important that we are
              able to communicate with our clients regardless of their time zone
              or location. Our professional staff will ensure you find the hair
              systems you need and the order is carried out efficiently. We have
              an excellent inspection team that guarantees consistent high
              quality throughout production. By offering our products to your
              customers, your brand will be respected and celebrated over time.
            </p>
          </div>

          <!-- 右文左图4 -->
          <div class="center_right">
            <img src="../../src/assets/index/s-about-us-img3.jpg" alt="" />
          </div>
          <div class="center_left">
            <p>
              In addition, we have a team of professional hairstylists in our
              hair service studio who can provide comprehensive toupee services.
              All of our departments are transparent with one another when it
              comes to sharing information. This allows the customer to have a
              seamless experience when working with us. Staff members are fully
              qualified to handle any questions before and after each sale.
            </p>
          </div>
          <!-- 左文右图5 -->
          <div class="center_right">
            <img src="../../src/assets/index/182.jpg" alt="" />
          </div>
          <div class="center_left">
            <p>3.Warehouse</p>
            <p>
              New Times Hair stocks more than 80,000 hair systems in over 40
              different models and over 50 colors that are ready for immediate
              shipment. There’s a large variety of hair system designs for your
              customers including lace, skin, mono toupees for men, as well as
              full cap wigs, hair toppers, hair integrations, and hair
              extensions for women.
            </p>
            <p>
              We also have many kinds of accessories like tapes, color rings,
              hair volume charts, hair density charts, and even mannequins to
              help you better navigate your business. Everything can be
              delivered to you within 3-5 business days of the shipment date.
            </p>
          </div>

          <!-- 右文左图 6-->
          <div class="center_right">
            <img src="../../src/assets/index/s-about-us-img4-1.jpg" alt="" />
          </div>
          <div class="center_left">
            <p>4. Team</p>
            <p>
              We have over 100 members on our team. While dedicated to providing
              our clients with the most professional and considerate services,
              all New Times Hair members are like family. In such a highly
              cooperative and synergistic team, we take great pride in helping
              one another grow and become better. We prioritize a friendly,
              supportive environment and believe that bringing joy and positive
              energy to each member of our team is essential for a business to
              thrive.
            </p>
          </div>
        </div>
      </div>
      <div class="want">
        <h2>WANT TO WORK WITH US?</h2>
        <button>CONTACT NOw</button>
      </div>
      <div class="OUR">
        <h2>OUR CERTIFICATES</h2>
        <div>
          <img src="../../src/assets/index/0013.png" alt="" class="img" />
        </div>
        <div class="OUR_img4">
          <img src="../../src/assets/index/s-certificate6-2.jpg" alt="" />
          <img src="../../src/assets/index/s-certificate7.jpg" alt="" />
          <img src="../../src/assets/index/s-certificate8.jpg" alt="" />
          <img src="../../src/assets/index/s-certificate9.jpg" alt="" />
        </div>
      </div>
      <div class="CUSTOMER">
        <h2>CUSTOMER VISITS & VISITING CUSTOMERS</h2>
        <el-carousel :interval="4000" type="card">
          <el-carousel-item v-for="(item, index) in divimgs" :key="index">
            <img :src="item.img" alt="" class="medium" />
          </el-carousel-item>
        </el-carousel>
        <!-- <div  class="CUSTOMER_dis">
           <div v-for="(item,index) in divimgs" :key="index">
          <img :src="item.img" alt="">
        </div>
        </div> -->
      </div>
      <div class="VAIUES">
        <h2>VAIUES</h2>
        <p>
          New Times Hair believes strongly in integrity, craftsmanship, sharing
          and innovation.
        </p>
        <div class="VAIUES_center">
          <div
            class="VAIUES_left"
            v-for="(item, index) in Integrity"
            :key="index"
          >
            <div>
              <h2>{{ item.title }}</h2>
              <p>{{ item.content }}</p>
            </div>
            <img :src="item.imgs" alt="" />
          </div>
          <div
            class="VAIUES_right"
            v-for="(item, index) in Integrityright"
            :key="index"
          >
            <img :src="item.imgs" alt="" />
            <div>
              <h2>{{ item.title }}</h2>
              <p>{{ item.content }}</p>
            </div>
          </div>
          <div
            class="VAIUES_left"
            v-for="(item, index) in Integrity3"
            :key="index"
          >
            <div>
              <h2>{{ item.title }}</h2>
              <p>{{ item.content }}</p>
            </div>
            <img :src="item.imgs" alt="" />
          </div>
          <div
            class="VAIUES_right"
            v-for="(item, index) in Integrity4"
            :key="index"
          >
            <img :src="item.imgs" alt="" />
            <div>
              <h2>{{ item.title }}</h2>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="VAIUESing">
        <h2>VAIUES</h2>
        <p>
          New Times Hair believes strongly in integrity, craftsmanship, sharing
          and innovation.
        </p>
        <div class="VAIUES_center">
          <div
            class="VAIUES_left"
            v-for="(item, index) in Integrity"
            :key="index"
          >
            <img :src="item.imgs" alt="" />
            <div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.content }}</p>
            </div>
          </div>
          <div
            class="VAIUES_right"
            v-for="(item, index) in Integrityright"
            :key="index"
          >
            <img :src="item.imgs" alt="" />
            <div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.content }}</p>
            </div>
          </div>
          <div
            class="VAIUES_left"
            v-for="(item, index) in Integrity3"
            :key="index"
          >
            <img :src="item.imgs" alt="" />
            <div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.content }}</p>
            </div>
          </div>
          <div
            class="VAIUES_right"
            v-for="(item, index) in Integrity4"
            :key="index"
          >
            <img :src="item.imgs" alt="" />
            <div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- input框 -->
      <div style="width: 100%; background-color: #f2f2f2">
        <div class="CONTACT">
          <h2>INQUIRIES & QUESTIONS</h2>
          <div class="inputs">
            <!-- <input id="type" type="text" placeholder="请选择"> -->
            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.label }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>

          <button @click="submit">Submit</button>
        </div>
        <!-- input框 -->
        <div class="CONTACTs">
          <h2>CONTACT NOW</h2>
          <div class="inputs">
            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.name }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>

          <button @click="submits">Submit</button>
        </div>
      </div>
    </div>

    <div class="top" @click="toTop">
      <img
        style="width: 3%; height: 50px"
        src="../../src/assets/index/xiala_un@2x.png"
      />
    </div>
    <div class="tops" @click="toTop">
      <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      one: "",
      aboutnew: [
        {
          h2: "About TsingTao Hair WigsZone",
          h5: "Wholesale Man Toupees & Woman Wigs Manufacturer",
          cont: "Thank you for visiting Wigszone.com - TsingTao Hair Factory Direct Online Store. Established in 1989, we have been committed to producing best quality hair systems, replacements, man toupees, women wigs, hairpieces, hair toppers, hair extensions and bulk hair. Our factory have our own proud brands Mr. Right Hair, Starlady and Queen La Hair, also we are doing OEM brands for many big companies all around the world.With business growing for over 30 years, we’re proud to have become a leading top quality non-surgical hair system manufacturer and wholesale supplier. We have most strict quality control in every process and excellent customer service. We are not just a supplier, we prefer be a good partner. With strong production team, as well as the R&D and QC teams ensure that we can provide customers high quality products continuously. Also, we have after-sale service team works 12 hours a day to confirm that all the problems you may meet will be solved properly in time. With rich experience in international trade, we ship worldwide to our customers from Europe, North America, Middle East and Asia...We cater to our wholesale customers including hair replacements companies, distributors, hair shop owners and salon owners. We welcome you to cooperate with us to expand your business and boost your profits!",
          cont2: "TSINGTAO HAIR WIGSZONE PROUDCTS CO., LTD.",
          cont3: "Email: info@wigszone.com, order@wigszone.com, ",
          cont4: "Telephone/WhatsApp: +86-1509-2233-077, +86-159-5329-0787",
        },
      ],
      TaoHair: [
        {
          h3: "WHO WE ARE & WHAT WE DO",
          content:
            "TsingTaoHair has been producing, designing and shipping non-surgical hair replacement systems since 1989 and WigsZone is Tsingtaohair’s factory direct online store. We specialize in hair systems that promise a realistic look, a natural hairline, high durability, and affordable prices. Our non-surgical hair replacement products are shipped to over 100 countries with wholesale prices and good after sale service. We do not play tricks but insist on offering premium quality and reasonable prices hair replacements to our customers around the world.In recent years, non-surgical hair replacement systems have emerged as a great alternative to invasive and painful surgical procedures and they now are gaining huge popularity among people struggling with hair loss. Our hair systems for men are the perfect choice if you want a full head of hair without undergoing painful surgery. WigsZone cares deeply about people battling hair loss whatever their circumstances and our collection of medical wigs reflect our commitment to hair recovery amongst people suffering from illness or medical conditions.Here are the reasons why WigsZone is favored by people all across the globe seeking non-surgical hair recovery: ",
        },
      ],
      Hairicon: [
        {
          img: require("../assets/logoimgs/mans.png"),
          name: "Non-surgical Hair Roplacomont",
        },
        {
          img: require("../assets/logoimgs/kacha.png"),
          name: "Roady To woar",
        },
        {
          img: require("../assets/logoimgs/money.png"),
          name: "Affordablo Factory Pricos",
        },
        {
          img: require("../assets/logoimgs/day.png"),
          name: "30-Day Money Back Guarantee",
        },
        {
          img: require("../assets/logoimgs/day.png"),
          name: "Easy To order online",
        },
        {
          img: require("../assets/logoimgs/dangers.png"),
          name: "safe Online Payment",
        },
        {
          img: require("../assets/logoimgs/xin.png"),
          name: "Fast Worldwide Free Shipping",
        },
        {
          img: require("../assets/logoimgs/xin.png"),
          name: "Professional Customer service",
        },
        {
          img: require("../assets/logoimgs/zhuan.png"),
          name: "Frequent Order Status Updates",
        },
      ],
      optaicying: [
        {
          imgs: require("../../src/assets/index/Inventory1.png"),
          number: "80,000+",
          title: "Inventory",
        },
        {
          imgs: require("../../src/assets/index/Annual-output@2x.png"),
          number: "180,000+",
          title: "Annual output",
        },
        {
          imgs: require("../../src/assets/index/Wholesale-customers@2x.png"),
          number: "150,000+",
          title: "Social media subscribers",
        },
        {
          imgs: require("../../src/assets/index/Audience-Range@2x.png"),
          number: "5,000+",
          title: "Wholesale client range",
        },
      ],
      fontsleft: [
        {
          title: "1.Hairpiece Factory",
        },
      ],
      divimgs: [
        {
          img: require("../../src/assets/index/Customer-Visits-Visiting-Customers.jpg"),
        },
        {
          img: require("../../src/assets/index/Customer-Visits-Visiting-Customers5.jpg"),
        },
        {
          img: require("../../src/assets/index/Customer-Visits-Visiting-Customersss@2x.jpg"),
        },
        {
          img: require("../../src/assets/index/Customer-Visits-Visiting-Customer3@2x.jpg"),
        },
        {
          img: require("../../src/assets/index/Customer-Visits-Visiting-Customer7.jpg"),
        },
      ],
      Integrity: [
        {
          imgs: require("../../src/assets/index/Integrity@2x.png"),
          title: "Integrity",
          content:
            "Being honest and trustworthy, providing customers with the best quality service.",
        },
      ],
      Integrityright: [
        {
          imgs: require("../../src/assets/index/Integrity@2x.png"),
          title: "The spirit of craftsmanship",
          content: "We focus on every detail of production and service.",
        },
      ],
      Integrity3: [
        {
          imgs: require("../../src/assets/index/Integrity@2x.png"),
          title: "Innovation",
          content:
            "We work to optimize and upgrade every aspect of our production process, helping our clients to achieve greater revenues and business success.",
        },
      ],
      Integrity4: [
        {
          imgs: require("../../src/assets/index/Integrity@2x.png"),
          title: "Knowledge sharing",
          content:
            "We aim to share and learn from each other, grow together and strive to broaden the boundaries of our personal and industry knowledge every day.",
        },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    submits() {},
    submit() {},
  },
};
</script>
<style lang="less" scoped>
.TsingTaoHair {
  color: #fff;
  width: 100%;
  height: 550px;
  background: url("../assets/index/20220622092012.png");
  .Tsingbox1 {
    text-align: center;
    width: 75%;
    margin: 0 auto;
    padding: 40px 0;
  }
  .Tsingboxdis {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 70%;
    margin: 0 auto;
    .Tsingbox2 {
      width: 30%;
      display: flex;
      justify-content: left;
      margin: 5px 0;
      img {
        width: 13%;
      }
      p {
        padding-top: 9px;
        padding-left: 3%;
      }
    }
  }
  button {
    background-color: #304e6a;
    color: #fff;
    width: 6%;
    padding: 6px 0;
    margin-left: 45%;
    text-align: center;
    border: 1px solid #304e6a;
  }
  button:hover {
    opacity: 0.4;
  }
}
.contentbanners {
  display: none;
}
.aboutNewsing {
  display: none;
}
.optaicysing {
  display: none;
}
.ABOUTUSing {
  display: none;
}
.CONTACTs {
  display: none;
}
.tops {
  display: none;
}
.VAIUESing {
  display: none;
}
.about {
  .aboutcontent {
    width: 100%;
    .contentbanner {
      position: relative;
      img {
        width: 100%;
        height: 600px;
      }
      .position {
        text-shadow: #01244b 3px 3px;
        position: absolute;
        left: 35%;
        top: 250px;
        color: #fff;
        h1 {
          font-weight: 700;
        }
        p {
          font-size: 24px;
        }
      }
    }
    .aboutNews {
      text-align: center;
      background-color: #fff;
      width: 80%;
      margin: 0 auto;
      padding: 50px 0;
      h2 {
        margin-bottom: 20px;
      }
      h5 {
        margin-bottom: 20px;
      }
      .cont {
        // width: 50%;
        height: 478px;
        border-right: 10px solid #091a41;
        border-bottom: 10px solid #091a41;
        background: rgba(255, 255, 255, 0.39);
        opacity: 1;
        font-size: 14px;
        .cont-t {
          // padding-top: 20px;
          width: 100%;
          text-align: left;
        }
        .cont-btn {
          display: flex;
          justify-content: space-around;
          margin-top: 20px;
          width: 100%;
          .btn {
            font-size: 13px;
            background-color: #091a41;
          }
          .btn-outline-warning {
            color: #fff;
          }
          .btn:hover {
            color: #000;
            background-color: #ffc107;
          }
        }
      }
      .conts {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 478px;
        background: url("../assets/index/bck.png");
        .video {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 80px;
          height: 80px;
          border: 8px solid #ffc107;
          border-radius: 50%;
          // background: #ffc107;
          .video-s {
            width: 0px;
            height: 0px;
            border: 20px solid #ffc107;
            border-left-color: #ffc107;
            border-right-color: transparent;
            border-top-color: transparent;
            border-bottom-color: transparent;
            border-radius: 5px;
            // background: #ffc107;
          }
        }
        .video:hover {
          transform: scale(1.1);
          // animation: name duration timing-function delay iteration-count direction fill-mode;
        }
      }
    }
    .pos_fixed {
      width: 100%;
      position: fixed;
      top: 0px;
      z-index: -1;
      //  bottom:600px;
      left: 0;
      width: 100%;
      height: 500px;
      margin-bottom: 100px;
      img {
        width: 100%;
        height: 900px;
      }
    }
    .optaicys {
      height: 300px;
      .optaicys_div {
        padding-top: 80px;

        h2 {
          color: #f29d35;
        }
        p {
          color: #fff;
          font-size: 20px;
        }
        img {
          width: 70%;
          height: 100px;
        }
      }
    }
    .ABOUTUS {
      text-align: center;
      background-color: #fff;
      width: 100%;
      padding-top: 50px;
      h2 {
        padding-bottom: 40px;
        font-weight: 700;
      }
      .ABOUTUS_center {
        font-size: 16px;
        text-align: left;
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .center_left {
          width: 45%;
          padding: 50px 0;
        }
        .center_right {
          width: 45%;
          img {
            width: 100%;
            height: 350px;
            padding: 20px 0;
            // vertical-align: middle;
          }
        }
      }
    }
    .want {
      background-color: #383f45;
      height: 200px;
      text-align: center;
      h2 {
        font-weight: 700;
        color: #fff;
        padding-top: 50px;
      }
      button {
        background-color: #383f45;
        border: none;
        border: 1px solid #fff;
        border-radius: 18px;
        color: #fff;
        padding: 5px 1%;
        margin-top: 20px;
      }
      button:hover {
        transform: scale(1.2);
      }
    }
    .OUR {
      text-align: center;
      background-color: #fff;
      color: #002548;
      h2 {
        font-weight: 700;
        padding: 20px 0;
      }
      .img {
        width: 20%;
      }
      .OUR_img4 {
        width: 70%;
        margin: 0 auto;
        padding: 50px 0;
        display: flex;
        justify-content: space-around;
        img {
          width: 13%;
        }
      }
    }
    .CUSTOMER {
      text-align: center;
      height: 600px;
      background: url(../../src/assets/index/banner_8@2x.png);
      width: 100%;
      background-size: 100% 100%;
      background-repeat: no-repeat;

      h2 {
        color: #fff;
        font-weight: 700;
        padding: 50px 0;
      }
      .CUSTOMER_dis {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin: 0 auto;
        padding: 50px 0;
        img {
          width: 100%;
        }
      }
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }
    .VAIUES {
      background-color: #f2f2f2;
      width: 100%;
      text-align: center;
      h2 {
        font-weight: 700;
        padding: 30px 0;
      }
      .VAIUES_center {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .VAIUES_left {
          text-align: left;
          display: flex;
          justify-content: space-around;
          width: 48%;
          background-color: #fff;
          padding: 5px 2%;
          margin: 10px 0;
          p {
            width: 80%;
          }

          img {
            width: 20%;
            height: 100px;
            margin-top: 60px;
          }
        }
        .VAIUES_right {
          margin: 10px 0;
          width: 45%;
          text-align: left;
          display: flex;
          justify-content: space-around;
          width: 48%;
          background-color: #fff;
          padding: 5px 2%;
          p {
            width: 80%;
          }
          img {
            width: 20%;
            height: 100px;
            margin-top: 60px;
            padding-right: 1%;
          }
        }
      }
    }
    .CONTACT {
      text-align: center;
      padding: 50px 0;
      width: 80%;
      margin: 0 auto;
      h2 {
        font-weight: 600;
      }
      .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        input {
          width: 45%;
          height: 50px;
          margin: 20px 0;
          border: none;
          border: 1px solid #cfd5db;
        }
      }
      textarea {
        border: none;
        border: 1px solid #cfd5db;
        width: 95%;
        height: 200px;
      }
      button {
        margin-top: 30px;
        background-color: #304e6a;
        border: 1px solid #304e6a;
        padding: 10px 1.5%;
        border-radius: 5px;
        color: #fff;
      }
      button:hover {
        background-color: #f9bd5a;
        border: 1px solid #f9bd5a;
      }
    }
  }
  .top {
    img {
      position: fixed;
      // z-index: 999;
      bottom: 50px;
      right: 50px;
    }
  }
}
@media screen and (max-width: 390px) {
  .contentbanner {
    display: none;
  }
  .contentbanners {
    display: block;
    position: relative;
    text-align: center;
    img {
      width: 100%;
    }
    .position {
      text-shadow: #01244b 3px 3px;

      position: absolute;
      left: 10%;
      top: 30px;
      color: #fff;
      h5 {
        font-weight: 400;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .aboutNewsing {
    display: block;
    text-align: center;
    background-color: #fff;
    width: 90%;
    margin: 0 auto;
    padding: 50px 0;
    h2 {
      margin-bottom: 10px;
    }
    h5 {
      margin-bottom: 10px;
    }
    .cont {
      width: 100%;
      // height: 478px;
      // border-right: 10px solid #091a41;
      // border-bottom: 10px solid #091a41;
      background: rgba(255, 255, 255, 0.39);
      opacity: 1;
      .cont-t {
        padding-top: 20px;
        width: 100%;
        text-align: left;
      }
      .cont-btn {
        margin-top: 50px;
        width: 100%;
        .btn {
          font-size: 13px;
          background-color: #091a41;
        }
        .btn-outline-warning {
          color: #fff;
        }
        .btn:hover {
          color: #000;
          background-color: #ffc107;
        }
      }
    }
    .conts {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 478px;
      background: url("../assets/index/bck.png");
      .video {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 80px;
        height: 80px;
        border: 8px solid #ffc107;
        border-radius: 50%;
        // background: #ffc107;
        .video-s {
          width: 0px;
          height: 0px;
          border: 20px solid #ffc107;
          border-left-color: #ffc107;
          border-right-color: transparent;
          border-top-color: transparent;
          border-bottom-color: transparent;
          border-radius: 5px;
          // background: #ffc107;
        }
      }
      .video:hover {
        transform: scale(1.1);
        // animation: name duration timing-function delay iteration-count direction fill-mode;
      }
    }
  }
  .aboutNews {
    display: none;
  }
  .optaicys {
    display: none;
  }
  .optaicysing {
    display: block;
    text-align: center;
    .optaicys_div {
      padding-top: 80px;

      h2 {
        color: #f29d35;
      }
      p {
        color: #fff;
        font-size: 20px;
      }
      img {
        width: 20%;
        height: 80px;
      }
    }
  }
  .ABOUTUS {
    display: none;
  }
  .ABOUTUSing {
    display: block;
    text-align: center;
    background-color: #fff;
    width: 100%;
    padding-top: 50px;
    h2 {
      padding-bottom: 40px;
      font-weight: 700;
    }
    .ABOUTUS_center {
      font-size: 16px;
      text-align: left;
      width: 90%;
      margin: 0 auto;
      .center_left {
        width: 99%;
        padding: 50px 0;
      }
      .center_right {
        width: 99%;
        img {
          width: 100%;
          height: 350px;
          padding: 20px 0;
          // vertical-align: middle;
        }
      }
    }
  }
  .about .aboutcontent .OUR .img {
    width: 80%;
  }
  .VAIUES {
    display: none;
  }
  .VAIUESing {
    display: block;
    background-color: #f2f2f2;
    width: 100%;
    text-align: center;
    h2 {
      font-weight: 700;
      padding: 20px 0;
    }
    .VAIUES_center {
      width: 99%;
      margin: 0 auto;

      .VAIUES_left {
        text-align: left;
        // display: flex;
        // justify-content: space-around;
        width: 99%;
        background-color: #fff;
        padding-left: 9%;
        margin: 10px 0;
        p {
          width: 100%;
        }
        img {
          width: 30%;
          height: 100px;
          margin-top: 60px;
        }
      }
      .VAIUES_right {
        width: 99%;
        text-align: left;
        // display: flex;
        // justify-content: space-around;

        background-color: #fff;
        padding-left: 9%;
        padding-bottom: 10px;
        p {
          width: 100%;
        }
        img {
          width: 30%;
          height: 100px;
          margin-top: 60px;
          padding-right: 1%;
        }
      }
    }
  }
  .CONTACT {
    display: none;
  }
  .CONTACTs {
    display: block;

    text-align: center;
    padding: 50px 0;
    width: 90%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      input {
        width: 100%;
        height: 50px;
        border: none;
        border: 1px solid #cfd5db;
        margin: 10px 0;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }
  .top {
    display: none;
  }

  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
}
</style>