<template>
  <div class="STOCK">
    <Nav />
    <!-- </div> -->
    <div class="top" @click="toTop">
      <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
    <div id="produce">
      <div class="produce_left">
        <!-- <proleft :navList="navList" @getId="getId" :parentId="parentId" /> -->
        <el-row class="tac">
          <el-col :span="12" style="width: 100%">
            <ul style="margin-bottom: 0px">
              <li
                style="
                  font-size: 25px;
                  color: #fff;
                  background-color: #012340;
                  padding: 8px 5%;
                "
              >
                Product Categories
              </li>
            </ul>
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
            >
              <el-submenu
                :index="index + ''"
                v-for="(item, index) in navList"
                :key="index"
              >
                <template slot="title">
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    :index="index + '-' + sindex"
                    v-for="(items, sindex) in item.list"
                    :key="sindex"
                    @click="goodsLiClick(items.category_id, items.parent_id)"
                    ><router-link
                      :to="{
                        name: 'product',
                        query: {
                          id: items.category_id,
                          parentId: items.parent_id,
                        },
                      }"
                      >{{ items.name }}</router-link
                    ></el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="produce_right">
        <div class="right_divs">
          <div style="padding: 0px 15px">
            <p
              style="font-weight: 600; margin-bottom: 10px"
              v-show="this.divs == ''"
            >
              The website is being updated
            </p>
            <div style="flex-direction: column; display: flex">
              <div class="typeDiv" v-for="(item, findex) in divs" :key="findex">
                <h5>{{ item.spec_name }}</h5>
                <ul>
                  <li
                    @click="typeClick(items.spec_value, findex, sindex)"
                    v-for="(items, sindex) in item.spec_values"
                    :key="sindex"
                    :class="items.active == true ? 'active' : ''"
                  >
                    {{ items.spec_value }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p style="font-weight: 600; margin: 15px" v-show="this.navimgs == ''">
          The website is being updated
        </p>
        <goods :goodsList="navimgs" :count="count" @getPage="getPage" />
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
import goods from "../components/goods.vue";
import { getGoodsApi, getGoodsCataApi, getSpenApi } from "../api/index";
export default {
  components: {
    Footer,
    Nav,
    goods,
  },
  data() {
    return {
      gklog: "",
      categoryId: null, //当前分类 id
      parentId: null, //默认分类父级Id
      navList: [], // 默认产品分类列表
      specId: [], //标签id
      count: null, //默认产品总个数
      page: 1, //默认页面数值
      radio3: "", //默认选中选项
      sameTrue: false,
      bannerList: [],
      fontsing: [
        {
          headerfont:
            "As a wholesale toupee factory and manufacturer, TsingTao Hair WigsZone works to address worldwide best men’s stock hair systems to solve hair loss problems and deliver a positive impact on people’s lives.",
          our: "Our wide range of in-stock hair systems includes over 90,000 real toupees for both men and women. Choose from 50+ different men’s stock hair systems models in various colors. ",
          all: "All of our men’s stock hair systems are ready for immediate delivery and can be shipped to you within 3-5 business days no matter where you’re located in the world. For nearly two years, our on-site wholesale toupee factory, based in Qingdao, China has helped tens of thousands of those with hair loss problems gain back their younger look and changed their lives for the better. We’ve also helped countless business owners and hair professionals find increased revenue and success partnering with us. ",
          numb: "The number of men’s wholesale hair systems sellers that sell men’s toupees from TsingTao Hair WigsZone to invigorate their own businesses never stop growing. Our stock men’s hairpieces are widely recognized by our partners for their superior quality, low factory-direct prices, a large selection of hair system designs, and a first-rate client service system. By choosing men’s stock hair systems from us, you’ll set yourself on the path for rapid and consistent business growth. If you want to know more about our men’s stock hair systems, please contact us now!",
        },
      ],
      showBtn: true,
      divs: [],
      navimgs: [],
      isActive: true,
    };
  },
  created() {
    this.getCategory(); // 获取分类列表
    let goodsData = {};
    goodsData.goods_name = this.$route.query.goods_name;
    goodsData.category_id =
      this.$route.query.id !== "" ? this.$route.query.id : null;
    goodsData.spac_id = null;
    goodsData.page = 1;
    getGoodsApi(goodsData)
      .then((res) => {
        if (
          res &&
          res.code &&
          res.code == 1 &&
          res.data &&
          res.data.length !== 0
        ) {
          res.data.map((res) => {
            res.img_url.splice(2, res.img_url.length - 2);
          });
          this.navimgs = res.data;
          this.count = res.count;
        }
      })
      .catch((err) => {
        if (err.code == 0 && err.data == "") {
          this.navimgs = [];
        }
      });
    this.getSpec();
  },
  watch: {
    categoryId(newVal) {
      if (newVal != null) {
        // debugger;
        this.getCategory(this.parentId); //获取分类信息 列表
        this.getSpec(newVal); //获取标签 一级 二级
        this.getGoodsList(this.categoryId, this.specId, this.page); //获取产品列表信息
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.categoryId = to.query.id;
    this.parentId = to.query.parentId;
    next();
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    /**
     * 获取分类 banner轮播图片
     *
     */
    getCbanner() {
      getBannerApi(this.categoryId).then((res) => {
        if (res && res.code && res.code == 1) {
          this.bannerList = res.data;
        }
      });
    },
    clickJoin() {
      this.$router.push({
        path: "/Joinnow",
      });
    },
    //inquiryNow
    inquiryNow() {
      this.$router.push({ path: "/Inquirynow" });
    },
    getId(e) {
      this.categoryId = e;
    },
    typeClick(id, findex, sindex) {
      // this.specId = id;
      let that = this;
      this.divs.forEach((res, b1index) => {
        // debugger;
        if (findex == b1index) {
          res.spec_values.forEach((resSecond, b2index) => {
            if (sindex == b2index && !resSecond.active) {
              // debugger;
              resSecond.active = true;
            } else {
              resSecond.active = false;
            }
          });
        }
      });
      let idss = []; //选中的规格id
      this.divs.forEach((item) => {
        item.spec_values.forEach((val) => {
          if (val.active) {
            idss.push(val.spec_value_id);
          }
        });
      });

      this.specId = idss;

      this.getGoodsList(this.categoryId, this.specId, this.page); //获取产品列表信息
    },
    changeSpec(e) {
      // console.log(e);
    },
    /**
     * 获取 当前页码
     */
    getPage(e) {
      this.page = e;
      this.getGoodsList(this.categoryId, this.specId, this.page); //获取产品列表信息
    },

    /**
     * 导航点击 跳转
     * @id 产品分类id
     */
    navClick(id, pId) {
      this.$router.push({
        path: "/Stock?id=" + id + "&parentId=" + pId,
      });
    },
    goodsLiClick(id, pid) {
      this.$router.push({
        path: "/product?id=" + id + "&parentId=" + pid,
      });
      // this.getGoodsList(this.categoryId, this.specId, this.page); //获取产品列表信息
      // debugger;
    },
    /**
     * before after 图片点击
     * @id 产品ID
     */
    imgClick(id) {
      this.$router.push({
        name: "?id=" + id,
      });
    },
    /* 设置视频进度 */
    playerReadied(player) {
      player.currentTime(this.gklog);
      // console.log("视频初始时长",this.gklog)
    },
    onPlayerTimeupdate(player) {
      this.gklog = player.cache_.currentTime; //获取当前播放时间
      // console.log(" onPlayerTimeupdate!", this.gklog);
    },

    liClick(index) {
      this.currentIndex = index;
    },
    btns1() {
      //     this.$router.push({
      //        path:'../Viewdetail'
      //     })
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    showMore() {
      this.showBtn = false;
    },
    submit() {},
    submits() {},
    displiog() {},
    //根据id跳转页面
    go(id) {
      // console.log(id, "id");
      // if(id==1){
      // windows.location.href = "Afro.vue"
      // this.$router.push('/home/first')//vue跳转
      // }
    },
    // 获取产品列表
    getGoodsList(categoryId, specId, page) {
      let goodsData = {};
      goodsData.category_id = categoryId;
      goodsData.spac_id = specId;
      goodsData.page = page;
      getGoodsApi(goodsData)
        .then((res) => {
          if (
            res &&
            res.code &&
            res.code == 1 &&
            res.data &&
            res.data.length !== 0
          ) {
            res.data.map((res) => {
              res.img_url.splice(2, res.img_url.length - 2);
            });
            this.navimgs = res.data;
            this.count = res.count;
          } else {
            this.navimgs = "";
          }
        })
        .catch((err) => {
          if (err.code == 0 && err.data == "") {
            this.navimgs = [];
          }
        });
    },
    // 获取分类列表
    getCategory() {
      getGoodsCataApi().then((res) => {
        if (res && res.code && res.code == 1) {
          // console.log(res);
          this.navList = res.data;
        }
      });
    },
    /**
     * 获取产品标签
     * @category_id 分类id
     * divs
     */
    getSpec() {
      let id = null;
      if (this.$route.query.id) {
        id = this.$route.query.id;
      }
      getSpenApi(id).then((res) => {
        if (res && res.code && res.code == 1) {
          res.data.map((res) => {
            res.spec_values.map((ress) => {
              ress.active = false;
            });
          });
          this.divs = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.typeDiv {
  h5 {
    font-size: 16px;
    font-weight: bold;
  }
  ul {
    display: flex;
    li {
      list-style: none;
      padding: 5px 8px;
      // background-color: #f00;
      margin: 5px !important;
      &.active {
        background-color: #f29d35;
        color: #fff;
      }
    }
  }
}
.top {
  img {
    // width: 3%;
    // height: 50px;
    width: 42px;
    max-width: 100%;
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
  }
}
#produce {
  max-width: 1280px;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  .produce_left {
    width: 27%;
    ul {
      li {
        a {
          color: #303133;
        }
      }
    }
  }
  .produce_right {
    .right_divs {
      border: 15px solid #f2f2f2;
      padding: 10px 0;
      cursor: pointer;
    }
    width: 70%;

    a {
      // text-decoration: none;
      color: #000;
    }
    ul {
      li {
        font-size: 14px;
        margin-right: 20px;
      }
    }
  }
}
</style>