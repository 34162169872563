<template>
  <div class="goods">
    <div class="disnav">
      <div class="navimgs" v-for="(item, index) in goodsList" :key="index">
        <img
          v-for="(items, findex) in item.img_url"
          :src="items"
          alt=""
          class="laceimg"
          :key="findex"
        />
        <!-- <img :src="item.img_url[1]" alt="" class="laceimg1" /> -->
        <p style="font-size: 14px">{{ item.fonts }}</p>
        <button @click="btns1(item.goods_id)">Quick View</button>
        <!-- <button @click="loginGo">Login to view prices</button> -->
      </div>
    </div>
    <!-- count -->
    <div v-if="this.goodsList.length !== 0">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="count"
        @current-change="pageChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        class="paging"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "goods",
  props: {
    goodsList: {
      type: Array,
      default: null,
    },
    count: {
      type: Number,
      default: null,
    },
  },
  watch: {
    goodsList: {
      handler(newVal) {
        if (newVal) {
          this.goodsList = newVal;
        } else {
          this.goodsList = "";
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    // console.log(this.goodsList);
  },
  data() {
    return {
      imgUrl: "",
    };
  },
  computed: {},
  methods: {
    /**
     * 点击不同页码数字事件
     * @pageNum 点击页码值
     * @e 获取要请求的页码数值
     */
    pageChange(e) {
      this.$emit("getPage", e);
    },
    prevClick(e) {
      this.$emit("getPage", e);
    },
    nextClick(e) {
      this.$emit("getPage", e);
    },
    // 进入产品详情
    btns1(id) {
      this.$router.push({ name: "productshow", query: { id: id } });
    },
    loginGo() {
      this.$router.push({ name: "usercenter" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f9bd5a !important;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: #304e6a;
  color: #fff;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  background-color: #f9bd5a !important;
  color: #fff;
}

.disnav {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .navimgs {
    width: 30%;
    .laceimg1 {
      display: none;
      width: 100%;
    }
    .laceimg {
      width: 100%;
      &:nth-child(2) {
        display: none;
      }
    }

    p {
      height: 40px;
    }
    button {
      color: #fff;
      border: 1px solid #012340;
      background-color: #012340;
      width: 100%;
      padding: 8px 0;
      margin: 5px 0;
    }
    button:hover {
      background-color: #f29d35;
      border: 1px solid #f29d35;
    }
  }

  .navimgs:hover {
    .laceimg {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: block;
      }
    }
  }
}
.paging {
  margin: 30px 0px 10px 0px;
}
</style>
