<template>
  <div class="STOCK">
    <Nav />
    <div class="container">
      <div class="row">
        <el-card class="box-card">
          <el-form ref="form" :model="form" label-position="top">
            <div class="night">
              <span
                >Lost your password?We will reset your password for you, please
                login again later!</span
              >
              <el-form-item label="Email address *">
                <el-input v-model="form.email"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit"
                  >Reset password</el-button
                >
              </el-form-item>
            </div>
          </el-form>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../../components/Nav.vue";
import { checkEmail } from "../../utils/validate";
import { postResetPasswordApi } from "../../api/user";
// import { getGoodsApi, getGoodsCataApi, getSpenApi } from "../api/index";
export default {
  name: "forgetpw",
  components: {
    Nav,
  },
  data() {
    return {
      form: {
        email: "",
      },
    };
  },
  created() {},
  mounted() {},
  watch: {},
  beforeRouteUpdate(to, from, next) {
    this.categoryId = to.query.id;
    this.parentId = to.query.parentId;
    next();
  },
  methods: {
    onSubmit() {
      if (!checkEmail(this.form.email)) {
        this.$message.error("Please enter the correct email address!");
        return;
      }
      let emailData = {};
      emailData.email = this.form.email;
      postResetPasswordApi(emailData).then((res) => {
        if (res && res.code && res.code == 1) {
          this.$message.success(
            "Reset will take effect soon, please try again later!"
          );
        } else {
          this.$message.error(
            "Check whether the mailbox exists and check the network connection!"
          );
        }
      });
    },
  },
};
</script>
<style lang="less" >
.container {
  max-width: 1280px;
  font-family: "VisbyCF", Sans-serif;
  height: calc(100% - 121px);
  .row {
    // display: block;
    align-items: center;
    justify-content: center;
    padding: 8.34em 6.67em;
    .el-form {
      padding: 20px;
      box-sizing: border-box;
      box-shadow: 0 2px 20px rgb(45 53 89 / 10%);
      .night {
        border: 1px solid #dee0e9;
        padding: 20px;
        .el-form-item__label {
          padding: 0px;
        }
        span {
          color: #333;
          margin-bottom: 1rem;
          display: block;
        }
        button {
          background-color: #f29d35;
          border-color: #f29d35;
          span {
            color: #fff;
            margin: 0;
          }
          &:hover {
            background-color: #012340;
            border-color: #012340;
          }
        }
      }
    }
  }
}
</style>