 <template>
  <div class="wigszone">
    <Nav />
    <div class="wigszone_content">
      <div v-for="(item, index) in list" :key="index" style="padding: 40px 0">
        <!-- <img :src="item.img" alt="" />
        <h3>{{ item.h3 }}</h3> -->
        <p>{{ item.h5 }}</p>
      </div>
      <div class="contents" v-for="(item, index) in contents" :key="index">
        <h3>{{ item.title1 }}</h3>
        <p>{{ item.p1 }}</p>
        <img :src="item.img" alt="" />
        <p>{{ item.p2 }}</p>
        <h3></h3>
        <p></p>
        <img :src="item.img1" alt="" />
      </div>
      <div class="contents" v-for="(item, index) in unb" :key="index">
        <img :src="item.img" alt="" />
        <p>{{ item.name }}</p>
      </div>
      <div class="content3" v-for="(item, index) in content3" :key="index">
        <h3>{{ item.h3 }}</h3>
        <p>{{ item.name }}</p>
        <img :src="item.img" alt="" />
        <p>{{ item.name2 }}</p>
      </div>
      <div  class="content4" v-for="(item, index) in content4" :key="index">
        <h3>{{item.title}}</h3>
        <p>{{ item.name }}</p>
       <img :src="item.img" alt="" />
      </div>
    <div  class="content5" v-for="(item, index) in content5" :key="index">
        <h3>{{item.title}}</h3>
         <p>{{ item.name }}</p>
          <img :src="item.img" alt="" />
    </div>

    </div>
        <div class="top" @click="toTop">
        <img src="../assets/index/xiala_un@2x.png" alt="" />
      </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      list: [
        {
          //   img: require("../assets/index/20220624150925.png"),
          //   h3: "WHAT KIND OF HAIR SYSTEM IS SUITABLE FOR ME?",
          h5: "How WigsZone Create the Most Natural Hairline For Hair Systems?People all love their hair systems most natural effects and undetected. That's why we never stop exploiting the techniques to create the natural front hairline and improve natural effects. ",
        },
      ],
      contents: [
        {
          title1: "Graduated Density",
          p1: "Graduated density can help to create a natural-looking front hairline for a hair system. We tend to use a graduated density at the front to create this look. A graduated hair density means less density and an irregular front line which then graduates through to the main density of the hairpiece. This method also avoids a very neat and straight front line. The overall effect is a more natural front hairline that mimics our natural hair pattern. However, if your client needs thick hair at the front then we will not use the graduation method.",
          img: require("../assets/index/20220624171021.png"),
          p2: "Single hair single knot and gradual densitySome customers request very heavy density even on the front hairline, as they think high volume hair can cover the base edges completely. Actually, that's wrong. Hairpieces needs hairlines with density that decreases gradually. It is better to knot one hair per knot.For base designs with French lace or welded mono on the front, we always do this if density on the front is not very heavy.For base designs with super thin skin on the front, hair is usually V-looped to the area of 1/8 or 1/4 inch at the very front edge. These are invisible.",
        },
        {
          title1: "Bleached Knots",
          p1: "After the hair systems have been made, WigsZone can use bleaching technics to lighter the knots and hair roots to make the knots virtually undetectable.",
          img: require("../assets/index/20220624171559.png"),
          p2: "Bleach knots are the latest innovation in hair systems and they look great. As the name suggests, hair is put through a delicate root bleaching process after being knotted to the hair system base. Knot bleaching removes most of the dark color pigment from the hair.Bleached knots are virtually invisible which makes the hair appear to be growing right out from your own scalp. No one will be able to tell that you are wearing a hair replacement system with bleach knots. Root bleaching makes knots translucent so ugly knots just fade away. Knot bleaching mimics the appearance of your own hair because near the scalp, growing hair appears lighter in color.",
        },
      ],
      unb: [
        {
          img: require("../assets/index/20220624171912.png"),
          name: "Bleached knots are an important part of a knotted hair system. As they look so good, most clients choose bleach knots for their front hairline as a default because a natural front hairline is important to achieve a realistic look. Root bleaching makes knots simply undetectable and are especially effective if you have a darker hair color. Bleach knots are available either around the perimeter or parting area. If you have a lighter hair density we can even add root bleaching to the entire system. Your real hair is knotless, and we can create that look with bleached knots.With WigsZone, you have the option of having bleached knots either just around the perimeter or the parting area, or throughout your hair system. ",
        },
        {
          img: require("../assets/index/20220624171957.png"),
          name: "Note: Bleached knots are usually applicable to the lace base system. Skin units and silk base systems, bionic base systems have no need for knot bleaching. When hair color is darker than 1B, it is very difficult to lighten to hair roots color again with bleaching technics. If you still want front knots undetectable, we suggest to do dye-after treatment of using light color for the front hairline, so you can dye the light-colored hair to a dark color to match clients' hair color leaving the knots in light color and undetectable.  ",
        },
      ],
      content3: [
        {
          h3: "Dye-after Treatment",
          name: " Dye-after treatment is an alternative technique for bleaching knots. When the hair color is darker than 1B, it is very difficult to lighten the hair roots color again with bleaching technics. The dye-after treatment solve this problem as follows:Firstly, we can either use light-colored hair to make the hair system or as is more common, just use light hair for the first 0.5~1' front hairline area. Once the hair system is finished, we will dye the light-colored hair to a dark color to match the clients' hair color sample or the required color. Most of our customers asked us to keep the light color front and they will do the color treatment in their hair salons and hair shops.  When dye the light-colored hair, the hair roots won't be dyed, instead, the hair roots will be left as they are which will create the same effect as bleaching knots. ",
          img: require("../assets/index/20220624172453.png"),
          name2:
            "Being a hair systems toupee factory, we produce hair systems in bulk and offer wholesale prices. Our dye work might be not as delicate as hair salons. So we suggest the dye work on the light color at hair salons. We also do not suggest doing dye-after to whole piece because there is a change you might dye the rest of the base. Most of our customers are wholesalers, hair systems companies, hair shops and salon owners. They simply ask us to use a light color for the front hairline and then they will do dye-after in their own salons or studios or they will sell the hair systems to salons. ",
        },
              {
          h3: "A Suitable Front Contour",
          name: "Choosing an appropriate front contour helps to creat a natural front effect too. Different people have different front contours. Some have sharp front contours and some have flat ones while other people have irregular ones. WigsZone have several common front contours: A,AA,B,BB,C and CC. A and CC front contours are mostly used for man hair systems. CC is our default option for men's hair replacements. Women however, tend to have a rounder and flatter front contour. It's best that you make templates if want the hair systems with the exact front contours as the clients'. We will make the contours according to the templates.  ",
          img: require("../assets/index/20220624172647.png"),
         },
      ],
      content4:[
        {
            title:" A Suitable Knotting Method",
            name:"Choosing a suitable knotting method is important for creating a natural effect. Many people understand that hair pieces are all hand-made. However, not many know that there are different types of knots that make the unit look and fit into one's desired look. Here WigsZone share commonly used knots and a couple of different methods not many know about. And hopefully this can help you get the most natural hair pieces. Ventilation has a lot to do with both the appearance and durability of your hair replacement systems. Knowing a little bit about ventilation and how the hair is attached might help you achieve a better looking hair system.How is the hair attached? The most popular types of ventilation are called knotting, injection and looping. Knotting is most commonly used in lace hair systems but can also be used with poly hair systems as well. Injection and looping are only available in poly hair systems as they appear as if the hair is growing from the scalp.",
         img: require("../assets/index/20220624173030.png"),
        }
      ],
      content5:[
        {
            title:" Single Knots",
            name:"Single knots on hair systems are usually done with a few strands of hair knotted on the system once (The amount of hair on the knot usually depends on the density). This type of knotting does not provide a lot of volume or durability like the other knotting methods. Due to the nature of the loop, the single knots are usually knotted flat in one direction, which is great for those flat back hairstyles. It provides a more natural looking hair system as the knot at the root of the unit are smaller and almost invisible.",
         img: require("../assets/index/220624173235.png"), 
        },
          {
            title:"Double Knots",
            name:"Double Knots are also a few strands of hair knotted on the system, but instead of looping once, the worker loops it twice. This knot is one of the most durable knots, but it is not the most natural looking. Since the knots are knotted twice with a few hairs, those little pesky dots at the bottom of the base are really apparent. However, the benefit is that it provides systems with much needed volume at the roots and allows much more room for added density.",
         img: require("../assets/index/0220624173559.png"), 
        },
             {
            title:"Single Reverse Split Knot",
            name:"The single reverse split knot, is very popular and the most common knot you will see on the any poly skin base material. If the system has a poly skin perimeter, you can almost guarantee that the systems will have this type of knotting used in those areas. That's because not only does it provide more durability than a single knot while allowing more movement in the hair. The poly helps seal in the knots better. It also is less detectable at the root since the knots are smaller than a double reverse split knot. This knot is best used in front of systems with poly perimeters to provide a more natural look. It is also used all throughout a full skin base.This knot differs from a single knot due to the fact that the single knot can only be knotted in one direction, and therefore movement is not usually easy. If you compare the two knots, in a single knot the hair ends up in the same direction. With a single reverse split knot, splits the strands into two causing a slight gap to provide that movement. (see the comparison below). The downfall to this knot is that the knots can loosen faster than the double split reverse knot, but it still holds better than a single knot. ",
         img: require("../assets/index/20220624173720.png"), 
        },
               {
            title:"Double Reverse Split Knot",
            name:"This knot is very similar to the single reverse split knot providing that movement in the hair. Since the knots are knotted twice, it is twice as durable as a single split reverse knot. This knot is commonly used in the top and back of systems to provide that extra volume and movement. Typically, more than a few strands of hair are knotted to provide that density. The downfall to this knot is that the knots are twice as big and therefore more visible at the root. It is never suggested to use this type of knot in the front of the system unless it is knotted with a single strand.Typically, lace bases will have this knot used in the front, however it is only knotted with one single strand. This allows for that natural look without having big pesky black dots at the roots. While also providing that durability and volume at the front of the system.",
         img: require("../assets/index/20624173904.png"), 
        },
       {
            title:"Half Knot",
            name:"Is the smallest and most undetectable hair knot and provide some lift. The half knot is even smaller than a single knot and can also be used in a slick back hair style. ",
         img: require("../assets/index/20220624173943.png"), 
        },
           {
            title:"Injection Ventilation",
            name:"Injection is most common in poly hair systems as it requires a thicker base so the hair can be sealed between a layer of polyurethane and silicone. The best part is there is no hair return. That means all the hair follicles go in the same direction so tangling is rarely an issue. The Injected hair is natural because the hair is hand-inserted to the base from different angles, which means it looks very natural for all the hair to tend to fall in different directions.  ",
         img: require("../assets/index/0220624174100.png"), 
        },
           {
            title:"Looping Ventilation",
            name:"Namely, V-looped is related to the V. On the other hand means the hair is sewn through the base almost in a V or U pattern. There is a hair return with looping and this kind of skin hair is not tied but looped into the base so no visible knots show. V-looped hair is very popular in the world because it can be divided in different directions depends on what you think. And about the V-looped base design, it can be made for super thin about 0.02-0.03. This is another reason why V- looped hair sells very well in the world, the super-thin skin systems are very natural because there are no knots to bleach, no fine lines from lace. If you wear a V- looped hair, all you see is hair coming from the scalp! ",
         img: require("../assets/index/20220624174142.png"), 
        },
          {
            title:" Cutting Scallop",
            name:"Scalloped edges can you to avoid straight and stiff hairlines. If the base material on the front is poly, it is good choice because in does not fade. However, it is unavailable if base material on the front is French lace, Swiss lace, super thin skin or silicon base.",
         img: require("../assets/index/0220624174231.png"), 
        },
            {
            title:" Adding Under-ventilation Hair",
            name:"Under-ventilation hair is ventilating hair to the opposite side of base along front base edge. This is another way to decrease visibility. It is used on poly edge or folded edge systems.If you like brush back hair style we don't recommend this method, the under-ventilation hair always splits from hair brushed back due to opposite hair knotting direction.",
         img: require("../assets/index/0220624174317.png"), 
        },
          {
            title:"About WigsZone",
            name:"WigsZone has been supporting men and women through temporary as well as permanent hair loss since our hair products factory was established in 1989. Our collection of hairpieces for men,non surgical hair replacement systems and hair toupees has been given 5-star ratings by people across the globe.At WigsZone, we are dedicated to designing and supplying the world's best range of hair systems for men and wigs for women. We are the leaders in designing modern hair systems that last long, most natural realistic appearance, and promise supreme comfort.Our hair systems products are shipped to 100+ countries and come with 30 days money-back guarantee. Explore our growing range of non surgical hair replacement products or email us on info@wigszone.com or phone/whatsapp 0086-15092233077 to discuss your queries!",
            img: require("../assets/index/0220624180649.png"), 
        },
      ]
    };
  },
  methods:{
     toTop() {
      document.documentElement.scrollTop = 0;
    },
  }
};
</script>
<style lang="less" scoped>
.wigszone {
  text-align: left;
  width: 100%;
  .wigszone_content {
    width: 75%;
    margin: 0 auto;
    p{
        padding: 20px 0;
    }
    img{
        width: 50%;
    }
    .content5{
    
  h3,p{
       text-align: center;
   }
  img{
      text-align: left;
      width: 40%;
      padding: 20px 0;
    }
    }
  }
    .top {
    img {
      width: 3%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 50px;
    }
  }
}
</style>