<template>
  <div class="newtimehair">
    <Nav />
    <div class="content">
      <!-- banner -->
      <div class="content_banner">
        <p class="headtitle">
          This Supplier Has Been Verified Onsite By World-leading Inspection
          Company
        </p>
        <!-- banner图片 -->
        <div
          id="carouselExampleInterval"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active" data-interval="10000">
              <img
                src="../assets/index/banner@2x.png"
                class="d-block w-100"
                alt=""
              />
            </div>
            <div class="carousel-item" data-interval="2000">
              <img
                src="../assets/index/banner@2x.png"
                class="d-block w-100"
                alt=""
              />
            </div>
            <div class="carousel-item">
              <img
                src="../assets/index/banner@2x.png"
                class="d-block w-100"
                alt=""
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-target="#carouselExampleInterval"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-target="#carouselExampleInterval"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </button>
        </div>
        <h1>HAIR SYSTEM FACTORY TOUR</h1>
      </div>
      <!-- banner下边五个图标 -->
      <div class="fiveimg">
        <h2 style="font-weight: 700">WHO WE ARE & WHAT WE DO</h2>
        <div class="fiveimg_dis">
          <div v-for="(item, index) in list" :key="index">
            <img :src="item.img" alt="" />
            <h3 style="color: #f29d35">{{ item.h3 }}</h3>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <!-- banner下边五个图标 -->
      <div class="fiveimgs">
        <h4>WHO WE ARE & WHAT WE DO</h4>
        <div class="fiveimg_dis">
          <div v-for="(item, index) in list" :key="index">
            <img :src="item.img" alt="" />
            <h3 style="color: #f29d35">{{ item.h3 }}</h3>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="COMPANY">
        <h2>COMPANY ALBUM</h2>
        <div class="COMPANY_img">
          <div v-for="(item, index) in imgs" :key="index">
            <img :src="item.img" alt="" />
          </div>
        </div>
      </div>
      <div class="COMPANYs">
        <h4>COMPANY ALBUM</h4>
        <div class="COMPANY_img">
          <el-carousel height="150px;">
            <el-carousel-item v-for="(item, index) in imgs" :key="index">
              <img :src="item.img" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 八个鼠标经过切换图片 -->
      <div class="Products">
        <h2>HOT-SALE Products</h2>
        <div class="divbox">
          <div class="allimg" v-for="(item, index) in listing" :key="index">
            <a href="" class="imgs">
              <img :src="item.imgs" alt="" class="a1" />
            </a>
            <a href="" class="imging">
              <img :src="item.imging" alt="" class="a2" />
            </a>
            <p style="width: 250px; color: #7d8daf; text-align: center">
              {{ item.titles }}
            </p>
          </div>
        </div>
        <router-link to="/">More>></router-link>
      </div>
      <!-- 01-05 -->
      <div class="PRODUCTION">
        <h2>PRODUCTION FLOW</h2>
        <div class="FLOW">
          <div v-for="(item, index) in flowimg" :key="index">
            <img :src="item.img" alt="" />
            <h3 style="color: #acacac">{{ item.h3 }}</h3>
            <p style="font-weight: bold; font-size: 20px">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="ban">
        <img src="../../src/assets/index/banner_7@2x.png" alt="" />

        <div class="video">
          <div class="video-s"></div>
        </div>
        <p style="font-size: 30px; font-weight: 700; width: 100%">
          INSIDE NEW TIMES HAIR FACTORY
        </p>
      </div>
      <!-- input框 -->
      <div class="CONTACT">
        <h2>CONTACT NOW</h2>
        <div class="inputs">
          <!-- <input id="type" type="text" placeholder="请选择"> -->

          <input type="text" v-model="one" id="" placeholder="Name*" />
          <input type="text" name="" id="" placeholder="Email Address*" />
          <input
            type="text"
            name=""
            id=""
            placeholder="Country*"
            list="typelist"
          />
          <datalist id="typelist">
            　　
            <option v-for="(item, index) in country" :key="index">
              {{ item.label }}
            </option>
          </datalist>
          <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
          <input type="text" name="" id="" placeholder="Business name" />
          <input
            type="text"
            name=""
            id=""
            list="inputlist"
            placeholder="Select your business role..*"
          />
          <datalist id="inputlist">
            　　
            <option v-for="(item, index) in inputlists" :key="index">
              {{ item.name }}
            </option>
          </datalist>
        </div>
        <textarea
          type=""
          placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
        ></textarea>

        <button @click="submit">Submit</button>
      </div>
      <!-- input框 -->
      <div class="CONTACTs">
        <h2>CONTACT NOW</h2>
        <div class="inputs">
          <input type="text" v-model="one" id="" placeholder="Name*" />
          <input type="text" name="" id="" placeholder="Email Address*" />
          <input
            type="text"
            name=""
            id=""
            placeholder="Country*"
            list="typelist"
          />
          <datalist id="typelist">
            　　
            <option v-for="(item, index) in country" :key="index">
              {{ item.name }}
            </option>
          </datalist>
          <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
          <input type="text" name="" id="" placeholder="Business name" />
          <input
            type="text"
            name=""
            id=""
            list="inputlist"
            placeholder="Select your business role..*"
          />
          <datalist id="inputlist">
            　　
            <option v-for="(item, index) in inputlists" :key="index">
              {{ item.name }}
            </option>
          </datalist>
        </div>
        <textarea
          type=""
          placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
        ></textarea>

        <button @click="submits">Submit</button>
      </div>
    </div>
    <!-- <div id="topAnchor" ref="faultTree" class="wrap">
	    <a id="TOPUp" href="#topAnchor">
	      <img style="width: 10%;height: 10%;" src="../../src/assets/index/xiala_un@2x.png" alt="">
	    </a>
	</div> -->
    <div class="top" @click="toTop">
      <img
        style="width: 3%; height: 50px"
        src="../../src/assets/index/xiala_un@2x.png"
        alt=""
      />
    </div>
    <div class="tops" @click="toTop">
      <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      one: "",
      list: [
        {
          img: require("../../src/assets/index/two.png"),
          h3: "10+",
          title: "Years of Experience",
        },
        {
          img: require("../../src/assets/index/one.png"),
          h3: "100+",
          title: "Years of Experience",
        },
        {
          img: require("../../src/assets/index/two.png"),
          h3: "2000+",
          title: "Years of Experience",
        },
        {
          img: require("../../src/assets/index/three.png"),
          h3: "8000+",
          title: "Years of Experience",
        },
        {
          img: require("../../src/assets/index/four.png"),
          h3: "18000+",
          title: "Years of Experience",
        },
      ],

      imgs: [
        { img: require("../../src/assets/index/Album1.jpg") },
        { img: require("../../src/assets/index/Album2.jpg") },
        { img: require("../../src/assets/index/Album3.jpg") },
        { img: require("../../src/assets/index/Album2.jpg") },
        { img: require("../../src/assets/index/Album5.jpg") },
      ],
      listing: [
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "HS7 Full Lace Men’s Hair Piece With Realistic Hairline Wholesale",
        },
        {
          imgs: require("../../src/assets/NWS014-Silk-Top-Hair-System-with-Injected-Skin-and-Lace-Front-2-300x300.jpg"),
          imging: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          titles:
            "HS1 Thin Skin Hair System Wholesale 0.08 mm Transparent Poly Skin",
        },
        {
          imgs: require("../../src/assets/DURO-LACE-Full-Lace-Hair-System-for-Men-5-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles: "N6 French Lace Hair System With PU Perimeter Wholesale",
        },
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/OCT-French-Lace-Hair-System-with-NPU-Back-Sides-and-Double-Layered-Lace-Front-3-300x300.jpg"),
          titles:
            "HS25-V 0.03mm Ultra-Thin Skin Hair System Wholesale V-looped Human Hair",
        },
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/HS7-Full-French-Lace-Hair-System-for-Men-4-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
      ],
      flowimg: [
        {
          img: require("../../src/assets/index/Flow1.jpg"),
          h3: "01",
          title: "Base Making",
        },
        {
          img: require("../../src/assets/index/Flow2.jpg"),
          h3: "02",
          title: "Hair Process",
        },
        {
          img: require("../../src/assets/index/Flow3.jpg"),
          h3: "03",
          title: "Hair Knotting",
        },
        {
          img: require("../../src/assets/index/Flow4.jpg"),
          h3: "04",
          title: "Base Process",
        },
        {
          img: require("../../src/assets/index/Flow5.jpg"),
          h3: "05",
          title: "Packing",
        },
      ],
      country: [],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    //submit按钮
    submit() {
      // if(this.one == ""){
      //   alert("请填写姓名")
      //   return
      // }
    },
    submits() {},
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="less" scoped>
.tops {
  display: none;
}
.fiveimgs {
  display: none;
}
.CONTACTs {
  display: none;
}
.COMPANYs {
  display: none;
}
a {
  color: #000;
  text-decoration: none;
  font-size: 20px;
}
.newtimehair {
  background-color: #fff;
  width: 100%;
  .content {
    padding: 10px 0;
    .content_banner {
      position: relative;
      img {
        width: 100%;
      }
      h1 {
        position: absolute;
        top: 280px;
        left: 35%;
        color: #fff;
      }
      .headtitle {
        font-size: 20px;
        text-align: center;
      }
    }
    .fiveimg {
      padding: 60px 0;
      width: 70%;
      margin: 0 auto;
      text-align: center;

      .fiveimg_dis {
        display: flex;
        justify-content: space-around;
        img {
          width: 60%;
          padding: 20px 0;
        }
      }
    }
    .COMPANY {
      text-align: center;

      margin: 40px 0;
      h2 {
        padding-bottom: 40px;
        font-weight: 700;
      }
      .COMPANY_img {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
    }
    .Products {
      text-align: center;
      margin: 40px 0;
      h2 {
        padding: 20px 0;
        font-weight: 700;
      }
      .divbox {
        width: 80%;
        margin: 0 auto;
        padding: 60px 0;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .allimg:hover {
          .imgs {
            display: none;
          }
          .imging {
            display: block;
          }
        }
        .imging {
          display: none;
        }
        img {
          width: 90%;
          height: 310px;
          display: block;
        }
      }
    }
    .PRODUCTION {
      text-align: center;
      width: 70%;
      margin: 0 auto;
      h2 {
        padding: 40px 0;
        font-weight: 700;
      }
      .FLOW {
        display: flex;
        justify-content: space-around;
        // flex-wrap: wrap;
        img {
          width: 90%;
        }
      }
    }
    .ban {
      text-align: center;
      margin-top: 50px;
      position: relative;
      img {
        max-width: 100%;
        // height: 600px;
        background-size: 100% 100%;
      }
      p {
        position: absolute;
        // left: 45%;
        top: 200px;
      }
      .video {
        position: absolute;
        left: 45%;
        top: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 80px;
        height: 80px;
        border: 8px solid #ffc107;
        border-radius: 50%;
        // background: #ffc107;
        .video-s {
          width: 0px;
          height: 0px;
          border: 20px solid #ffc107;
          border-left-color: #ffc107;
          border-right-color: transparent;
          border-top-color: transparent;
          border-bottom-color: transparent;
          border-radius: 5px;
          // background: #ffc107;
        }
      }
      .video:hover {
        transform: scale(1.1);
        // animation: name duration timing-function delay iteration-count direction fill-mode;
      }
    }
    .CONTACT {
      text-align: center;

      padding: 50px 0;
      width: 80%;
      margin: 0 auto;
      h2 {
        font-weight: 600;
      }
      .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        input {
          width: 45%;
          height: 50px;
          margin: 20px 0;
          border: none;
          border: 1px solid #cfd5db;
        }
      }
      textarea {
        border: none;
        border: 1px solid #cfd5db;
        width: 95%;
        height: 200px;
      }
      button {
        margin-top: 30px;
        background-color: #304e6a;
        border: 1px solid #304e6a;
        padding: 10px 1.5%;
        border-radius: 5px;
        color: #fff;
      }
      button:hover {
        background-color: #f9bd5a;
        border: 1px solid #f9bd5a;
      }
    }
  }
  .top {
    img {
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 50px;
    }
  }
}
@media screen and (max-width: 390px) {
  .newtimehair .content .content_banner .headtitle {
    font-size: 16px;
    text-align: center;
  }
  .newtimehair .content .content_banner h1 {
    position: absolute;
    top: 120px;
    left: 20%;
    font-size: 16px;
    color: #fff;
  }
  .fiveimg {
    display: none;
  }
  .fiveimgs {
    display: block;
    padding: 30px 0;
    width: 90%;
    margin: 0 auto;
    text-align: center;

    .fiveimg_dis {
      img {
        width: 30%;
        padding: 10px 0;
      }
    }
  }
  .COMPANY {
    display: none;
  }
  .COMPANYs {
    display: block;
    text-align: center;
    margin: 40px 0;
    h4 {
      padding-bottom: 30px;
    }
    .COMPANY_img {
      width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  .newtimehair .content .Products h2 {
    padding: 20px 0;
    font-size: 23px;
    text-align: center;
  }
  .newtimehair .content .PRODUCTION h2 {
    padding: 10px 0;
    font-size: 23px;
  }
  .newtimehair .content .Products .divbox {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .newtimehair .content .PRODUCTION {
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .FLOW {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    img {
      width: 100%;
    }
  }
  .newtimehair .content .ban img {
    width: 100%;
    height: 400px;
  }
  .newtimehair .content .ban .video {
    position: absolute;
    left: 45%;
    top: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80px;
    height: 80px;
    border: 8px solid #ffc107;
    border-radius: 50%;
  }
  .newtimehair .content .ban p {
    position: absolute;
    top: 200px;
  }
  .CONTACT {
    display: none;
  }
  .CONTACTs {
    display: block;

    text-align: center;
    padding: 50px 0;
    width: 90%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      input {
        width: 100%;
        height: 50px;
        border: none;
        border: 1px solid #cfd5db;
        margin: 10px 0;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }
  .top {
    display: none;
  }

  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
  /deep/ .el-carousel__indicators {
    // 指示器变圆点
    left: unset;
    transform: unset;
    bottom: 40px;
    right: 35%;
  }
  /deep/ .el-carousel__button {
    // 指示器按钮
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  /deep/ .is-active .el-carousel__button {
    // 指示器激活按钮
    background: #3f8ec8;
  }
}
</style>