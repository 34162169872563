<template>
  <div class="STOCK">
    <Nav />
    <div class="container">
      <div class="row">
        <div class="userCenter">
          <el-card class="box-card">
            <div class="row">
              <div class="col-sm-6 loginForm">
                <h5>Login</h5>
                <el-form ref="form" :model="form" label-position="top">
                  <el-form-item label="Email address *">
                    <el-input v-model="form.email"></el-input>
                  </el-form-item>
                  <el-form-item label="Password *">
                    <el-input show-password v-model="form.password"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSubmit"
                      >Login</el-button
                    >
                  </el-form-item>
                  <span class="spanLost" @click="lostPw"
                    >Lost your password?</span
                  >
                </el-form>
              </div>
              <div class="col-sm-6 loginForm">
                <h5>Create an account</h5>
                <el-form
                  ref="formCreate"
                  :model="formCreate"
                  label-position="left"
                >
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item>
                        <el-input
                          v-model="formCreate.nickName"
                          placeholder="Account Username *"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        <el-input
                          v-model="formCreate.email"
                          placeholder="Email"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item>
                        <el-input
                          v-model="formCreate.first_name"
                          placeholder="First Name *"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        <el-input
                          v-model="formCreate.last_name"
                          placeholder="Last Name *"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item>
                        <!-- <el-input
                          v-model="formCreate.country"
                          placeholder="Country"
                        ></el-input> -->
                        <el-select
                          v-model="formCreate.countrys"
                          placeholder="Country"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        <el-input
                          v-model="formCreate.mobile"
                          placeholder="Phone/Whatsapp *"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item>
                        <el-input
                          show-password
                          v-model="formCreate.password"
                          placeholder="Password *"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        <el-input
                          show-password
                          v-model="formCreate.againPw"
                          placeholder="Confirm password *"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item>
                    <el-button type="primary" @click="resignAdmin"
                      >Submit</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
import { checkEmail } from "../utils/validate";
import countryJson from "../utils/country.json";
// import { getGoodsApi, getGoodsCataApi, getSpenApi } from "../api/index";
import { postRegisterApi, postLoginApi } from "../api/user";
// import axios from "axios";
// Vue.prototype.$axios = axios;
export default {
  name: "usercenter",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      formCreate: {
        nickName: "", //必选	string	昵称
        mobile: "", //必选	string	手机号
        password: "", //必选	string	登录密码
        email: "", //必选	string	邮箱
        first_name: "", //必选	string	姓
        last_name: "", //必选	string	名
        countrys: "", //必选	string	国家
      },
    };
  },
  created() {
    this.options = countryJson;
  },
  mounted() {},
  watch: {},
  beforeRouteUpdate(to, from, next) {
    this.categoryId = to.query.id;
    this.parentId = to.query.parentId;
    next();
  },
  methods: {
    // 用户登录
    onSubmit() {
      if (!checkEmail(this.form.email)) {
        this.$message.error("Please enter the correct email address!");
        return;
      }
      if (this.form.password == "") {
        this.$message.error("Please enter your password!");
        return;
      }
      this.$axios({
        // 默认请求方式为get
        method: "post",
        url: "http://www.trustwigs.com/api/login/login",
        // 传递参数
        data: {
          email: this.form.email,
          password: this.form.password,
        },
        // responseType: "json",
      })
        .then((res) => {
          // 请求成功
          if (res && res.data && res.data.code && res.data.code == 1) {
            localStorage.setItem("token", res.data.data.token);
            this.$message.success("Log in successfully");
            let that = this;
            setTimeout(function () {
              that.$router.push({ name: "account", params: { name: "123" } });
            }, 1500);
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((error) => {
          // 请求失败，
          console.log(error);
        });
    },
    // 用户注册
    resignAdmin() {
      if (
        !this.formCreate.nickName &&
        !this.formCreate.email &&
        !this.formCreate.first_name &&
        !this.formCreate.last_name &&
        !this.formCreate.mobile &&
        !this.formCreate.password
      ) {
        this.$message.error(
          "Please fill in all options marked with asterisks!"
        );
        return;
      }
      if (this.formCreate.password !== this.formCreate.againPw) {
        this.$message.error(
          "The password you entered for the second time is different from the first time!"
        );
        return;
      }
      if (!checkEmail(this.formCreate.email)) {
        this.$message.error("Please enter the correct email address!");
        return;
      }
      postRegisterApi(this.formCreate).then((res) => {
        // debugger;
        if (res && res.code && res.code == 1) {
          this.$message.success("Registration succeeded. Please log in!");
          let that = this;
          setTimeout(function () {
            that.$router.go();
          }, 1500);
        } else {
          this.$message(res.msg);
        }
        // console.log(res);
      });
    },
    /**
     * 忘记密码 跳转
     */
    lostPw() {
      this.$router.push({ name: "forgetpw" });
    },
  },
};
</script>
<style lang="less" >
.container {
  max-width: 1280px;
  font-family: "VisbyCF", Sans-serif;
  .row {
    padding: 5rem 0px;
    align-items: center;

    justify-content: center;
    .userCenter {
      max-width: 1080px;
      width: 100%;
      .row {
        justify-content: space-between;
        padding: 15px;
        .loginForm {
          // max-width: 49%;
          padding: 0px 8px;
          .el-form {
            border: 1px solid #dee0e9;
            padding: 15px;
            .spanLost {
              color: #f29d35;
            }
          }
          h5 {
            font-weight: bolder;
            color: #002548;
            font-size: 1.625rem;
            padding-bottom: 10px;
          }
          .el-form-item {
            margin-bottom: 15px;
            .el-select {
              width: 100%;
            }
          }
          .el-form-item__label {
            padding: 0px !important;
          }
          .el-input.is-active .el-input__inner,
          .el-input__inner:focus {
            border-color: #e6a23c !important;
          }
          .el-input-number__decrease:hover,
          .el-input-number__increase:hover {
            color: #f29d35 !important;
          }
          .el-input-number__decrease:hover:not(.is-disabled)
            ~ .el-input
            .el-input__inner:not(.is-disabled),
          .el-input-number__increase:hover:not(.is-disabled)
            ~ .el-input
            .el-input__inner:not(.is-disabled),
          .el-textarea__inner:focus {
            border-color: #f29d35 !important;
          }
          input::-webkit-input-placeholder,
          textarea::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #212529;
          }
          input:-moz-placeholder,
          textarea:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #212529;
          }
          input::-moz-placeholder,
          textarea::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #212529;
          }
          input:-ms-input-placeholder,
          textarea:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #212529;
          }

          button {
            background-color: #f29d35;
            border-color: #f29d35;
            &:hover {
              background-color: #002548;
              border-color: #002548;
            }
          }
        }
      }
    }
  }
}
</style>