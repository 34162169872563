import { render, staticRenderFns } from "./HairReview.vue?vue&type=template&id=18c12c96&scoped=true&"
import script from "./HairReview.vue?vue&type=script&lang=js&"
export * from "./HairReview.vue?vue&type=script&lang=js&"
import style0 from "./HairReview.vue?vue&type=style&index=0&id=18c12c96&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c12c96",
  null
  
)

export default component.exports