<template>
  <div class="STOCK">
    <Nav />
    <div class="container">
      <div class="row" style="display: block">
        <el-card class="box-card">
          <!-- Dashboard
          Edit Profile
          Edit Password
          Edit Address
          Orders
          User Logout -->

          <el-tabs tab-position="left">
            <el-tab-pane label="Dashboard" class="dashboard">
              <h5>Welcome, {{ this.userInfoData.nickName }}</h5>
              <span
                >From your account dashboard you can edit your profile details
                and edit your password.</span
              >
            </el-tab-pane>
            <el-tab-pane label="Edit Profile" class="editPro">
              <h5 class="pubh5">Profile Detail</h5>
              <el-form ref="form" :model="userInfoData" label-position="top">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item>
                      <el-input
                        v-model="userInfoData.nickName"
                        placeholder="Username"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item>
                      <el-input
                        v-model="userInfoData.email"
                        placeholder="Email"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item>
                      <el-input
                        v-model="userInfoData.countrys"
                        placeholder="Country"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item>
                      <el-input
                        v-model="userInfoData.mobile"
                        placeholder="Phone"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="Edit Password" class="editTpw">
              <h5 class="pubh5">Change Password</h5>
              <el-form ref="form" :model="confirmData" label-position="top">
                <el-form-item label="Current password">
                  <el-input
                    v-model="confirmData.cu_password"
                    placeholder="Current password"
                  ></el-input>
                </el-form-item>
                <el-form-item label="New password">
                  <el-input
                    v-model="confirmData.password"
                    placeholder="New password"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Confirm new password">
                  <el-input
                    v-model="confirmData.re_password"
                    placeholder="Confirm new password"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="savePw"
                    >Save Change</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!-- <el-tab-pane label="Edit Address" class="editShip">
              <h5 class="pubh5">Shipping address</h5>
              <el-form ref="form" :model="addressData" label-position="top">
                <el-form-item label="First name *">
                  <el-input
                    v-model="addressData.first_name"
                    placeholder="First name "
                  ></el-input>
                </el-form-item>
                <el-form-item label="Last name *">
                  <el-input
                    v-model="addressData.last_name"
                    placeholder="Last name "
                  ></el-input>
                </el-form-item>
                <el-form-item label="Country / Region *">
                  <el-input
                    v-model="addressData.country"
                    placeholder="Country / Region "
                  ></el-input>
                </el-form-item>
                <el-form-item label="Detailed address *">
                  <el-input
                    v-model="addressData.detail"
                    placeholder="Detailed address"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="saveAddress"
                    >Save Change</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane> -->
            <el-tab-pane label="Orders Center" class="editOrder">
              <h5 v-if="this.orderList == ''">
                No orders at the moment. Go ahead and order the products!
              </h5>
              <div
                @click="liclick(item)"
                style="border-top: 2px solid #f29d35"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <el-card class="box-card orderList" shadow="hover">
                  <ul>
                    <li>
                      <span>Order Id: {{ item.order_no }}</span>
                    </li>
                    <li>
                      <span>Product Name: {{ item.goods_name }}</span>
                    </li>
                    <li>
                      <span>Quantity: {{ item.total_num }}</span>
                    </li>
                    <!-- <li><span>Product Specification:</span></li> -->
                    <li>
                      <span>Date: {{ item.create_time }}</span>
                    </li>
                  </ul>
                  <img :src="item.file_url + '/' + item.file_name" alt="" />
                </el-card>
              </div>
              <!-- <div @click="liclick()">
                <el-card class="box-card orderList" shadow="hover">
                  <ul>
                    <li><span>Order Id:</span></li>
                    <li><span>Product Name:</span></li>
                    <li><span>Quantity:</span></li>
                    <li><span>Product Specification:</span></li>
                    <li><span>Date:</span></li>
                  </ul>
                  <img src="../../assets/stock/5.jpg" alt="" />
                </el-card>
              </div> -->
              <el-dialog title="" :visible.sync="dialogVisible" width="70%">
                <el-descriptions
                  title="Order Details"
                  direction="vertical"
                  :column="8"
                  border
                >
                  <el-descriptions-item label="Order Id">{{
                    orderDetail.order_no
                  }}</el-descriptions-item>
                  <el-descriptions-item label="Product Name">{{
                    orderDetail.goods_name
                  }}</el-descriptions-item>
                  <el-descriptions-item label="Quantity" :span="2">{{
                    orderDetail.total_num
                  }}</el-descriptions-item>
                  <el-descriptions-item label="Product Specification">
                    <!-- <el-tag size="small">学校</el-tag> -->{{
                      orderDetail.goods_attr
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="Date">{{
                    orderDetail.address
                  }}</el-descriptions-item>
                  <el-descriptions-item label="Price">{{
                    orderDetail.total_price
                  }}</el-descriptions-item>
                  <el-descriptions-item label="Handle">
                    <el-button
                      v-if="orderDetail.order_status !== 40"
                      type="primary"
                      @click="confirmRece"
                      size="mini"
                      style="background-color: #f29d35; border-color: #f29d35"
                      >Confirmation of receipt</el-button
                    >
                    <div v-else>
                      Confirmed{{
                        orderDetail.order_status == 30 ? "Has been shipped" : ""
                      }}
                    </div>
                  </el-descriptions-item>
                </el-descriptions>
              </el-dialog>
            </el-tab-pane>
            <el-tab-pane label="Logout">
              <h5>Are you sure you want to log out?</h5>
              <el-button
                type="danger"
                @click="logout"
                size="mini"
                style="margin-top: 10px"
                >Log out</el-button
              >
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../../components/Nav.vue";
import { checkEmail } from "../../utils/validate";
// import _chunk from "lodash/chunk";
import {
  getUserinfoApi,
  postUserResetPasswordApi,
  postAddressApi,
  postEditAddressApi,
  postLogoutApi,
  postOrderListApi,
  postOrderFinishApi,
} from "../../api/user";
export default {
  name: "account",
  components: {
    Nav,
  },
  data() {
    return {
      // 个人详细信息 TAB
      editFormData: {
        username: "maomao",
        email: "123",
        country: "",
        phone: "",
      },
      // 修改密码 TAB
      confirmData: {
        cu_password: "",
        password: "",
        re_password: "",
      },
      // 地址 TAB
      addressData: {
        first_name: "",
        last_name: "",
        country: "",
        detail: "",
      },
      orderList: {},
      dialogVisible: false, //弹出框是否展示
      userInfoData: {}, //默认 个人信息对象
      orderDetail: "", //订单详细
    };
  },
  created() {
    // console.log(_.chunk(["a", "b", "c", "d"], 2));
    this.getUserinfo(); // 获取个人信息
    // this.getAddress(); //获取地址信息
    this.getOrderList(); //获取订单列表
  },
  mounted() {},
  watch: {},
  beforeRouteUpdate(to, from, next) {
    this.categoryId = to.query.id;
    this.parentId = to.query.parentId;
    next();
  },
  methods: {
    // 确认收货
    confirmRece() {
      this.$confirm("Please make sure the goods have been received!", "Tip", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          postOrderFinishApi({
            token: localStorage.getItem("token"),
            order_id: this.orderDetail.order_id,
          }).then((res) => {
            // debugger;
            if (res && res.code && res.code == 1) {
              this.$message({
                type: "success",
                message:
                  "Thank you for your recognition, welcome to order again!",
              });
              this.getOrderList(); //获取订单列表
              this.dialogVisible = false;
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 获取订单列表
    getOrderList() {
      postOrderListApi({ token: localStorage.getItem("token") }).then((res) => {
        if (res && res.code && res.code == 1) {
          this.orderList = res.data;
        }
      });
    },
    // edit password
    savePw() {
      this.confirmData.email = this.userInfoData.email;
      this.confirmData.token = localStorage.getItem("token");
      if (this.confirmData.cu_password == this.confirmData.password) {
        this.$message(
          "The new password cannot be the same as the old password!"
        );
        return;
      }
      if (this.confirmData.password !== this.confirmData.re_password) {
        this.$message(
          "The password you entered for the second time is different from the first time!"
        );
        return;
      }
      postUserResetPasswordApi(this.confirmData).then((res) => {
        if (res && res.code && res.code == 1) {
          this.$message("Modify the success!Please log in again.");
          setTimeout(() => {
            this.$router.push({ name: "usercenter" });
          }, 1500);
        } else {
          this.$message(
            "Please check the network connection or try again later!"
          );
        }
      });
    },
    // 查询地址信息
    // getAddress() {
    //   postAddressApi({ token: localStorage.getItem("token") }).then((res) => {
    //     if (res && res.code && res.code == 1) {
    //       this.addressData.first_name = res.data.first_name
    //         ? res.data.first_name
    //         : "";
    //       this.addressData.country = res.data.country ? res.data.country : "";
    //       this.addressData.detail = res.data.detail ? res.data.detail : "";
    //       this.addressData.last_name = res.data.last_name
    //         ? res.data.last_name
    //         : "";
    //     }
    //   });
    // },
    // 编辑 保存地址信息
    saveAddress() {
      this.addressData.token = localStorage.getItem("token");
      postEditAddressApi(this.addressData).then((res) => {
        if (res && res.code && res.code == 1) {
          this.$message.success("Modify the success!");
          this.getUserinfo(); // 获取个人信息
          // this.getAddress(); //获取地址信息
        }
      });
    },
    /**
     * 退出登录
     */
    logout() {
      let token = localStorage.getItem("token");
      postLogoutApi({ token: token }).then((res) => {
        if (res && res.code && res.code == 1) {
          localStorage.removeItem("token");
          this.$message("Exit the success !");
          this.$router.push({ name: "usercenter" });
        }
      });
    },
    /**
     * 订单列表 点击
     * @item 整条订单item
     */
    liclick(item) {
      this.dialogVisible = true;
      this.orderDetail = item;
    },
    // 获取用户信息
    getUserinfo() {
      // debugger;
      let token = localStorage.getItem("token");
      if (!token) {
        this.$message("Please log in again!");
        setTimeout(() => {
          this.$router.push({ name: "usercenter" });
        }, 1500);
        return;
      }
      getUserinfoApi({ token: token }).then((res) => {
        if (res && res.code && res.code == 1) {
          this.userInfoData = res.data;
        } else {
          // this.$message(
          //   "Please try again later and check the network connection"
          // );
          // setTimeout(() => {
          //   this.$router.push({ name: "usercenter" });
          // }, 1500);
        }
      });
    },
  },
};
</script>
<style lang="less" >
.container {
  max-width: 1280px;
  font-family: "VisbyCF", Sans-serif;
  height: calc(100% - 121px);
  .row {
    align-items: center;
    justify-content: center;
    padding: 8.34em 6.67em;
    .el-tabs__content {
      max-height: 500px;
      padding: 20px;
      overflow: auto;

      .dashboard {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        h5 {
          font-size: 28px;
        }
      }
      .pubh5 {
        margin-bottom: 1rem;
        text-align: center;
        font-size: 22px;
      }
      .editPro {
        .el-input.is-disabled .el-input__inner {
          color: #333;
        }
      }
      .editTpw {
        .el-form--label-top .el-form-item__label {
          padding: 0px;
          line-height: 32px;
        }
        button {
          background-color: #f29d35;
          border-color: #f29d35;
          &:hover {
            background-color: #002548;
            border-color: #002548;
          }
        }
      }
      .editShip {
        .el-form--label-top .el-form-item__label {
          padding: 0px;
          line-height: 32px;
        }
        button {
          background-color: #f29d35;
          border-color: #f29d35;
          &:hover {
            background-color: #002548;
            border-color: #002548;
          }
        }
      }
      .editOrder {
        .orderList {
          margin-bottom: 10px;
          .el-card__body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // background-color: red;
            // margin-bottom: 10px;
            padding: 10px;
            border-radius: 5px;
            img {
              max-height: 180px;
            }
            &:hover {
              cursor: pointer;
            }
            ul {
              margin: 0px;
              li {
                list-style: none;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
.el-tabs__item.is-active,
.el-tabs__item:hover {
  color: #f29d35;
}
.el-tabs__active-bar {
  background-color: #f29d35;
}
</style>