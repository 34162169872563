<template>
  <div class="repair">
    <Nav />
    <div class="repair_content">
      <div class="custom_img">
        <img src="../../../src/assets/index/require.png" alt="" />
      </div>
      <div class="wkind">
        <h2 style="padding: 2px 5%">
          What Kind of Hair Pieces Does New Times Hair Repair?
        </h2>
        <p style="padding: 2px 5%">
          We repair any type of hair pieces for men and women, including wigs,
          hair systems, toupees, hair toppers, etc.
        </p>
        <h2 style="padding: 2px 5%">
          What Will New Times Hair Piece Repair Service Cover？
        </h2>
        <div class="wkind_num">
          <div v-for="(item, index) in box" :key="index">
            <h5>{{ item.num }}</h5>
            <p style="border-bottom: 1px dotted #000">{{ item.title }}</p>
          </div>
        </div>
        <img
          src="../../../src/assets/index/Hair-Volume-Chart0.jpg"
          alt=""
          class="kindimg"
        />
        <h2>IMPORTANT NOTE! Read Before You Take Order</h2>
        <p>
          We do not suggest you repair wigs or hair systems older than six
          months—— Hair and base will become especially fragile when it becomes
          old, and the cost to repair it will be even more than buying a new
          one. If you still like the design of the old hair system, we will
          recommend that you use our duplicate service.
        </p>
        <p>
          Please clean the adhesives on the hair systems before you send
          out——Why? The adhesives will stick together during shipping, making
          the base easily to be teared up when we clean the adhesives after
          receiving it. The hair will also be damaged if tucked to the adhesives
          for a long time. What’s more, we cannot ensure the base is crafted
          nicely and evenly with existing adhesives on the base.
        </p>
      </div>
      <div class="howto">
        <h2>How to Order</h2>
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              >Step1</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              >Step2</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              >Step3</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact4"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              >Step4</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact5"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              >Step5</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact6"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              >Step6</a
            >
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <h4>Tell Us About Your Hair Pieces</h4>
            <p>
              Contact us and describe where and how you want to repair your hair
              pieces with some images for reference.
            </p>
          </div>
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <h4>Evaluate the Hair Pieces</h4>
            <p>
              Hair Experts at New Times Hair will evaluate the hair pieces and
              tell you whether it worths a repair or not, and how much it spends
              for the repair.
            </p>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <h4>Send Us the Hair Pieces</h4>
            <p>
              Send us your hair pieces together with hair sample, hair ring, or
              any other items required.
            </p>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact4"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <h4>Place the Order</h4>
            <p>
              Hair experts at New Times Hair will check your hair pieces again
              after receiving it and help you place the order.
            </p>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact5"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <h4>Repair Process</h4>
            <p>
              Our skilled craftspeople will repair your hair system to a
              likely-new state within 6 to 8 weeks.
            </p>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact6"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <h4>Get Orders Delivered to Your Doorstep</h4>
            <p>
              After the repair is completed, we will ship your hair pieces
              directly to your address.
            </p>
          </div>
        </div>
      </div>
      <div class="GetQuote">
        <h2>Get a Quote</h2>
        <!-- input框 -->
        <div style="width: 100%">
          <!-- input框 -->
          <div class="CONTACT">
            <div class="inputs">
              <!-- <input id="type" type="text" placeholder="请选择"> -->

              <input type="text" v-model="one" id="" placeholder="Name*" />
              <input type="text" name="" id="" placeholder="Email Address*" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Country*"
                list="typelist"
              />
              <datalist id="typelist">
                　　
                <option v-for="(item, index) in country" :key="index">
                  {{ item.label }}
                </option>
              </datalist>
              <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
              <input type="text" name="" id="" placeholder="Business name" />
              <input
                type="text"
                name=""
                id=""
                list="inputlist"
                placeholder="Select your business role..*"
              />
              <datalist id="inputlist">
                　　
                <option v-for="(item, index) in inputlists" :key="index">
                  {{ item.name }}
                </option>
              </datalist>
            </div>
            <textarea
              type=""
              placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
            ></textarea>

            <button @click="submit">Submit</button>
          </div>
          <!-- input框 -->
          <div class="CONTACTs">
            <div class="inputs">
              <input type="text" v-model="one" id="" placeholder="Name*" />
              <input type="text" name="" id="" placeholder="Email Address*" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Country*"
                list="typelist"
              />
              <datalist id="typelist">
                　　
                <option v-for="(item, index) in country" :key="index">
                  {{ item.label }}
                </option>
              </datalist>
              <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
              <input type="text" name="" id="" placeholder="Business name" />
              <input
                type="text"
                name=""
                id=""
                list="inputlist"
                placeholder="Select your business role..*"
              />
              <datalist id="inputlist">
                　　
                <option v-for="(item, index) in inputlists" :key="index">
                  {{ item.name }}
                </option>
              </datalist>
            </div>
            <textarea
              type=""
              placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
            ></textarea>

            <button @click="submits">Submit</button>
          </div>
        </div>
      </div>
      <div class="FAQs">
        <h2>FAQs</h2>
        <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are the differences between a toupee and a wig?
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                With more and more people choosing non-surgical hair loss
                solutions, the men’s hair piece wholesale market has seen a
                significant increase in the types of stock hairpieces that are
                designed to cater to people’s needs more precisely and
                specifically. Therefore, many terms in the male hairpiece world
                often get mistaken for one another. The most common pair of
                terms that people confuse with one another is “toupee” and
                “wig.”<br />

                Both toupee and wig products are supposed to be worn on people’s
                heads for the purpose of hiding hair loss, but there are quite a
                few ways in which they differ from one another. A toupee is
                usually a small hair piece made of real human hair or synthetic
                hair that is worn at the top of the wearer’s scalp to cover a
                bald area, while a wig is meant to offer complete coverage of
                the whole head. That is why toupees are typically a lot smaller
                than wigs. Due to the differences in hair loss patterns between
                men and women, toupees are more commonly used by men to cover
                the top area of the head, which is more prone to baldness, while
                wigs are more popular among women who experience hair thinning
                problems.<br />

                Generally, it takes a lot more hair material and hair knotting
                work to produce a wig than it does for a toupee. Therefore,
                men’s professional wigs are undoubtedly more expensive. However,
                wigs are commonly seen as a more user-friendly option in the
                sense that you do not need to shave your head to attach one
                because tape and glue are not necessary to secure a wig to your
                head.<br />

                One other thing to note is that more and more people, including
                those without hair loss problems, are choosing to wear wigs for
                fashion purposes. Ultimately, whether you should buy a wig or a
                toupee for men largely depends on your hair loss pattern.
                Wholesale men’s toupees are more of an ideal option for most
                types of partial loss, and wholesale men’s wigs are typically
                recommended for those with general hair thinning problems or
                complete baldness.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What is the best men's stock hair systems wholesale?
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                Some placeholder content for the second accordion panel. This
                panel is hidden by default.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How canl get the right measurements for my clients?
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                And lastly, the placeholder content for the third and final
                accordion panel. This panel is hidden by default.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFOUR">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFOUR"
                  aria-expanded="false"
                  aria-controls="collapseFOUR"
                >
                  How canl get the right measurements for my clients?
                </button>
              </h2>
            </div>
            <div
              id="collapseFOUR"
              class="collapse"
              aria-labelledby="headingFOUR"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                How should l install a toupee for my clients?
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Why should l purchase men's stock hair systems wholesale from
                  New Times Hair?
                </button>
              </h2>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                And lastly, the placeholder content for the third and final
                accordion panel. This panel is hidden by default.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="top" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
      <div class="tops" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      one: "",
      box: [
        {
          num: "1",
          title:
            "Adding new hair to hair pieces that are thinning to restore original density.",
        },
        {
          num: "2",
          title:
            "Adding new hair to hair pieces that are thinning to restore original density.",
        },
        {
          num: "3",
          title:
            "Adding new hair to hair pieces that are thinning to restore original density.",
        },
        {
          num: "4",
          title:
            "Adding new hair to hair pieces that are thinning to restore original density.",
        },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    showMore() {
      this.showBtn = false;
    },
    submit() {},
    submits() {},
    displiog() {},
  },
};
</script>
<style lang="less" scoped>
.repair {
  .repair_content {
    .custom_img {
      img {
        width: 100%;
      }
    }
    .wkind {
      text-align: left;
      width: 70%;
      margin: 50px auto;
      .wkind_num {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        div {
          width: 40%;
        }
      }
      .kindimg {
        width: 50%;
        margin-left: 20%;
      }
    }
    .howto {
      width: 70%;
      margin: 30px auto;
      text-align: left;
      h2 {
        padding: 20px 0;
      }
    }
    .GetQuote {
      width: 70%;
      margin: 0 auto;
      .CONTACTs {
        display: none;
      }
      .CONTACT {
        text-align: center;
        padding: 50px 0;
        width: 100%;
        h2 {
          font-weight: 600;
        }
        .inputs {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          input {
            width: 45%;
            height: 50px;
            margin: 10px 0;
            border: none;
            border: 1px solid #cfd5db;
          }
        }
        textarea {
          border: none;
          border: 1px solid #cfd5db;
          width: 100%;
          height: 200px;
        }
        button {
          margin-top: 30px;
          background-color: #304e6a;
          border: 1px solid #304e6a;
          padding: 10px 1.5%;
          border-radius: 5px;
          color: #fff;
        }
        button:hover {
          background-color: #f9bd5a;
          border: 1px solid #f9bd5a;
        }
      }
    }
    .FAQs {
      width: 70%;
      margin: 0 auto;
      margin-bottom: 50px;
      h2 {
        padding: 10px 0;
        text-align: center;
      }
      button {
        color: #000;
        font-weight: 600;
      }
      .card {
        background-color: #fff;
      }
    }
    .top {
      img {
        width: 3%;
        height: 50px;
        position: fixed;
        z-index: 999;
        bottom: 50px;
        right: 50px;
      }
    }
    .tops {
      display: none;
    }
  }
}
</style>