<template>
  <div class="STOCK">
    <Nav />
    <div class="STOCK_content">
      <!-- banner -->
      <div class="content_banner">
        <swiper :bannerList="bannerList" />
      </div>
      <collapse>
        <div class="p" v-for="(item, index) in fontsing" :key="index">
          {{ item.headerfont }}
          <span v-show="isActive">
            <p>{{ item.our }}</p>
            <p>{{ item.all }}</p>
            <p>{{ item.numb }}</p>
          </span>
        </div>
      </collapse>
      <div
        v-if="showBtn"
        class="btning"
        @click="isActive = !isActive"
        style="
          color: #3e4c6a;
          text-align: right;
          padding-right: 20%;
          width: 100%;
          font-size: 16px;
        "
      >
        More
      </div>
      <div id="produce">
        <div class="produce_left">
          <proleft :navList="navList" @getId="getId" />
        </div>
        <div class="produce_right">
          <div class="right_divs">
            <div style="padding: 0px 15px">
              <p
                style="font-weight: 600; margin-bottom: 10px"
                v-show="this.divs == ''"
              >
                The website is being updated
              </p>
              <div style="flex-direction: column; display: flex">
                <div
                  class="typeDiv"
                  v-for="(item, findex) in divs"
                  :key="findex"
                >
                  <h5>{{ item.spec_name }}</h5>
                  <ul>
                    <li
                      @click="typeClick(items.spec_value, findex, sindex)"
                      v-for="(items, sindex) in item.spec_values"
                      :key="sindex"
                      :class="items.active == true ? 'active' : ''"
                    >
                      {{ items.spec_value }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <p style="font-weight: 600; margin: 15px" v-show="this.navimgs == ''">
            The website is being updated
          </p>
          <goods :goodsList="navimgs" :count="count" @getPage="getPage" />
        </div>
      </div>
      <!-- <nav
        aria-label="Page navigation example"
        style="margin-left: 23%; margin-top: 40px"
        class="pages2"
      >
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav> -->
      <div class="videoss">
        <!-- 视频播放器配置 -->
        <div class="video">
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            @timeupdate="onPlayerTimeupdate($event)"
            @ready="playerReadied"
          ></video-player>
          <!-- <b-button style="margin-left:40%;margin-top:5px" variant="outline-primary" @click="goBack">后退</b-button>
                  <b-button style="margin-left:20px;margin-top:5px" variant="outline-primary" @click="goFast">快进</b-button> -->
        </div>
        <!-- <p>How to Attach a Hair Replacement System to Your Customer’s Head</p> -->
      </div>
      <div class="gs">
        <div class="goodss">
          <p>
            Wide Range In-Stock Hair Systems & Custom Made Your Exclusive Hair
            Systems with Fast Speed!
          </p>
          <el-carousel :interval="4000" type="card" height="390px">
            <el-carousel-item
              v-for="(item, index) in imggood"
              :key="index"
              class="imggood"
            >
              <img :src="item.imgs" />
            </el-carousel-item>
          </el-carousel>
          <button>Learn More</button>
        </div>
      </div>
      <div class="before">
        <h2>Before&After</h2>
        <p>Regain your confidence with the quality hair from WigsZone!</p>
        <div class="imgss">
          <div v-for="(item, index) in imgs" :key="index">
            <img :src="item.imgs" alt="" @click="imgClick(item.id)" />
          </div>
        </div>
        <div class="BECOMES">
          <div class="Become">
            <div class="Become_dis">
              <img
                src="../../../src/assets/index/Company-Album41024x800.jpg"
                style="height: 500px; width: 100%"
              />
              <div
                class="Become_bg"
                style="background-color: #fff; opacity: 0.7"
              >
                <h2>Become a brand ambassador</h2>
                <ul>
                  <li
                    v-for="(item, index) in listli"
                    :key="index"
                    style="opacity: 1"
                  >
                    {{ item.li }}
                  </li>
                </ul>
                <p style="opacity: 1">
                  And most importantly – be a part of a supportive and uplifting
                  community of hair replacement specialists worldwide!
                </p>
                <p style="text-align: center; font-weight: 600">
                  It’s a rare opportunity, so don’t hesitate to get in before
                  your competition!
                </p>
                <button @click="inquiryNow">Apply now</button>
              </div>
            </div>
          </div>
          <div class="Become">
            <div class="Become_dis">
              <img
                src="../../../src/assets/index/Album3.jpg"
                style="height: 500px; width: 100%"
              />
              <div class="Become_bg">
                <h2>Join us as an exclusive wholesaler</h2>
                <p
                  style="
                    text-align: center;
                    font-weight: 600;
                    color: #f29d35;
                    font-size: 22px;
                  "
                >
                  Why join?
                </p>
                <ul>
                  <li
                    style="font-weight: 600; opacity: 0.9; font-size: 20px"
                    v-for="(item, index) in list"
                    :key="index"
                  >
                    {{ item.li }}
                  </li>
                </ul>
                <p style="opacity: 1">
                  And most importantly – be a part of a supportive and uplifting
                  community of hair replacement specialists worldwide!
                </p>

                <button @click="clickJoin">Apply now</button>
              </div>
            </div>
          </div>
        </div>

        <div class="BECOMES2">
          <div class="Become">
            <div class="Become_dis">
              <img
                src="../../../src/assets/index/Company-Album41024x800.jpg"
                style="height: 500px; width: 100%"
              />
              <div
                class="Become_bg"
                style="background-color: #fff; opacity: 0.7"
              >
                <h2>Become a brand ambassador</h2>
                <ul>
                  <li
                    v-for="(item, index) in listli"
                    :key="index"
                    style="opacity: 1"
                  >
                    {{ item.li }}
                  </li>
                </ul>
                <p style="opacity: 1">
                  And most importantly – be a part of a supportive and uplifting
                  community of hair replacement specialists worldwide!
                </p>
                <p style="text-align: center; font-weight: 600">
                  It’s a rare opportunity, so don’t hesitate to get in before
                  your competition!
                </p>
                <button @click="inquiryNow">Apply now</button>
              </div>
            </div>
          </div>
          <div class="Become">
            <div class="Become_dis">
              <img
                src="../../../src/assets/index/Album3.jpg"
                style="height: 500px; width: 100%"
              />
              <div class="Become_bg">
                <h2>Join us as an exclusive wholesaler</h2>
                <p
                  style="
                    text-align: center;
                    font-weight: 600;
                    color: #f29d35;
                    font-size: 22px;
                  "
                >
                  Why join?
                </p>
                <ul>
                  <li
                    style="font-weight: 600; opacity: 0.9; font-size: 20px"
                    v-for="(item, index) in list"
                    :key="index"
                  >
                    {{ item.li }}
                  </li>
                </ul>
                <p style="opacity: 1">
                  And most importantly – be a part of a supportive and uplifting
                  community of hair replacement specialists worldwide!
                </p>

                <button>Apply now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <submit />
      <!-- </div> -->
      <div class="top" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
      <div class="tops" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
import goods from "../../components/goods.vue";
import submit from "../../components/submit.vue";
import proleft from "../../components/proleft.vue";
import swiper from "../../components/swiper.vue";
import {
  getGoodsApi,
  getCategoryApi,
  getSpenApi,
  getBannerApi,
} from "../../api/index";
export default {
  components: {
    Footer,
    Nav,
    goods,
    submit,
    proleft,
    swiper,
  },
  data() {
    return {
      one: "",
      isActive: false,
      showBtn: true,
      currentIndex: 0,
      fontsing: [
        {
          headerfont:
            "As a wholesale toupee factory and manufacturer, TsingTao Hair WigsZone works to address worldwide best men’s stock hair systems to solve hair loss problems and deliver a positive impact on people’s lives.",
          our: "Our wide range of in-stock hair systems includes over 90,000 real toupees for both men and women. Choose from 50+ different men’s stock hair systems models in various colors. ",
          all: "All of our men’s stock hair systems are ready for immediate delivery and can be shipped to you within 3-5 business days no matter where you’re located in the world. For nearly two years, our on-site wholesale toupee factory, based in Qingdao, China has helped tens of thousands of those with hair loss problems gain back their younger look and changed their lives for the better. We’ve also helped countless business owners and hair professionals find increased revenue and success partnering with us. ",
          numb: "The number of men’s wholesale hair systems sellers that sell men’s toupees from TsingTao Hair WigsZone to invigorate their own businesses never stop growing. Our stock men’s hairpieces are widely recognized by our partners for their superior quality, low factory-direct prices, a large selection of hair system designs, and a first-rate client service system. By choosing men’s stock hair systems from us, you’ll set yourself on the path for rapid and consistent business growth. If you want to know more about our men’s stock hair systems, please contact us now!",
        },
      ],
      divs: [],
      ullis: [
        { lis: "8'×11'(1)" },
        { lis: "6'×8'(1)" },
        { lis: "6'×9'(1)" },
        { lis: "8'×11'(1)" },
        { lis: "8'×11'(1)" },
        { lis: "8'×11'(1)" },
        { lis: "8'×11'(1)" },
        { lis: "8'×11'(1)" },
      ],
      navimgs: [],
      imggood: [
        { imgs: require("../../../src/assets/stock/4.jpg") },
        { imgs: require("../../../src/assets/stock/5.jpg") },
        { imgs: require("../../../src/assets/stock/6.jpg") },
        { imgs: require("../../../src/assets/stock/7.jpg") },
        { imgs: require("../../../src/assets/stock/8.jpg") },
        { imgs: require("../../../src/assets/stock/9.jpg") },
        { imgs: require("../../../src/assets/stock/10.jpg") },
        { imgs: require("../../../src/assets/stock/11.jpg") },
        { imgs: require("../../../src/assets/stock/12.jpg") },
        { imgs: require("../../../src/assets/stock/13.jpg") },
        { imgs: require("../../../src/assets/stock/14.jpg") },
        { imgs: require("../../../src/assets/stock/15.jpg") },
        { imgs: require("../../../src/assets/stock/16.jpg") },
        { imgs: require("../../../src/assets/stock/17.jpg") },
        { imgs: require("../../../src/assets/stock/18.jpg") },
        { imgs: require("../../../src/assets/stock/19.jpg") },
        { imgs: require("../../../src/assets/stock/20.jpg") },
        { imgs: require("../../../src/assets/stock/21.jpg") },
      ],
      // Before & After 图集
      imgs: [
        { imgs: require("../../../src/assets/stock/22.jpg"), id: 22 },
        { imgs: require("../../../src/assets/stock/23.jpg"), id: 22 },
        { imgs: require("../../../src/assets/stock/24.jpg"), id: 22 },
        { imgs: require("../../../src/assets/stock/25.jpg"), id: 22 },
        { imgs: require("../../../src/assets/stock/26.jpg"), id: 22 },
        { imgs: require("../../../src/assets/stock/27.jpg"), id: 22 },
      ],
      listli: [
        { li: " FREE Hair Systems and Exciting Coupon Codes " },
        { li: " Exclusive WHOLESALE Discounts " },
        { li: "  Business Start-Up Guidance " },
        { li: "  Gain greater Social Media Exposure " },
      ],
      list: [
        { li: "Get the best wholesale prices" },
        { li: "Special offer for sample orders" },
        { li: "Access to product experts" },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        currentTime: "00:00",
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: require("../../assets/index/WeChat_20220830180644.mp4"), //本地视频使用方式，本地图片引入亦如此
            // src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",//视频地址
          },
        ],
        poster: this.url, //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      //一开始视频观看时长，可用于记录视频播放的时间，下一次直接从这个时间开始播放
      gklog: "",
      categoryId: null, //当前分类 id
      parentId: null, //默认分类父级Id
      navList: [], // 默认产品分类列表
      specId: [], //标签id
      count: null, //默认产品总个数
      page: 1, //默认页面数值
      radio3: "", //默认选中选项
      sameTrue: false,
      bannerList: [],
    };
  },
  created() {
    this.categoryId = this.$route.query.id;
    this.parentId = this.$route.query.parentId;
    this.country = this.$store.state.countryJson;
    this.getCategory(this.parentId); //获取分类信息 列表
    this.getSpec(this.categoryId); //获取标签 一级 二级
    // this.getGoodsList(this.categoryId, this.specId, this.page); //获取产品列表信息
    // this.getCbanner(); //获取轮播信息
    // this.getGoodsList(this.categoryId, this.page); //获取产品列表信息
    //  $(document).ready(function () {
    //     $(".produce_left>li a").each(function () {
    //         if ($($(this))[0].href == String(window.location))
    //             $(this).parent().addClass('active');
    //             $(this).style.backgroundcolor = "#fff"
    //     });
    // });
    // $(".produce_left li").click(function () {
    //   $(".produce_left li").removeClass("active");
    //   $(this).addClass("active");
    //      $(this).style.back-groundcolor = "#fff"
    // });
  },
  watch: {
    categoryId(newVal) {
      if (newVal != null) {
        this.getCategory(this.parentId); //获取分类信息 列表
        this.getSpec(newVal); //获取标签 一级 二级
        this.getGoodsList(this.categoryId, this.specId, this.page); //获取产品列表信息
        this.getCbanner();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.categoryId = to.query.id;
    this.parentId = to.query.parentId;
    next();
  },
  methods: {
    /**
     * 获取分类 banner轮播图片
     *
     */
    getCbanner() {
      getBannerApi(this.categoryId)
        .then((res) => {
          if (res && res.code && res.code == 1) {
            this.bannerList = res.data;
          }
        })
        .catch((err) => {
          if (err.code == 0 && err.data == "") {
            this.bannerList = [];
          }
        });
    },
    getId(e) {
      this.categoryId = e;
    },
    typeClick(id, findex, sindex) {
      // this.specId = id;
      let that = this;
      this.divs.forEach((res, b1index) => {
        if (findex == b1index) {
          res.spec_values.forEach((resSecond, b2index) => {
            if (sindex == b2index && !resSecond.active) {
              resSecond.active = true;
            } else {
              resSecond.active = false;
            }
          });
        }
      });
      let idss = []; //选中的规格id
      this.divs.forEach((item) => {
        item.spec_values.forEach((val) => {
          if (val.active) {
            idss.push(val.spec_value_id);
          }
        });
      });

      this.specId = idss;

      this.getGoodsList(this.categoryId, this.specId, this.page); //获取产品列表信息
    },
    changeSpec(e) {
      // console.log(e);
    },
    /**
     * 获取 当前页码
     */
    getPage(e) {
      this.page = e;
      this.getGoodsList(this.categoryId, this.specId, this.page); //获取产品列表信息
    },

    /**
     * 导航点击 跳转
     * @id 产品分类id
     */
    navClick(id, pId) {
      this.$router.push({
        path: "/Stock?id=" + id + "&parentId=" + pId,
      });
    },
    clickJoin() {
      this.$router.push({
        path: "/Joinnow",
      });
    },
    //inquiryNow
    inquiryNow() {
      this.$router.push({ path: "/Inquirynow" });
    },
    /**
     * before after 图片点击
     * @id 产品ID
     */
    imgClick(id) {
      this.$router.push({
        name: "?id=" + id,
      });
    },
    /* 设置视频进度 */
    playerReadied(player) {
      player.currentTime(this.gklog);
      // console.log("视频初始时长",this.gklog)
    },
    onPlayerTimeupdate(player) {
      this.gklog = player.cache_.currentTime; //获取当前播放时间
      // console.log(" onPlayerTimeupdate!", this.gklog);
    },

    liClick(index) {
      this.currentIndex = index;
    },
    btns1() {
      //     this.$router.push({
      //        path:'../Viewdetail'
      //     })
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    showMore() {
      this.showBtn = false;
    },
    submit() {},
    submits() {},
    displiog() {},
    //根据id跳转页面
    go(id) {
      // console.log(id, "id");
      // if(id==1){
      // windows.location.href = "Afro.vue"
      // this.$router.push('/home/first')//vue跳转
      // }
    },
    // 获取产品列表
    getGoodsList(categoryId, specId, page) {
      let goodsData = {};
      goodsData.category_id = categoryId;
      goodsData.spac_id = specId;
      goodsData.page = page;
      getGoodsApi(goodsData)
        .then((res) => {
          if (
            res &&
            res.code &&
            res.code == 1 &&
            res.data &&
            res.data.length !== 0
          ) {
            res.data.map((res) => {
              res.img_url.splice(2, res.img_url.length - 2);
            });
            this.navimgs = res.data;
            if (res.data == "") {
              this.navimgs = [];
            } else {
              this.navimgs = res.data;
            }
            this.count = res.count;
          }
        })
        .catch((err) => {
          // 报错
          if (err.code == 0 && err.data == "") {
            this.navimgs = [];
          }
        });
    },
    // 获取分类列表
    getCategory(id) {
      getCategoryApi(id).then((res) => {
        if (res && res.code && res.code == 1) {
          // console.log(res);
          this.navList = res.data;
        }
      });
    },
    /**
     * 获取产品标签
     * @category_id 分类id
     * divs
     */
    getSpec(id) {
      getSpenApi(id).then((res) => {
        if (res && res.code && res.code == 1) {
          res.data.map((res) => {
            res.spec_values.map((ress) => {
              ress.active = false;
            });
          });
          this.divs = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.typeDiv {
  h5 {
    font-size: 16px;
    font-weight: bold;
  }
  ul {
    display: flex;
    li {
      padding: 5px 8px;
      // background-color: #f00;
      margin: 5px !important;
      &.active {
        background-color: #f29d35;
        color: #fff;
      }
    }
  }
}
.video-js .vjs-tech {
  height: 360px;
  background: none;
}
//   .vjs_video_3-dimensions.vjs-fluid {
//     padding-top: 42.25%;
// }
.video {
  margin: 0 auto;
  margin-top: -100px;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  width: 50%;
  height: 150px;
  border-radius: 50%;
}

.active {
  background-color: #012340;
  color: #f00;
}
.disnavss {
  display: none;
}
.banerlefts {
  display: none;
}
.pages2 {
  display: none;
}
.tops {
  display: none;
}
.STOCK {
  .STOCK_content {
    .top {
      img {
        // width: 3%;
        // height: 50px;
        width: 42px;
        max-width: 100%;
        position: fixed;
        z-index: 999;
        bottom: 50px;
        right: 50px;
      }
    }

    width: 100%;
    .content_banner {
      position: relative;
      img {
        width: 100%;
      }
      .banerleft {
        position: absolute;
        color: #3e4450;
        width: 25%;
        top: 150px;
        left: 5%;
        p {
          font-size: 25px;
        }
      }
    }
    .p {
      width: 70%;
      text-align: left;
      margin: 20px auto;
      font-size: 17px;
    }
    #produce {
      li {
        list-style: none;
      }
      width: 70%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      .produce_left {
        width: 26%;
        // border: 2px solid #000;
        ul {
          // background-color: #F2F2F2;
          li {
            padding: 8px 5%;
            list-style: none;
            color: #000;
            background-color: #f2f2f2;
            cursor: pointer;
          }
          li:hover {
            background-color: #fff;
            width: 100%;
            border-bottom: none;
          }
        }
      }
      .produce_right {
        .right_divs {
          border: 15px solid #f2f2f2;
          padding: 10px 0;
          cursor: pointer;
        }
        width: 71%;

        a {
          // text-decoration: none;
          color: #000;
        }
        ul {
          li {
            font-size: 14px;
            margin-right: 20px;
          }
        }
      }
    }
    .disnav {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      cursor: pointer;
      .navimgs {
        width: 30%;
        .laceimg1 {
          display: none;
          width: 100%;
        }
        .laceimg {
          width: 100%;
        }
        p {
          height: 40px;
        }
        button {
          color: #fff;
          border: 1px solid #012340;
          background-color: #012340;
          width: 100%;
          padding: 8px 0;
          margin: 5px 0;
        }
        button:hover {
          background-color: #f29d35;
          border: 1px solid #f29d35;
        }
      }

      .navimgs:hover {
        .laceimg {
          display: none;
        }
        .laceimg1 {
          display: block;
        }
      }
    }
    .btning:hover {
      cursor: pointer; //变小手
      color: #f5a635;
    }
    .videoss {
      width: 100%;
      height: 600px;
      background: url(../../../src/assets/index/stt6.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      color: #fff;
      font-size: 24px;
      font-weight: 800;
      text-align: center;
      padding-block: 180px;
      img {
        width: 4%;
      }
      img:hover {
        transform: scale(1.2); //放大图片
        cursor: pointer; //变小手
        transition: all 0.7s;
      }
    }
    .gs {
      width: 100%;
      height: 1000px;
      background: url(../../../src/assets/index/31101229.jpg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .el-carousel__mask {
        background-color: transparent;
      }
      .goodss {
        margin: 40px auto;
        width: 70%;
        text-align: center;
        padding-top: 50px;

        p {
          color: #fff;
          font-size: 17px;
          padding: 100px;
          font-weight: 500;
        }
        .el-carousel__item {
          margin: 0 auto;
          img {
            width: 70%;
          }
          //   .el-carousel__mask {
          // background-color: transparent;//取消背景颜色

          //   }
        }
        button {
          background-color: #09161f;
          color: #fff;
          border: 3px solid #fff;
          padding: 8px 3%;
        }
      }
      .imggood {
        padding-left: 10%;
      }
    }
    .before {
      width: 70%;
      margin: 40px auto;
      text-align: center;
      .imgss {
        div {
          display: inline-block;
          max-width: 32.5%;
          margin: 8px 5px;
          cursor: pointer;
        }
        img {
          width: 99%;
        }
      }
      .imgss2 {
        display: none;
      }
    }
    .disnav2 {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .navimgs2 {
        width: 30%;
        .laceimg1 {
          display: none;
          width: 100%;
        }
        .laceimg {
          width: 100%;
        }
        p {
          height: 40px;
        }
        button {
          color: #fff;
          border: 1px solid #012340;
          background-color: #012340;
          width: 100%;
          padding: 8px 0;
          margin: 5px 0;
        }
        button:hover {
          background-color: #f29d35;
          border: 1px solid #f29d35;
        }
      }

      .navimgs2:hover {
        .laceimg {
          display: none;
        }
        .laceimg1 {
          display: block;
        }
      }
    }

    .BECOMES {
      display: flex;
      justify-content: space-around;
      .Become {
        // height: 500px;
        width: 49%;
        margin-top: 20px;
        // background: url();
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        // color: #012340;
        .Become_dis {
          position: relative;
        }
        .Become_bg {
          position: absolute;
          top: 25px;
          left: 5%;

          width: 90%;
          padding-top: 50px;
          button {
            border: none;
            border: 1px solid #0a2840;
            background-color: #0a2840;
            color: #fff;
            padding: 5px 3%;
            margin: 20px 0;
            // margin-left: 40%;
            text-align: center;
            border-radius: 18px;
            opacity: 1;
          }
          button:hover {
            background-color: #f29d35;
            border: 1px solid #f29d35;
            border-radius: 18px;
          }
          h2 {
            text-align: center;
            padding: 20px 0;
            opacity: 1;
          }
        }
      }
    }
    .BECOMES2 {
      display: none;
    }
    .FAQ {
      h2 {
        padding: 20px 0;
        text-align: center;
      }
      button {
        color: #000;
        font-weight: 600;
      }
    }
    .CONTACTs {
      display: none;
    }
    .CONTACT {
      text-align: center;

      padding: 50px 0;
      width: 80%;
      margin: 0 auto;
      h2 {
        font-weight: 600;
      }
      .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        input {
          width: 45%;
          height: 50px;
          margin: 20px 0;
          border: none;
          border: 1px solid #cfd5db;
        }
      }
      textarea {
        border: none;
        border: 1px solid #cfd5db;
        width: 95%;
        height: 200px;
      }
      button {
        margin-top: 30px;
        background-color: #304e6a;
        border: 1px solid #304e6a;
        padding: 10px 1.5%;
        border-radius: 5px;
        color: #fff;
      }
      button:hover {
        background-color: #f9bd5a;
        border: 1px solid #f9bd5a;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .STOCK .STOCK_content .before[data-v-7a0efa33] {
    width: 98%;
    .imgss {
      display: none;
    }
    .imgss2 {
      display: block;
      div {
        width: 99%;
      }
      img {
        width: 80%;
        padding: 10px 0;
      }
    }
    .BECOMES {
      display: none;
    }
    .BECOMES2 {
      display: block;
      .Become {
        // height: 500px;
        width: 98%;
        margin: 60px 0;
        .Become_dis {
          position: relative;
        }
        .Become_bg {
          position: absolute;
          top: 25px;
          left: 5%;
          width: 90%;
          button {
            border: none;
            border: 1px solid #0a2840;
            background-color: #0a2840;
            color: #fff;
            padding: 5px 3%;
            margin: 20px 0;
            text-align: center;
            border-radius: 18px;
            opacity: 1;
          }
          button:hover {
            background-color: #f29d35;
            border: 1px solid #f29d35;
            border-radius: 18px;
          }
          h2 {
            text-align: center;
            padding: 20px 0;
            opacity: 1;
          }
        }
      }
    }
  }

  .STOCK .STOCK_content .videoss img[data-v-7a0efa33] {
    width: 19%;
  }
  .top {
    display: none;
  }

  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
  .banerleft {
    display: none;
  }
  .produce_right {
    display: none;
  }
  .banerlefts {
    display: block;
    position: absolute;
    color: #3e4450;
    width: 60%;
    top: 10px;
    left: 5%;
    h1 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }
  .p {
    width: 98%;
    text-align: left;
    margin: 20px auto;
    font-size: 14px;
  }
  .produce_left {
    display: none;
  }
  .right_divs {
    display: none;
  }
  .disnavss {
    display: block;
    width: 100%;
    margin: 0 auto;
    .navimgs {
      width: 99%;
      .laceimg1 {
        display: none;
        width: 100%;
      }
      .laceimg {
        width: 100%;
      }
      p {
        height: 40px;
      }
      button {
        color: #fff;
        border: 1px solid #012340;
        background-color: #012340;
        width: 100%;
        padding: 8px 0;
        margin: 5px 0;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
      }
    }

    .navimgs:hover {
      .laceimg {
        display: none;
      }
      .laceimg1 {
        display: block;
      }
    }
  }
  .STOCK .STOCK_content .disnav2 .navimgs2 {
    width: 90%;
  }
  .pages2 {
    display: block;
  }
  .page1 {
    display: none;
  }
  .STOCK .STOCK_content .gs .goodss .el-carousel__item img[data-v-7a0efa33] {
    width: 100%;
  }
}
</style>