<template>
  <!-- input框 -->
  <div class="container">
    <!-- input框 -->
    <div style="width: 100%; background-color: #f2f2f2; margin: 2rem 0px 3rem">
      <div class="CONTACT">
        <h2>Become an exclusive wholesaler</h2>
        <div class="inputs">
          <!-- <input id="type" type="text" placeholder="请选择"> -->
          <input
            type="text"
            v-model="formData.account_username"
            id=""
            placeholder="Account Username*"
          />
          <input type="text" v-model="formData.email" placeholder="Email*" />
          <input
            type="text"
            v-model="formData.first_name"
            placeholder="First Name*"
          />
          <input
            type="text"
            v-model="formData.last_name"
            placeholder="Last Name *"
          />
          <input
            type="text"
            v-model="formData.country"
            placeholder="Country*"
            list="typelist"
          />
          <datalist id="typelist">
            <option v-for="(item, index) in country" :key="index">
              {{ item.label }}
            </option>
          </datalist>
          <input
            type="text"
            v-model="formData.phone_whatsapp"
            placeholder="Phone/whatsapp*"
          />
          <input
            type="text"
            v-model="formData.business_name"
            placeholder="Business name"
          />
          <input
            type="text"
            v-model="formData.business_role"
            list="inputlist"
            placeholder="Business Role*"
          />
          <datalist id="inputlist">
            <option v-for="(item, index) in inputlists" :key="index">
              {{ item.name }}
            </option>
          </datalist>
          <input
            type="text"
            v-model="formData.business_license_number"
            placeholder="Business License Number *"
            style="width: 95%"
          />
          <input
            type="text"
            v-model="formData.system_business"
            placeholder="Years of Your Hair System Business"
            style="width: 95%"
          />
          <p style="width: 100%; text-align: left; padding-left: 35px">
            Business Orientation
          </p>
          <div class="eselect">
            <el-select
              v-model="formData.mens_hairstyles"
              multiple
              placeholder="请选择"
              style="width: 47%"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="formData.ladies_hairstyle"
              multiple
              placeholder="请选择"
              style="width: 47%"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <textarea
          type=""
          v-model="formData.questions"
          style="margin-top: 20px"
          placeholder=" Please let us know if you have your own brand/instgram/facebook/youtube/website, any of them will help speed up the approval process, and we have a great discount for wholesale orders now!"
        ></textarea>
        <button @click="submit">Submit</button>
      </div>
    </div>
  </div>
</template>
<script>
import { getWholesaleApi } from "../api/index";
export default {
  name: "submitwho",
  props: {},
  data() {
    return {
      formData: {
        account_username: "", //必填	string	姓名
        email: "", //必填	string	电子邮件
        country: "", //必填	string	国家
        phone_whatsapp: "", //必填	string	手机号
        first_name: "", //必填	first_name	*
        last_name: "", //必填	last_name	*
        business_name: "", //必填	string	business_name
        business_role: "", //必填	string	business_role
        business_license_number: "", //必填	string	business_license_number
        system_business: "", //必填	string	system_business
        mens_hairstyles: "", //必填	string	mens_hairstyles
        ladies_hairstyle: "", //必填	string	ladies_hairstyle
        questions: "", //必填	string	questions
      },
      // role
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      options: [
        {
          value: "Stock hair system",
          label: "Stock hair system",
        },
        {
          value: "Custom hair system",
          label: "Custom hair system",
        },
        {
          value: "Afro toupee",
          label: "Afro toupee",
        },
      ],
      options2: [
        {
          value: "Hair system for hair loss",
          label: "Hair system for hair loss",
        },
        {
          value: "Human hair wig",
          label: "Human hair wig",
        },
        {
          value: "Hair topper",
          label: "Hair topper",
        },
        {
          value: "Hair extension",
          label: "Hair extension",
        },
        {
          value: "Full lace & lace front wig",
          label: "Full lace & lace front wig",
        },
        {
          value: "Hair bundle",
          label: "Hair bundle",
        },
      ],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    submit() {
      const regEmail =
        /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!regEmail.test(this.formData.email)) {
        this.$message.error("Please enter the correct email address!");
        return;
      }
      if (
        !this.formData.account_username ||
        !this.formData.email ||
        !this.formData.phone_whatsapp ||
        !this.formData.country ||
        !this.formData.first_name ||
        !this.formData.last_name ||
        !this.formData.business_license_number
      ) {
        this.$message.error("A required field was left blank!");
        return;
      }
      let nstring = this.formData.mens_hairstyles.join(",");
      this.formData.mens_hairstyles = nstring;
      let nstrings = this.formData.ladies_hairstyle.join(",");
      this.formData.ladies_hairstyle = nstrings;
      getWholesaleApi(this.formData).then((res) => {
        if (res && res.code && res.code == 1) {
          this.$alert("Thank you for your cooperation!", "Success", {
            confirmButtonText: "OK",
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.eselect {
  width: 98%;
  display: flex;
  justify-content: space-around;
}
.CONTACT {
  text-align: center;
  padding: 2rem 0;
  margin: 0 auto;
  h2 {
    font-weight: 600;
  }
  .inputs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    input {
      width: 45%;
      height: 50px;
      margin: 20px 0;
      border: none;
      border: 1px solid #cfd5db;
    }
  }
  textarea {
    border: none;
    border: 1px solid #cfd5db;
    width: 95%;
    height: 200px;
  }
  button {
    margin-top: 30px;
    background-color: #304e6a;
    border: 1px solid #304e6a;
    padding: 10px 1.5%;
    border-radius: 5px;
    color: #fff;
  }
  button:hover {
    background-color: #f9bd5a;
    border: 1px solid #f9bd5a;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 70%;
    max-width: 100%;
    padding: 0px 5px;
  }
}
</style>