import { render, staticRenderFns } from "./Human.vue?vue&type=template&id=3fb91fb1&scoped=true&"
import script from "./Human.vue?vue&type=script&lang=js&"
export * from "./Human.vue?vue&type=script&lang=js&"
import style0 from "./Human.vue?vue&type=style&index=0&id=3fb91fb1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fb91fb1",
  null
  
)

export default component.exports