<template>
  <div class="custom">
    <Nav />
    <div class="custom_content">
      <div class="custom_img" v-for="(item, index) in imgbanner" :key="index">
        <img :src="item.imgs" alt="" />
      </div>
      <nav aria-label="breadcrumb" style="background-color: #fff">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item"><router-link to="">Men</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">Custom</li>
        </ol>
      </nav>
      <div class="Customs">
        <h2>Custom</h2>
        <collapse>
          <div class="p">
            As a leading hair replacement system manufacturer dedicated to hair
            replacement across the globe, New Times Hair takes pride in
            producing top-notch men’s custom hair systems for our partners.
            Custom hair systems are a great option if you have customers who
            have their own individual needs and expectations for their hair
            systems that stock ones just can’t fulfill. In that case, custom
            hair system turns out to be the only way to win their business.
            <span v-show="isActive">
              <p>
                In spite of a higher cost and longer delivery time, custom hair
                systems are becoming increasingly popular among hair system
                wearers in many countries as people’s needs for their hair
                replacement systems become more diverse.
              </p>
              <p>
                Having manufactured thousands of men’s custom hairpieces, New
                Times Hair always puts its reputation ahead of everything.
                That’s why all of our custom hair systems are carefully
                handcrafted by the most experienced artisans using the finest
                materials. By doing so, we also achieve the goal of helping our
                partners gain trust and recognition from their customers. To
                customize the best hair replacement systems for your customers,
                it’s important to know what their true needs are with regard to
                different aspects of the hair system.
              </p>
              <p>
                If you’re interested in our hair replacement system cost, please
                feel free to contact us.
              </p>
            </span>
          </div>
        </collapse>
        <div
          v-if="showBtn"
          class="btning"
          @click="isActive = !isActive"
          style="
            color: #3e4c6a;
            text-align: right;
            padding-right: 20%;
            width: 100%;
            font-size: 16px;
          "
        >
          show more
        </div>
      </div>
      <div class="produce">
        <div class="produce_left">
          <ul>
            <li style="font-size: 25px; color: #fff; background-color: #012340">
              Product Categories
            </li>
            <li
              v-for="(item, index) in lilist"
              :key="index"
              @click="go(item.category_id)"
            >
              {{ item.name }}
            </li>
            <!-- <router-link to="/Stock"> <li>Stock</li></router-link>
            <router-link to="/Custom"> <li>Custom</li></router-link>
            <router-link to="/Lace"><li>Lace</li></router-link>
            <router-link to="/skin"><li>skin</li></router-link>
            <router-link to="/Mono"><li>Mono</li></router-link>
            <router-link to="/SilkTop"><li>Silk Top</li></router-link>
            <router-link to="/Frontal"><li>Frontal & Partial</li></router-link>
            <router-link to="/Crown"><li>Crown</li></router-link>
            <router-link to="/FullCap"><li>Full Cap</li></router-link>
            <router-link to="/Afro"><li>Afro</li></router-link>
            <router-link to="/Repair"><li>Repair</li></router-link>
            <router-link to="/Duplidete"><li>Duplicate</li></router-link> -->
          </ul>
        </div>
        <div class="produce_right">
          <h2>POPULAR CUSTOM BASE CASES</h2>
          <div class="right_divs">
            <div v-for="(item, index) in listing" :key="index">
              <img :src="item.imgs" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
          <div class="right_divsmd">
            <div v-for="(item, index) in listing" :key="index">
              <img :src="item.imgs" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            CUSTOMIZED BASE DESIGNS
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModal2"
          >
            CUSTOMIZED HAIR OPTIONS
          </button>

          <!-- Modal1   BASE DESIGNS -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <div
                    style="
                      display: flex;
                      justify-content: space-around;
                      text-align: left;
                    "
                  >
                    <h6
                      class="modal-title"
                      id="exampleModalLabel"
                      style="width: 15%"
                    >
                      Base design:
                    </h6>
                    <p>
                      There are more than 30 types of materials available,
                      including lace, skin, and mono to create the base design.
                      These can be combined to achieve hybrid base designs as
                      well.
                    </p>
                  </div>
                  <!-- 右上角x号 -->
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- 弹窗第一组 -->
                <div class="Lace">
                  <h6>Lace:</h6>
                  <div
                    v-for="(item, index) in Laceimg"
                    :key="index"
                    class="Laceimg"
                  >
                    <img :src="item.imgs" alt="" />
                    <div class="optic">{{ item.conton }}</div>
                  </div>
                </div>
                <!-- 弹窗第二组 -->
                <div class="Lace">
                  <h6>Skin:</h6>
                  <div
                    v-for="(item, index) in skinimg"
                    :key="index"
                    class="Laceimg"
                  >
                    <img :src="item.imgs" alt="" />
                    <div class="optic">{{ item.conton }}</div>
                  </div>
                </div>
                <!-- 弹窗第san组 -->
                <div class="Lace">
                  <h6>Mono:</h6>
                  <div
                    v-for="(item, index) in monoimg"
                    :key="index"
                    class="Laceimg"
                  >
                    <img :src="item.imgs" alt="" />
                    <div class="optic">{{ item.conton }}</div>
                  </div>
                </div>
                <div class="Baseing">
                  <h6>Base size:</h6>
                  <div class="donts">
                    <p>
                      Base size is the size of the cap of a hair system. We
                      categorize them into 4 types:
                    </p>
                    <p>
                      Partial size: Hair loss areas equal or are smaller than 4”
                      x 4”.
                    </p>
                    <p>
                      Regular size: Hair loss area is larger than 4”x 4” and up
                      to 10”x 8”.
                    </p>
                    <p>
                      Oversize: Hair loss area is larger than 10” x 8” and up to
                      10” x 10”.
                    </p>
                    <p>Full wig: Hair loss area is larger than 10” x 10”.</p>
                    <div style="display: flex; justify-content: space-around">
                      <div
                        v-for="(item, index) in hairloss"
                        :key="index"
                        class="hairlossing"
                      >
                        <img :src="item.imgs" alt="" />
                        <div class="hairloss">{{ item.opacy }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Baseing">
                  <h6>Front contour:</h6>
                  <div class="donts">
                    <p>
                      Front contour describes the shape of the front hairline.
                      Since this varies from individual to individual, we offer
                      7 different types of front contour systems to choose from.
                      It’s important to choose the right one that best matches
                      the front contour of the hair system wearer. This will
                      achieve a natural-looking front hairline that makes a hair
                      system look more realistic.
                    </p>
                    <div
                      style="
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                      "
                    >
                      <div
                        v-for="(item, index) in Front"
                        :key="index"
                        class="hairlossing"
                      >
                        <img :src="item.imgs" alt="" />
                        <div class="hairloss">{{ item.opacy }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-around;
                    margin-left: 5%;
                  "
                >
                  <h6>Bleached knots:</h6>
                  <div style="text-align: left; margin-left: 1%">
                    <p>
                      Knot bleaching works by having the hair put through a root
                      bleaching process after being knotted to the hair system
                      base to remove any dark color pigment from the hair.
                      Bleached knots will make the hair roots appear more
                      realistic. This technique only applies to lace material
                      and not skin base since there are no holes on skin
                      material for the bleach to penetrate.
                    </p>
                    <p>Area To Be Bleached Options:</p>
                    <p>
                      ront ½" Front 1" Parting area Crown area Bleached knots
                      all over
                    </p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal 22-->
          <div
            class="modal fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel"></h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="Haircolor">
                  <h6 style="padding-left: 2%; width: 15%">Hair color:</h6>
                  <div v-for="(item, index) in colorhair" :key="index">
                    <p>{{ item.acmacs }}</p>
                    <p>{{ item.regul }}</p>
                    <p>{{ item.hight }}</p>
                    <router-link to="">
                      <p>{{ item.view }}</p>
                    </router-link>
                  </div>
                </div>
                <div class="Hairlength">
                  <h6 style="width: 10%; padding-left: 2%">Hair length:</h6>
                  <p>
                    Hair length is the distance measured from the root to the
                    tip of the hair. Starting at 3 inches, the hair length of
                    our custom hair systems can be as long as
                    <span style="font-weight: 600"> 24 inches.</span>
                  </p>
                </div>
                <div class="Hairdensity">
                  <h6 style="width: 13%; padding-left: 2%">Hair density:</h6>
                  <div class="densitys">
                    <p>
                      Hair density is the measurement of the hair volume of a
                      hair system. The higher the hair density, the more hair
                      there is on the hair system. At New Times Hair, hair
                      density is classified into 6 levels that you can choose
                      from.
                    </p>
                    <div
                      style="
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                      "
                    >
                      <div
                        v-for="(item, index) in density"
                        :key="index"
                        class="densitying"
                      >
                        <img :src="item.imgings" alt="" />
                        <div class="disps">{{ item.light }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Hairtype">
                  <h6 style="width: 13%; padding-left: 2%">Hair type:</h6>
                  <div>
                    <p>
                      We have different types of hair available for the
                      production of custom hair systems:
                    </p>
                    <p>
                      Human hair (normal Indian hair, remy Indian hair, Chinese
                      hair, European hair), Synthetic hair, Yak
                    </p>
                  </div>
                </div>
                <div class="CurlWave">
                  <h6 style="width: 10%; padding-left: 2%">Curl & Wave:</h6>
                  <div>
                    <p>
                      We provide a wide selection of different hair curls and
                      waves ranging from straight to 4mm tight afro to meet the
                      needs of all wearers.
                    </p>
                    <div class="wave">
                      <div
                        v-for="(item, index) in Curl"
                        :key="index"
                        class="densitying"
                      >
                        <img :src="item.imgings" alt="" />
                        <div class="disps"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Hairdirection">
                  <h6 style="width: 12%; padding-left: 2%">Hair direction:</h6>
                  <div>
                    <p>
                      Customizing a hair also involves selecting a hairstyle or
                      hair direction. Here are the common hairstyles we offer:
                    </p>
                    <!-- <div class="wave"> 
                      <div v-for="(item,index) in dired" :key="index" class="densitying">
                        <img :src="item.imgings" alt="">
                        <div class="disps">{{item.light}}</div>
                    </div>
                  </div> -->
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="btn2">
            <button
              class="b1 btn-primary"
              type="button"
              data-toggle="modal"
              data-target="#customModal"
            >
              Custom new hair systems
            </button>
            <button class="b2">Download order form</button>
          </div>
          <!-- Modal -->
          <div
            class="modal fade"
            id="customModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">...</div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" class="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div style="margin: 50px 0">
            <h2>CUSTOM HAIR SYSTEM SHOW</h2>
            <div class="right_divs2">
              <div v-for="(item, index) in divs" :key="index">
                <p style="font-weight: 600; padding-left: 5%">
                  {{ item.title }}
                </p>
                <ul
                  style="display: flex; justify-content: left; flex-wrap: wrap"
                >
                  <li v-for="(item, index) in ullis" :key="index">
                    <router-link to=""> {{ item.lis }} </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="disnav">
            <div class="navimgs" v-for="(item, index) in navimgs" :key="index">
              <img :src="item.laceimg" alt="" class="laceimg" />
              <img :src="item.laceimg1" alt="" class="laceimg1" />
              <p style="font-size: 14px">{{ item.fonts }}</p>
              <button>{{ item.but1 }}</button>
              <button>{{ item.but2 }}</button>
            </div>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">4</a></li>
              <li class="page-item"><a class="page-link" href="#">5</a></li>
              <li class="page-item"><a class="page-link" href="#">6</a></li>
              <li class="page-item"><a class="page-link" href="#">7</a></li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="Still">
        <button>Help me custom</button>
      </div>
      <div class="FAQ">
        <h2>FAQ</h2>
        <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are the differences between a toupee and a wig?
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                With more and more people choosing non-surgical hair loss
                solutions, the men’s hair piece wholesale market has seen a
                significant increase in the types of stock hairpieces that are
                designed to cater to people’s needs more precisely and
                specifically. Therefore, many terms in the male hairpiece world
                often get mistaken for one another. The most common pair of
                terms that people confuse with one another is “toupee” and
                “wig.”<br />

                Both toupee and wig products are supposed to be worn on people’s
                heads for the purpose of hiding hair loss, but there are quite a
                few ways in which they differ from one another. A toupee is
                usually a small hair piece made of real human hair or synthetic
                hair that is worn at the top of the wearer’s scalp to cover a
                bald area, while a wig is meant to offer complete coverage of
                the whole head. That is why toupees are typically a lot smaller
                than wigs. Due to the differences in hair loss patterns between
                men and women, toupees are more commonly used by men to cover
                the top area of the head, which is more prone to baldness, while
                wigs are more popular among women who experience hair thinning
                problems.<br />

                Generally, it takes a lot more hair material and hair knotting
                work to produce a wig than it does for a toupee. Therefore,
                men’s professional wigs are undoubtedly more expensive. However,
                wigs are commonly seen as a more user-friendly option in the
                sense that you do not need to shave your head to attach one
                because tape and glue are not necessary to secure a wig to your
                head.<br />

                One other thing to note is that more and more people, including
                those without hair loss problems, are choosing to wear wigs for
                fashion purposes. Ultimately, whether you should buy a wig or a
                toupee for men largely depends on your hair loss pattern.
                Wholesale men’s toupees are more of an ideal option for most
                types of partial loss, and wholesale men’s wigs are typically
                recommended for those with general hair thinning problems or
                complete baldness.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What is the best men's stock hair systems wholesale?
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                Some placeholder content for the second accordion panel. This
                panel is hidden by default.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How canl get the right measurements for my clients?
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                And lastly, the placeholder content for the third and final
                accordion panel. This panel is hidden by default.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFOUR">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFOUR"
                  aria-expanded="false"
                  aria-controls="collapseFOUR"
                >
                  How canl get the right measurements for my clients?
                </button>
              </h2>
            </div>
            <div
              id="collapseFOUR"
              class="collapse"
              aria-labelledby="headingFOUR"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                How should l install a toupee for my clients?
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Why should l purchase men's stock hair systems wholesale from
                  New Times Hair?
                </button>
              </h2>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                And lastly, the placeholder content for the third and final
                accordion panel. This panel is hidden by default.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="StillGo">
        <!-- <h5>Don't want to wait for a long time？</h5> -->
        <button>Go for stock now</button>
      </div>
      <div class="BECOMES">
        <div class="Become">
          <div class="Become_dis">
            <img
              src="../../../src/assets/index/Company-Album41024x800.jpg"
              style="height: 500px; width: 100%"
            />
            <div class="Become_bg" style="background-color: #fff; opacity: 0.7">
              <h2>Become a brand ambassador</h2>
              <ul>
                <li
                  v-for="(item, index) in listli"
                  :key="index"
                  style="opacity: 1"
                >
                  {{ item.li }}
                </li>
              </ul>
              <p style="opacity: 1">
                And most importantly – be a part of a supportive and uplifting
                community of hair replacement specialists worldwide!
              </p>
              <p style="text-align: center; font-weight: 600">
                It’s a rare opportunity, so don’t hesitate to get in before your
                competition!
              </p>
              <button>Apply now</button>
            </div>
          </div>
        </div>
        <div class="Become">
          <div class="Become_dis">
            <img
              src="../../../src/assets/index/Album3.jpg"
              style="height: 500px; width: 100%"
            />
            <div class="Become_bg">
              <h2>Join us as an exclusive wholesaler</h2>
              <p
                style="
                  text-align: center;
                  font-weight: 600;
                  color: #f29d35;
                  font-size: 22px;
                "
              >
                Why join?
              </p>
              <ul>
                <li
                  style="font-weight: 600; opacity: 0.9; font-size: 20px"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  {{ item.li }}
                </li>
              </ul>
              <p style="opacity: 1">
                And most importantly – be a part of a supportive and uplifting
                community of hair replacement specialists worldwide!
              </p>

              <button>Apply now</button>
            </div>
          </div>
        </div>
      </div>
      <div class="BECOMES2">
        <div class="Become">
          <div class="Become_dis">
            <img
              src="../../../src/assets/index/Company-Album41024x800.jpg"
              style="height: 500px; width: 100%"
            />
            <div class="Become_bg" style="background-color: #fff; opacity: 0.7">
              <h2>Become a brand ambassador</h2>
              <ul>
                <li
                  v-for="(item, index) in listli"
                  :key="index"
                  style="opacity: 1"
                >
                  {{ item.li }}
                </li>
              </ul>
              <p style="opacity: 1">
                And most importantly – be a part of a supportive and uplifting
                community of hair replacement specialists worldwide!
              </p>
              <p style="text-align: center; font-weight: 600">
                It’s a rare opportunity, so don’t hesitate to get in before your
                competition!
              </p>
              <button>Apply now</button>
            </div>
          </div>
        </div>
        <div class="Become">
          <div class="Become_dis">
            <img
              src="../../../src/assets/index/Album3.jpg"
              style="height: 500px; width: 100%"
            />
            <div class="Become_bg">
              <h2>Join us as an exclusive wholesaler</h2>
              <p
                style="
                  text-align: center;
                  font-weight: 600;
                  color: #f29d35;
                  font-size: 22px;
                "
              >
                Why join?
              </p>
              <ul>
                <li
                  style="font-weight: 600; opacity: 0.9; font-size: 20px"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  {{ item.li }}
                </li>
              </ul>
              <p style="opacity: 1">
                And most importantly – be a part of a supportive and uplifting
                community of hair replacement specialists worldwide!
              </p>

              <button>Apply now</button>
            </div>
          </div>
        </div>
      </div>
      <!-- input框 -->
      <div style="width: 100%; background-color: #f2f2f2">
        <!-- input框 -->
        <div class="CONTACT">
          <h2>INQUIRIES & QUESTIONS</h2>
          <div class="inputs">
            <!-- <input id="type" type="text" placeholder="请选择"> -->

            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.label }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>

          <button @click="submit">Submit</button>
        </div>
        <!-- input框 -->
        <div class="CONTACTs">
          <h2>INQUIRIES & QUESTIONS</h2>
          <div class="inputs">
            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.label }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>

          <button @click="submits">Submit</button>
        </div>
      </div>
      <div class="top" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
      <div class="tops" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
import { getCategory } from "../../api/index";
// import collapse from "../../../src/js/collapse.js";
export default {
  components: {
    Footer,
    Nav,
  },
  created() {
    var data = {
      category_id: 1,
    };
    //获取商品分类接口
    getCategory(data).then((res) => {
      this.lilist = res.data;
      this.category_id = res.data.category_id;
    });
  },
  data() {
    return {
      category_id: "",
      lilist: [],
      imgbanner: [
        {
          imgs: require("../../assets/index/0220622144038.png"),
        },
      ],
      one: "",
      isActive: false,
      showBtn: true,
      listing: [
        {
          imgs: require("../../../src/assets/index/NW0194324-Lace.jpg"),
          title: "Full Face",
        },

        {
          imgs: require("../../../src/assets/index/NW0194324-Lace.jpg"),
          title: "Full Face",
        },

        {
          imgs: require("../../../src/assets/index/NW0194324-Lace.jpg"),
          title: "Full Face",
        },
        {
          imgs: require("../../../src/assets/index/NW0194324-Lace.jpg"),
          title: "Full Face",
        },
        {
          imgs: require("../../../src/assets/index/NCF000418.jpg"),
          title: "Full Face",
        },
        {
          imgs: require("../../../src/assets/index/NCF000418.jpg"),
          title: "Full Face",
        },
        {
          imgs: require("../../../src/assets/index/NCF000418.jpg"),
          title: "Full Face",
        },
        {
          imgs: require("../../../src/assets/index/NCF000418.jpg"),
          title: "Full Face",
        },
      ],
      divs: [
        {
          title: "BASE SIZE",
        },
        {
          title: "BASE DESIGN",
        },
        {
          title: "MEN'S STOCK HAIR COLOR",
        },
        {
          title: "BASE SIZE",
        },
        {
          title: "BASE SIZE",
        },
        {
          title: "BASE SIZE",
        },
        {
          title: "BASE SIZE",
        },
      ],
      ullis: [
        { lis: "8'×11'(1)" },
        { lis: "6'×8'(1)" },
        { lis: "6'×9'(1)" },
        { lis: "8'×11'(1)" },
        { lis: "8'×11'(1)" },
        { lis: "8'×11'(1)" },
        { lis: "8'×11'(1)" },
        { lis: "8'×11'(1)" },
      ],
      navimgs: [
        {
          laceimg: require("../../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HS7 Full Lace Men’s Hair Piece With Realistic Hairline Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts: "No-Shine Hair Extension Tape Rolls 1/4″ x 6 Yards",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          laceimg: require("../../../src/assets/index/HSR-1-Head-Template-Replicator.jpg"),
          laceimg1: require("../../../src/assets/index/HSR-2-Head-Template-Replicator.jpg"),
          fonts:
            "HSR-1 Head Template Replicator with Heating Film for Hair Replacement System",
          but1: "Quick View",
          but2: "Login to view prices",
        },
      ],
      list: [
        { li: "Get the best wholesale prices" },
        { li: "Special offer for sample orders" },
        { li: "Access to product experts" },
      ],
      listli: [
        { li: " FREE Hair Systems and Exciting Coupon Codes " },
        { li: " Exclusive WHOLESALE Discounts " },
        { li: "  Business Start-Up Guidance " },
        { li: "  Gain greater Social Media Exposure " },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
      Laceimg: [
        {
          imgs: require("../../assets/index/64-French-Lace.jpg"),
          conton: "French lace",
        },
        {
          imgs: require("../../assets/index/64-French-Lace.jpg"),
          conton: "Swiss lace",
        },
      ],
      skinimg: [
        {
          imgs: require("../../assets/index/Custom-Skin(1)@2x.jpg"),
          conton: "0.03mm",
        },
        {
          imgs: require("../../assets/index/Custom-Skin(1)@2x.jpg"),
          conton: "0.08mm",
        },
      ],
      monoimg: [
        {
          imgs: require("../../assets/index/Custom-Mono@2x.jpg"),
          conton: "Fine mono",
        },
        {
          imgs: require("../../assets/index/Custom-Mono@2x.jpg"),
          conton: "Fine mono",
        },
      ],
      hairloss: [
        {
          imgs: require("../../assets/index/partial-2.jpg"),
          opacy: "Hair loss areasequal or are  smaller than 4x4",
        },

        {
          imgs: require("../../assets/index/partial-2.jpg"),
          opacy: "Hair loss areasequal or are  smaller than 4x4",
        },
        {
          imgs: require("../../assets/index/partial-2.jpg"),
          opacy: "Hair loss areasequal or are  smaller than 4x4",
        },
        {
          imgs: require("../../assets/index/partial-2.jpg"),
          opacy: "Hair loss areasequal or are  smaller than 4x4",
        },
      ],
      Front: [
        {
          imgs: require("../../assets/index/Standard1-1.jpg"),
          opacy: "A",
        },
        {
          imgs: require("../../assets/index/Standard1-1.jpg"),
          opacy: "A",
        },
        {
          imgs: require("../../assets/index/Standard1-1.jpg"),
          opacy: "A",
        },
        {
          imgs: require("../../assets/index/Standard1-1.jpg"),
          opacy: "A",
        },
        {
          imgs: require("../../assets/index/Standard1-1.jpg"),
          opacy: "A",
        },
        {
          imgs: require("../../assets/index/Standard1-1.jpg"),
          opacy: "A",
        },
        {
          imgs: require("../../assets/index/Standard1-1.jpg"),
          opacy: "A",
        },
        {
          imgs: require("../../assets/index/Standard1-1.jpg"),
          opacy: "A",
        },
      ],
      colorhair: [
        {
          acmacs:
            "It’s important to choose a color that matches the hair color of the wearer. You may either send us a sample of the hair from the wearer and we will do a custom color match or choose the color from our color ring. Our custom hair systems can be made in any hair color. ",
          regul:
            "Regular color: #1, #1A, #1B, #2, #3, #4,#4ASH, #5, #6,#7, #7ASH, #8, #10, #12 #18, #20, #22, #60…and any dark color with different percentages of gray hair like 1B05, ,1B10, 1B20, 1B30, 1B40, 1B50",
          hight:
            "Highlight: Blend, T-color (Ombre color), Spot color,  Chessboard",
          view: "View our hair color chart",
        },
      ],
      density: [
        {
          imgings: require("../../assets/index/Hair-density1@2x.jpg"),
          light: "light",
        },
        {
          imgings: require("../../assets/index/Hair-density1@2x.jpg"),
          light: "light",
        },
        {
          imgings: require("../../assets/index/Hair-density1@2x.jpg"),
          light: "light",
        },
        {
          imgings: require("../../assets/index/Hair-density1@2x.jpg"),
          light: "light",
        },
        {
          imgings: require("../../assets/index/Hair-density1@2x.jpg"),
          light: "light",
        },
        {
          imgings: require("../../assets/index/Hair-density1@2x.jpg"),
          light: "light",
        },
      ],
      Curl: [
        {
          imgings: require("../../assets/index/Custom-Curl-Wave5@2x-1.jpg"),
          light: "straight",
        },
        {
          imgings: require("../../assets/index/Custom-Curl-Wave5@2x-1.jpg"),
          light: "straight",
        },
        {
          imgings: require("../../assets/index/Custom-Curl-Wave5@2x-1.jpg"),
          light: "straight",
        },
        {
          imgings: require("../../assets/index/Custom-Curl-Wave5@2x-1.jpg"),
          light: "straight",
        },
        {
          imgings: require("../../assets/index/Custom-Curl-Wave5@2x-1.jpg"),
          light: "straight",
        },
      ],
      direc: [
        {
          imgings: require("../../assets/index/Hair-direction2-@2x.jpg"),
        },
      ],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    submits() {},
    submit() {},
    go(category_id) {
      // // if(category_id)
      //  this.$router.push({
      //   path:""
      //  })
    },
  },
};
</script>
<style lang="less" scoped>
.modal-dialog {
  max-width: 70%;
  margin: 1.75rem auto;
}
.top {
  img {
    width: 3%;
    height: 50px;
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
  }
}
.tops {
  display: none;
}
li {
  list-style: none;
}
.custom {
  width: 100%;
  .custom_content {
    .custom_img {
      img {
        width: 100%;
      }
    }
    .breadcrumb {
      padding-left: 10%;
    }
    .Customs {
      width: 70%;
      margin: 0 auto;
      line-height: 30px;
    }
    .produce {
      width: 70%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      .produce_left {
        width: 30%;
        ul {
          li {
            padding: 8px 5%;
            list-style: none;
            color: #000;
            background-color: #f2f2f2;
          }
          li:hover {
            background-color: #fff;
            width: 100%;
            border-bottom: none;
          }
        }
      }
      .right_divsmd {
        display: none;
      }
      .produce_right {
        text-align: center;
        width: 68%;
        .right_divs {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          div {
            width: 25%;
            img {
              width: 100%;
            }
            img:hover {
              transform: scale(1.1);
            }
          }
        }

        button {
          background-color: #f29d35;
          color: #fff;
          padding: 6px 2%;
          border: 1px solid #f29d35;
          margin: 25px 2%;
        }
        button:hover {
          background-color: #fff;
          color: #000;
          border: 1px solid #fff;
        }
        .btn2 {
          margin-top: 50px;
          width: 100%;
          height: 250px;
          background: url("../../../src/assets/index/cases.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          button {
            margin-top: 100px;
          }
          .b1 {
            background-color: #012340;
            border-radius: 20px;
            color: #fff;
            border: 1px solid #012340;
          }
          .b2 {
            background-color: #fff;
            border-radius: 20px;
            color: #000;
            border: 1px solid #fff;
          }
          button:hover {
            background-color: #f29d35;
            border: 1px solid #f29d35;
            color: #fff;
          }
        }
        .right_divs2 {
          margin: 50px 0;
          border: 15px solid #f2f2f2;
          padding: 10px 0;
          text-align: left;

          a {
            // text-decoration: none;
            color: #000;
          }
          ul {
            li {
              padding: 0 1%;
              font-size: 13px;
            }
          }
        }
        .disnav {
          width: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          .navimgs {
            width: 30%;
            .laceimg1 {
              display: none;
              width: 100%;
            }
            .laceimg {
              width: 100%;
            }
            p {
              height: 40px;
            }
            button {
              color: #fff;
              border: 1px solid #012340;
              background-color: #012340;
              width: 100%;
              padding: 8px 0;
              margin: 5px 0;
            }
            button:hover {
              background-color: #f29d35;
              border: 1px solid #f29d35;
            }
          }

          .navimgs:hover {
            .laceimg {
              display: none;
            }
            .laceimg1 {
              display: block;
            }
          }
        }
      }
    }
    .Still {
      width: 70%;
      margin: 50px auto;
      height: 250px;
      background: url("../../../src/assets/index/cases.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      button {
        background-color: #012340;
        border: 1px solid #012340;
        color: #fff;
        border-radius: 20px;
        padding: 5px 2%;
        float: right;
        margin-right: 10%;
        margin-top: 130px;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
      }
    }
    .StillGo {
      width: 70%;
      margin: 50px auto;
      height: 250px;
      background: url("../../../src/assets/index/cases.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      button {
        background-color: #fff;
        border: 1px solid #fff;
        color: #012340;
        border-radius: 20px;
        padding: 5px 2%;
        float: right;
        margin-right: 10%;
        margin-top: 130px;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
      }
    }
    .FAQ {
      width: 70%;
      margin: 0 auto;
      h2 {
        padding: 5px 0;
        text-align: center;
        margin-top: 22px;
      }
      button {
        color: #000;
        font-weight: 600;
      }
    }

    .BECOMES {
      width: 70%;
      margin: 50px auto;
      display: flex;
      justify-content: space-around;
      .Become {
        // height: 500px;
        width: 49%;
        margin-top: 20px;
        .Become_dis {
          position: relative;
        }
        .Become_bg {
          position: absolute;
          top: 25px;
          left: 5%;

          width: 90%;
          padding-top: 50px;
          button {
            border: none;
            border: 1px solid #0a2840;
            background-color: #0a2840;
            color: #fff;
            padding: 5px 3%;
            margin: 20px 0;
            margin-left: 40%;
            text-align: center;
            border-radius: 18px;
            opacity: 1;
          }
          button:hover {
            background-color: #f29d35;
            border: 1px solid #f29d35;
            border-radius: 18px;
          }
          h2 {
            text-align: center;
            padding: 20px 0;
            opacity: 1;
          }
        }
      }
    }
    .BECOMES2 {
      display: none;
    }
    .CONTACTs {
      display: none;
    }
    .CONTACT {
      text-align: center;

      padding: 50px 0;
      width: 80%;
      margin: 0 auto;
      h2 {
        font-weight: 600;
      }
      .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        input {
          width: 45%;
          height: 50px;
          margin: 20px 0;
          border: none;
          border: 1px solid #cfd5db;
        }
      }
      textarea {
        border: none;
        border: 1px solid #cfd5db;
        width: 95%;
        height: 200px;
      }
      button {
        margin-top: 30px;
        background-color: #304e6a;
        border: 1px solid #304e6a;
        padding: 10px 1.5%;
        border-radius: 5px;
        color: #fff;
      }
      button:hover {
        background-color: #f9bd5a;
        border: 1px solid #f9bd5a;
      }
    }
  }
}
.Lace {
  display: flex;
  justify-content: left;
  margin-left: 5%;

  h6 {
    padding-top: 10px;
  }
  .Laceimg {
    position: relative;
    margin: 20px 5%;

    img {
      width: 100%;
      height: 300px;
    }
    .optic {
      width: 100%;
      height: 300px;
      background-color: #ccc;
      color: #fff;
      font-size: 30px;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      text-align: center;
      padding-top: 210px;
    }
  }
  .Laceimg:hover {
    .optic {
      display: block;
    }
  }
}
.Baseing {
  display: flex;
  justify-content: left;
  margin-left: 5%;
  .donts {
    text-align: left;
    margin-left: 1%;

    .hairlossing {
      position: relative;
      // display: flex;
      //
      img {
        width: 100%;
        height: 200px;
      }
      .hairloss {
        width: 100%;
        height: 200px;
        background-color: #ccc;
        color: #fff;
        font-size: 30px;
        opacity: 0.8;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        text-align: center;
      }
    }
  }
  .hairlossing:hover {
    .hairloss {
      display: block;
    }
  }
}
.Haircolor {
  display: flex;
  justify-content: left;
  text-align: left;
}
.Hairlength {
  display: flex;
  text-align: left;
  justify-content: left;
}
.Hairdensity,
.Hairtype {
  display: flex;
  text-align: left;
  justify-content: left;
}

.densitys {
  img {
    width: 100%;
    height: 250px;
  }
  .densitying {
    position: relative;
    text-align: center;

    .disps {
      background-color: #ccc;
      color: #fff;
      font-size: 30px;
      height: 250px;
      display: none;
      opacity: 0.8;
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
    }
  }
  .densitying:hover {
    .disps {
      display: block;
    }
  }
}
.wave {
  display: flex;
  justify-content: space-around;
  img {
    width: 100%;
    height: 200px;
  }
  .densitying {
    position: relative;
    text-align: center;

    .disps {
      background-color: #ccc;
      color: #fff;
      font-size: 30px;
      height: 200px;
      display: none;
      opacity: 0.8;
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
    }
  }
  .CurlWave,
  .Hairdirection {
    display: flex;
    margin: 20px 0;
    justify-content: space-around;
    text-align: left;
  }
  .densitying:hover {
    .disps {
      display: block;
    }
  }
}

@media screen and (max-width: 390px) {
  .custom .custom_content .BECOMES[data-v-4476c2a4] {
    width: 100%;
    display: none;
  }
  .custom .custom_content .BECOMES2[data-v-4476c2a4] {
    display: block;
    width: 98%;
    margin: 50px auto;
    .Become {
      // height: 500px;
      width: 100%;
      margin-top: 20px;
      .Become_dis {
        position: relative;
      }
      .Become_bg {
        position: absolute;
        top: 25px;
        left: 5%;
        width: 90%;
        // padding-top: 50px;
        button {
          border: none;
          border: 1px solid #0a2840;
          background-color: #0a2840;
          color: #fff;
          padding: 5px 3%;
          margin: 20px 0;
          margin-left: 40%;
          text-align: center;
          border-radius: 18px;
          opacity: 1;
        }
        button:hover {
          background-color: #f29d35;
          border: 1px solid #f29d35;
          border-radius: 18px;
        }
        h2 {
          text-align: center;
          padding: 20px 0;
          opacity: 1;
        }
      }
    }
  }
  .produce_left {
    display: none;
  }
  .tops {
    display: block;
    width: 3%;
    height: 50px;
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
  }
  .top {
    display: none;
  }
  .produce_right {
    width: 90%;
    h2 {
      font-size: 15px;
    }
    .right_divs {
      div {
        display: none;
      }
    }
    .right_divsmd {
      display: block;
      div {
        width: 80%;
        img {
          width: 100%;
        }
      }
      img {
        width: 100%;
      }
      // img:hover{
      //   // transform: 0.1;
      // }
    }
  }
  .custom .custom_content .produce .produce_right[data-v-4476c2a4] {
    width: 100%;
  }
  .custom .custom_content .produce .produce_right .btn2[data-v-4476c2a4] {
    height: 150px;
  }
  .custom
    .custom_content
    .produce
    .produce_right
    .btn2
    button[data-v-4476c2a4] {
    margin: 15px 0;
  }
  .custom .custom_content .produce[data-v-4476c2a4] {
    width: 99%;
  }
  .custom
    .custom_content
    .produce
    .produce_right
    .disnav
    .navimgs[data-v-4476c2a4] {
    width: 40%;
  }
  .custom .custom_content .Still[data-v-4476c2a4] {
    width: 100%;
  }
  .custom .custom_content .Still button[data-v-4476c2a4] {
    margin-right: 32%;
  }
  .custom .custom_content .FAQ[data-v-4476c2a4] {
    width: 98%;
  }
  .custom .custom_content .StillGo[data-v-4476c2a4] {
    width: 100%;
  }
  .custom .custom_content .StillGo button[data-v-4476c2a4] {
    margin-right: 32%;
  }
}
</style>