<template>
  <div class="cases">
    <Nav />
    <div class="cases_content">
      <div class="contentbanner">
        <img src="../assets/index/cases.png" />
        <div class="position">
          <!-- <h1>NEW TIMES HAIR</h1> -->
        </div>
      </div>
      <div class="fonts">
        <p>
          We are so happy to receive stories and feedback from our customers on
          how they successfully grow their business or transform their clients
          with Newtimes Hair! It’s a great pleasure that we are helpful to the
          success of your business.
        </p>
        <p>
          Share your story to us or leave us a review, we would love to hear
          from you and try our best to make you satisfied!
        </p>
      </div>
      <div class="Custome">
        <h2>Customer Stories</h2>
        <p>
          See how New Times Hair help stylists, salons across the globe grow
          their business.
        </p>
        <div
          class="Custome_class"
          style="display: flex; justify-content: space-around"
        >
          <div v-for="(item, index) in imgs" :key="index" class="Custome_box">
            <img :src="item.img" alt="" />
            <div class="optic">{{ item.boxs }}</div>
          </div>
        </div>
      </div>
      <div class="Customes">
        <h2>Customer Stories</h2>
        <p>
          See how New Times Hair help stylists, salons across the globe grow
          their business.
        </p>
        <div class="Custome_class">
          <div v-for="(item, index) in imgs" :key="index" class="Custome_box">
            <img :src="item.img" alt="" />
            <div class="optic">{{ item.boxs }}</div>
          </div>
        </div>
      </div>
      <div class="Before">
        <h2>Before&After</h2>
        <p>
          See how New Times Hair helps hair stylists transform their clients and
          gain their confidence back!
        </p>
        <div style="display: flex; justify-content: space-around">
          <div v-for="(item, index) in imglist" :key="index" class="imglist">
            <img :src="item.img" alt="" />
            <!-- <img :src="item.imgs" alt=""> -->
          </div>
        </div>
        <div class="headerdiv">
          <div v-for="(item, index) in header" :key="index" class="header">
            <img :src="item.img" alt="" />
            <p>{{ item.titles }}</p>
            <button>Quick View</button>
            <button>Login to view prices</button>
          </div>
        </div>
      </div>
      <div class="Befores">
        <h2>Before&After</h2>
        <p>
          See how New Times Hair helps hair stylists transform their clients and
          gain their confidence back!
        </p>
        <div>
          <div v-for="(item, index) in imglist" :key="index" class="imglist">
            <img :src="item.img" alt="" />
            <!-- <img :src="item.imgs" alt=""> -->
          </div>
        </div>
        <div>
          <div v-for="(item, index) in header" :key="index" class="header">
            <img :src="item.img" alt="" />
            <p>{{ item.titles }}</p>
            <button>Quick View</button>
            <button>Login to view prices</button>
          </div>
        </div>
      </div>
      <div class="WHOLESALE">
        <h2>WHOLESALE CUSTOMER FEEDBACK</h2>
        <p>Hear voices and feedback from New Times Hair real customers.</p>
        <div>
          <el-carousel :interval="5000" arrow="always" style="height: 650px">
            <el-carousel-item
              style="height: 650px"
              v-for="(item, index) in listphoto"
              :key="index"
              class="alwasing"
            >
              <div class="always">
                <div class="always_box">
                  <img :src="item.img" alt="" />
                  <p>{{ item.content }}</p>
                  <p>{{ item.name }}</p>
                </div>
              </div>
              <div class="always">
                <div class="always_box">
                  <img :src="item.img" alt="" />
                  <p>{{ item.content }}</p>
                  <p>{{ item.name }}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="WHOLESALEs">
        <h2>WHOLESALE CUSTOMER FEEDBACK</h2>
        <p>Hear voices and feedback from New Times Hair real customers.</p>
        <div>
          <el-carousel :interval="5000" arrow="always" style="height: 500px">
            <el-carousel-item
              v-for="(item, index) in listphotos"
              style="height: 500px"
              :key="index"
              class="alwasing"
            >
              <div class="always">
                <div class="always_box">
                  <img :src="item.img" alt="" />
                  <p>{{ item.content }}</p>
                  <p>{{ item.name }}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- input框 -->
      <div style="width: 100%; background-color: #f2f2f2">
        <!-- input框 -->
        <div class="CONTACT">
          <h2>CONTACT NOW</h2>
          <div class="inputs">
            <!-- <input id="type" type="text" placeholder="请选择"> -->

            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.name }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>

          <button @click="submit">Submit</button>
        </div>
        <!-- input框 -->
        <div class="CONTACTs">
          <h2>CONTACT NOW</h2>
          <div class="inputs">
            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.label }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>

          <button @click="submits">Submit</button>
        </div>
      </div>
    </div>
    <div class="top" @click="toTop">
      <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
    <div class="tops" @click="toTop">
      <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      one: "",
      imgs: [
        {
          img: require("../../src/assets/index/hair-business-story-casey.png"),
          boxs: "CASEY",
        },
        {
          img: require("../../src/assets/index/hair-business-story-newtimeshair-client-todd.png"),
          boxs: "CASEY",
        },
        {
          img: require("../../src/assets/index/hair-business-story-casey.png"),
          boxs: "CASEY",
        },
      ],
      //   imgsing: [
      //     {
      //       img: require("../../src/assets/index/hair-business-story-casey.png"),
      //       boxs: "CASEY",
      //     },
      //     {
      //       img: require("../../src/assets/index/hair-business-story-newtimeshair-client-todd.png"),
      //       boxs: "CASEY",
      //     },
      //     {
      //       img: require("../../src/assets/index/hair-business-story-casey.png"),
      //       boxs: "CASEY",
      //     },
      //   ],
      imglist: [
        {
          img: require("../../src/assets/index/MEN.jpg"),
        },
        {
          img: require("../../src/assets/index/MEN.jpg"),
        },
        {
          img: require("../../src/assets/index/MEN.jpg"),
        },
      ],
      header: [
        {
          img: require("../../src/assets/index/HS7.jpg"),
          titles:
            "HS7 Full Lace Men’s Hair Piece With Realistic Hairline Wholesale",
        },
        {
          img: require("../../src/assets/index/HS7.jpg"),
          titles:
            "HS7 Full Lace Men’s Hair Piece With Realistic Hairline Wholesale",
        },
        {
          img: require("../../src/assets/index/HS7.jpg"),
          titles:
            "HS7 Full Lace Men’s Hair Piece With Realistic Hairline Wholesale",
        },
      ],
      listphoto: [
        {
          img: require("../../src/assets/index/newtimeshair-review-2.jpg"),
          content:
            "Choosing a trustorthy and long-term supplier is a tricky thing for me before until lmet New Timezs Hair. I still remembered my first impression of the bulk ordersfrom you, they are just fabulous and l am really pleased! The logistics, patient andconsiderate customer service, and so on.Lucky to meet you all along to boost mybusiness in futurel",
          name: "Ryan",
        },
        {
          img: require("../../src/assets/index/newtimeshair-review-2.jpg"),
          content:
            "Choosing a trustorthy and long-term supplier is a tricky thing for me before until lmet New Timezs Hair. I still remembered my first impression of the bulk ordersfrom you, they are just fabulous and l am really pleased! The logistics, patient andconsiderate customer service, and so on.Lucky to meet you all along to boost mybusiness in futurel",
          name: "Ryan",
        },
        {
          img: require("../../src/assets/index/newtimeshair-review-2.jpg"),
          content:
            "Choosing a trustorthy and long-term supplier is a tricky thing for me before until lmet New Timezs Hair. I still remembered my first impression of the bulk ordersfrom you, they are just fabulous and l am really pleased! The logistics, patient andconsiderate customer service, and so on.Lucky to meet you all along to boost mybusiness in futurel",
          name: "Ryan",
        },
      ],
      listphotos: [
        {
          img: require("../../src/assets/index/newtimeshair-review-2.jpg"),
          content:
            "Choosing a trustorthy and long-term supplier is a tricky thing for me before until lmet New Timezs Hair. I still remembered my first impression of the bulk ordersfrom you, they are just fabulous and l am really pleased! The logistics, patient andconsiderate customer service, and so on.Lucky to meet you all along to boost mybusiness in futurel",
          name: "Ryan",
        },
        {
          img: require("../../src/assets/index/newtimeshair-review-2.jpg"),
          content:
            "Choosing a trustorthy and long-term supplier is a tricky thing for me before until lmet New Timezs Hair. I still remembered my first impression of the bulk ordersfrom you, they are just fabulous and l am really pleased! The logistics, patient andconsiderate customer service, and so on.Lucky to meet you all along to boost mybusiness in futurel",
          name: "Ryan",
        },
        {
          img: require("../../src/assets/index/newtimeshair-review-2.jpg"),
          content:
            "Choosing a trustorthy and long-term supplier is a tricky thing for me before until lmet New Timezs Hair. I still remembered my first impression of the bulk ordersfrom you, they are just fabulous and l am really pleased! The logistics, patient andconsiderate customer service, and so on.Lucky to meet you all along to boost mybusiness in futurel",
          name: "Ryan",
        },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    //submit按钮
    submit() {
      // if(this.one == ""){
      //   alert("请填写姓名")
      //   return
      // }
    },
    submits() {
      // if(this.one == ""){
      //   alert("请填写姓名")
      //   return
      // }
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="less" scoped>
.WHOLESALEs {
  display: none;
}
.Befores {
  display: none;
}
.Customes {
  display: none;
}
.CONTACTs {
  display: none;
}

.top {
  img {
    width: 3%;
    height: 50px;
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
    width: 5%;
  }
}
.tops {
  display: none;
}
.cases {
  .cases_content {
    .contentbanner {
      position: relative;
      img {
        width: 100%;
        height: 400px;
        background-size: 100% 100%;
      }
      .position {
        text-shadow: #01244b 3px 3px;
        position: absolute;
        left: 35%;
        top: 250px;
        color: #fff;
        h1 {
          font-weight: 700;
        }
        p {
          font-size: 24px;
        }
      }
    }
    .fonts {
      width: 70%;
      margin: 0 auto;
      padding: 40px 0;
      font-size: 18px;
      color: #8a8080;
    }
    .Custome {
      width: 70%;
      margin: 0 auto;
      text-align: center;

      h2 {
        color: #002548;
      }
      .Custome_class {
        text-align: center;
        .Custome_box {
          width: 30%;
          text-align: center;
          position: relative;
          img {
            width: 80%;
            height: 300px;
          }
          .optic {
            width: 80%;
            height: 300px;
            background-color: #8e8d8b;
            position: absolute;
            top: 0;
            left: 10%;
            opacity: 0.5;
            display: none;
          }
        }
      }
      .Custome_box:hover {
        .optic {
          display: block;
          color: #000;
        }
      }
    }

    .Before {
      padding: 50px 0;
      width: 70%;
      margin: 0 auto;
      text-align: center;

      h2 {
        color: #002548;
      }
      .imglist {
        img {
          width: 99%;
          height: 300px;
        }
      }
      .headerdiv {
        display: flex;
        justify-content: space-around;
        padding: 20px 0;
        width: 95%;
        margin: 0 auto;
        .header {
          text-align: left;
          // margin: 0 auto;
          img {
            width: 90%;
          }
          button {
            background-color: #012340;
            border: none;
            border: 1px solid #012340;
            padding: 10px 2%;
            color: #fff;
            width: 90%;
            margin: 5px 0;
          }
          button:hover {
            background-color: #f29d35;
            border: 1px solid #f29d35;
          }
        }
      }
    }
    .WHOLESALE {
      width: 100%;
      text-align: center;
      .el-carousel__item h3 {
        // color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 600px;
        margin: 0;
      }
      .alwasing {
        width: 90%;
        padding-left: 10%;
        display: flex;
        justify-content: space-around;
        .always {
          width: 50%;
          margin: 0 auto;
          text-align: center;
          .always_box {
            img {
              width: 90%;
            }
            p {
              color: #000;
              // width: 50%;
              z-index: 999;
              text-align: left;
            }
          }
        }
      }
    }
    .CONTACT {
      text-align: center;

      padding: 50px 0;
      width: 80%;
      margin: 0 auto;
      h2 {
        font-weight: 600;
      }
      .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        input {
          width: 45%;
          height: 50px;
          margin: 20px 0;
          border: none;
          border: 1px solid #cfd5db;
        }
      }
      textarea {
        border: none;
        border: 1px solid #cfd5db;
        width: 95%;
        height: 200px;
      }
      button {
        margin-top: 30px;
        background-color: #304e6a;
        border: 1px solid #304e6a;
        padding: 10px 1.5%;
        border-radius: 5px;
        color: #fff;
      }
      button:hover {
        background-color: #f9bd5a;
        border: 1px solid #f9bd5a;
      }
    }
  }
}
@media screen and (max-width: 390px) {
  .top {
    display: none;
  }
  .WHOLESALE {
    display: none;
  }
  .Custome {
    display: none;
  }
  .Customes {
    display: block;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    h2 {
      color: #002548;
    }
    .Custome_class {
      text-align: center;
      .Custome_box {
        padding: 20px 0;
        width: 100%;
        text-align: center;
        position: relative;
        img {
          width: 100%;
          height: 300px;
        }
        .optic {
          width: 100%;
          height: 300px;
          background-color: #8e8d8b;
          position: absolute;
          top: 20px;
          left: 0;
          opacity: 0.5;
          display: none;
        }
      }
    }
    .Custome_box:hover {
      .optic {
        display: block;
        color: #000;
      }
    }
  }
  .Before {
    display: none;
  }
  .Befores {
    display: block;
    padding: 50px 0;
    width: 90%;
    margin: 0 auto;
    text-align: center;

    h2 {
      color: #002548;
    }
    .imglist {
      img {
        width: 100%;
        height: 300px;
        padding: 10px 0;
      }
    }
    .header {
      text-align: left;
      margin: 0 auto;
      img {
        width: 99%;
      }
      button {
        background-color: #012340;
        border: none;
        border: 1px solid #012340;
        padding: 10px 2%;
        color: #fff;
        width: 99%;
        margin: 5px 0;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
      }
    }
  }
  .WHOLESALEs {
    display: block;
    width: 100%;
    text-align: center;
    .el-carousel__item h3 {
      // color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 600px;
      margin: 0;
    }
    .alwasing {
      .always {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        .always_box {
          img {
            width: 90%;
          }
          p {
            color: #000;
            margin: 0 auto;
            width: 90%;
            z-index: 999;
            text-align: left;
          }
        }
      }
    }
  }
  .CONTACT {
    display: none;
  }
  .CONTACTs {
    text-align: center;
    display: block;
    padding: 50px 0;
    width: 90%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      input {
        width: 100%;
        height: 50px;
        border: none;
        border: 1px solid #cfd5db;
        margin: 10px 0;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }
  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
}
</style>