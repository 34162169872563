<template>
  <div class="supplies">
    <Nav />
    <!-- 20221010 -->
    <img
      src="../assets/stock/29.jpg"
      alt="become a brand ambassador"
      class="imgAmb"
    />
    <submitwho />
    <Footer />
    <div class="top" @click="toTop">
      <img
        style="width: 3%; height: 50px"
        src="../../src/assets/index/xiala_un@2x.png"
        alt=""
      />
    </div>
    <div class="tops" @click="toTop">
      <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
import Submitwho from "../components/submitwho.vue";
export default {
  components: {
    Footer,
    Nav,
    Submitwho,
  },
  data() {
    return {
      formData: {
        userName: "",
        fName: "",
        lName: "",
        countryValue: "",
        phoneNum: "",
        businessName: "",
        businessRole: "",
        businessNumber: "",
        remark: "",
      },
      stanList: {},
      one: "",
      inputlists: [
        // { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
    };
  },
  created() {
    this.countryList = this.$store.state.countryJson;
    this.toTop();
  },
  methods: {
    submit() {
      // console.log(this.formData);
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="less" scoped>
.imgAmb {
  width: 100%;
  margin: 0 auto;
}
.CONTACT {
  text-align: center;

  padding: 50px 0;
  width: 80%;
  margin: 0 auto;
  h2 {
    font-weight: 600;
  }
  .inputs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    input {
      width: 45%;
      height: 50px;
      margin: 20px 0;
      border: none;
      border: 1px solid #cfd5db;
    }
  }
  textarea {
    border: none;
    border: 1px solid #cfd5db;
    width: 95%;
    height: 200px;
  }
  button {
    margin-top: 30px;
    background-color: #304e6a;
    border: 1px solid #304e6a;
    padding: 10px 1.5%;
    border-radius: 5px;
    color: #fff;
  }
  button:hover {
    background-color: #f9bd5a;
    border: 1px solid #f9bd5a;
  }
}
.top {
  img {
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
  }
}
.tops {
  display: none;
}

@media screen and (max-width: 390px) {
  .top {
    display: none;
  }

  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
}
</style>