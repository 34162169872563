<template>
  <!-- input框 -->
  <div class="container">
    <div style="width: 100%; background-color: #f2f2f2; margin: 2rem 0px 3rem">
      <!-- input框 -->

      <div class="CONTACT">
        <h2>INQUIRIES & QUESTIONS</h2>
        <div class="inputs">
          <!-- <input id="type" type="text" placeholder="请选择"> -->

          <input type="text" v-model="formData.name" placeholder="Name*" />
          <input
            type="email"
            v-model="formData.email"
            name="email"
            placeholder="Email Address*"
          />
          <input
            type="text"
            name="country"
            placeholder="Country*"
            list="typelist"
            v-model="formData.country"
          />
          <datalist id="typelist">
            　　
            <option v-for="(item, index) in country" :key="index">
              {{ item.label }}
            </option>
          </datalist>
          <input
            type="text"
            name="phone_whatsapp"
            v-model="formData.phone_whatsapp"
            id=""
            placeholder="Phone/whatsapp*"
          />
          <input
            type="text"
            name="business_name"
            v-model="formData.business_name"
            id=""
            placeholder="Business name"
          />
          <input
            type="text"
            name="role"
            list="inputlist"
            placeholder="Select your business role..*"
            v-model="formData.role"
          />
          <datalist id="inputlist">
            　　
            <option v-for="(item, index) in inputlists" :key="index">
              {{ item.name }}
            </option>
          </datalist>
        </div>
        <textarea
          type=""
          name="questions"
          placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          v-model="formData.questions"
        ></textarea>

        <button @click="submit">Submit</button>
      </div>
    </div>
  </div>
</template>
<script>
import { getQuestionApi } from "../api/index";
export default {
  name: "submit",
  props: {},
  data() {
    return {
      formData: {
        name: "", //必填	string	姓名
        business_name: "", //name	必填	string	公司名称
        country: "", //必填	string	国家
        phone_whatsapp: "", //必填	string	手机号
        email: "", //必填	string	电子邮件
        role: "", //必填	string	业务角色
        questions: "", //必填	string	问题内容
      },
      // role
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    submit() {
      const regEmail =
        /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!regEmail.test(this.formData.email)) {
        this.$message.error("Please enter the correct email address!");
        return;
      }
      if (
        !this.formData.name ||
        !this.formData.email ||
        !this.formData.phone_whatsapp ||
        !this.formData.country ||
        !this.formData.role
      ) {
        this.$message.error("A required field was left blank!");
        return;
      }
      getQuestionApi(this.formData).then((res) => {
        if (res && res.code && res.code == 1) {
          this.$alert("Thank you for your cooperation!", "Success", {
            confirmButtonText: "OK",
            callback: (action) => {},
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.CONTACT {
  text-align: center;
  padding: 2rem 0;
  margin: 0 auto;
  h2 {
    font-weight: 600;
  }
  .inputs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    input {
      width: 45%;
      height: 50px;
      margin: 20px 0;
      border: none;
      border: 1px solid #cfd5db;
    }
  }
  textarea {
    border: none;
    border: 1px solid #cfd5db;
    width: 95%;
    height: 200px;
  }
  button {
    margin-top: 30px;
    background-color: #304e6a;
    border: 1px solid #304e6a;
    padding: 10px 1.5%;
    border-radius: 5px;
    color: #fff;
  }
  button:hover {
    background-color: #f9bd5a;
    border: 1px solid #f9bd5a;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 70%;
    max-width: 100%;
    padding: 0px 5px;
  }
}
</style>