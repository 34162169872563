<template>
  <div class="kind">
        <Nav />
    <div class="kind_content">
      <div v-for="(item, index) in list" :key="index">
        <img :src="item.img" alt="" />
        <h3>{{ item.h3 }}</h3>
        <p>{{ item.h5 }}</p>
      </div>
      <div v-for="(item, index) in contents" :key="index" class="contents">
        <h3>{{ item.h3 }}</h3>
        <p>{{ item.content }}</p>
        <img :src="item.img" alt="" />
      </div>
    </div>
      <Footer />
   <div class="top" @click="toTop">
        <img
          style="width: 3%; height: 50px"
          src="../../src/assets/index/xiala_un@2x.png"
          alt=""
        />
      </div>
      <div class="tops" @click="toTop">
        <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
   components: {
    Footer,
    Nav,
  },
  data() {
    return {
      list: [
        {
          img: require("../assets/index/20220624145225.png"),
          h3: "WHAT KIND OF HAIR SYSTEM IS SUITABLE FOR ME?",
          h5: "WigsZone offer 5 different hair systems for you:",
        },
      ],
      contents: [
        {
          h3: "Skin hair system:",
          content:
            "Natural、invisible, the translucent poly system base perfectly matches your skin color and offers the invisible front hairline. If you choose the thinnest poly, you will have the most natural appearance but it can only last a few weeks.",
          img: require("../assets/index/20220624161834.png"),
        },
           {
          h3: "Lace hair system:",
          content:"Comfortable、breathable、lightweight、natural, you can barely feel it when you are on it. The material prevents most allergic reactions as well as injuries to the scalp and skin. Even in hot and humid weather conditions, you can keep comfortable",
          img: require("../assets/index/20220624162325.png"),
        },
           {
          h3: "Mono hair system:",
          content:
            "Durable、breathable、natural、easy to clean and install with both tape and glue, a complete mono-filament base is light and breathable, yet strong enough to take the weight of hair. It is soft to the touch. It is basically hard to see through and very hard to detect. The honeycombed structure allows the heat and sweat to escape in the hot weather.",
          img: require("../assets/index/20220624162346.png"),
        },
              {
          h3: "Silk base hair system:",
          content:
            "Silk Top: Silk top hair systems are designed for the most discriminating of wearers, those who want the most realistic look. The silk top human hair system comes with a base consisting of two layers of highly durable and realistic silk mono that imitates a user’s scalp complexion. The exceptionally natural look is achieved through an intricate sewing technique where each individual strand of hair is tightly knotted onto the under layer before passing through the top layer of the base. This gives the appearance that each strand of hair is “growing” right from the scalp which is extremely realistic & NO knots.",
          img: require("../assets/index/20220624162459.png"),
        },
               {
          h3: "Bionic scalp hair system:",
          content:
            "Each single hair strand is tightly injected into the base to present a completely knotless appearance of hair growing out of scalp, guaranteeing its top level of realism. This gives the appearance that each strand of hair is “growing” right from the scalp which is extremely realistic & NO knots.In addition, there is a small number of punched holes scattered around the base to give the wearers extra breathable feeling, especially in the hot weathers.",
          img: require("../assets/index/20220624162610.png"),
        },
      ],
    };
  },
  methods:{
          toTop() {
      document.documentElement.scrollTop = 0;
    },
  }
};
</script>
<style lang="less" scoped>
 .top {
    img {
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 50px;
    }
  }
  .tops{display: none;}
.kind {
  text-align: center;
  width: 100%;
  .kind_content {
    width: 75%;
    margin: 0 auto;
    .contents {
      text-align: left;
      margin: 30px 0;
      p{
        padding: 20px 0;
      }
      img{
        width: 40%;
        margin-left: 20%;
      }
    }
  }
}
@media screen and (max-width: 390px) {
   .top {
    display: none;
  }

  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
}
</style>