<template>
  <div class="imgdetail">
    <Nav />
    <div class="detail_content">
        <div>

        </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
};
</script>
<style lang="less" scoped>
.imgdetail{
    .detail_content{
        width: 100%;
    }
}
</style>