<template>
  <div class="MOREs">
      <h3>More</h3>

    <div class="MOREing">
      <div
        class="card" 
        v-for="(item, index) in list"
        :key="index"
      >
        <img :src="item.img" class="card-img-top" alt="..." />
        <div class="card-body">
          <h4>{{ item.h4 }}</h4>
          <p class="card-text">{{ item.card_content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          img: require("../assets/index/stock-custom-1-.jpg"),
          h4: "Should you go for a stock or custom made hair system order?",
          card_content:
            "When you are looking to purchase a men’s toupee, you will either place an order for one of two options — a stock hair system or a custom made hair system. What are the differences between the two and",
        },
         {
          img: require("../assets/index/stock-custom-1-.jpg"),
          h4: "Should you go for a stock or custom made hair system order?",
          card_content:
            "When you are looking to purchase a men’s toupee, you will either place an order for one of two options — a stock hair system or a custom made hair system. What are the differences between the two and",
        },
         {
          img: require("../assets/index/stock-custom-1-.jpg"),
          h4: "Should you go for a stock or custom made hair system order?",
          card_content:
            "When you are looking to purchase a men’s toupee, you will either place an order for one of two options — a stock hair system or a custom made hair system. What are the differences between the two and",
        },
                {
          img: require("../assets/index/stock-custom-1-.jpg"),
          h4: "Should you go for a stock or custom made hair system order?",
          card_content:
            "When you are looking to purchase a men’s toupee, you will either place an order for one of two options — a stock hair system or a custom made hair system. What are the differences between the two and",
        },
                {
          img: require("../assets/index/stock-custom-1-.jpg"),
          h4: "Should you go for a stock or custom made hair system order?",
          card_content:
            "When you are looking to purchase a men’s toupee, you will either place an order for one of two options — a stock hair system or a custom made hair system. What are the differences between the two and",
        },
         {
          img: require("../assets/index/stock-custom-1-.jpg"),
          h4: "Should you go for a stock or custom made hair system order?",
          card_content:
            "When you are looking to purchase a men’s toupee, you will either place an order for one of two options — a stock hair system or a custom made hair system. What are the differences between the two and",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.MOREs {
    text-align: center;
    padding: 40px 0;
  .MOREing {
    width: 75%;
    margin: 40px auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .card{
    width: 30%;
    margin: 40px 0;
    }
  }
}
</style>