import { render, staticRenderFns } from "./WIGSZONE.vue?vue&type=template&id=3160abe0&scoped=true&"
import script from "./WIGSZONE.vue?vue&type=script&lang=js&"
export * from "./WIGSZONE.vue?vue&type=script&lang=js&"
import style0 from "./WIGSZONE.vue?vue&type=style&index=0&id=3160abe0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3160abe0",
  null
  
)

export default component.exports