<template>
  <div>
    <ul>
      <li style="font-size: 25px; color: #fff; background-color: #012340">
        Product Categories
      </li>
      <div
        v-for="(item, index) in navList"
        :key="index"
        @click="navClick(item.category_id, item.parent_id)"
      >
        <li>{{ item.name }}</li>
      </div>
    </ul>
  </div>
</template>
<script>
export default {
  name: "proleft",
  props: {
    navList: {
      type: Array,
      default: null,
    },
    parentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  created() {},
  methods: {
    navClick(id, pId) {
      this.id = id;
      if (pId == 1) {
        this.$router.push({
          path: "/Stock?id=" + id + "&parentId=" + pId,
        });
      } else if (pId == 2) {
        this.$router.push({
          path: "/Intergration?id=" + id + "&parentId=" + pId,
        });
      } else if (pId == 4) {
        this.$router.push({
          path: "/Extensions?id=" + id + "&parentId=" + pId,
        });
      } else if (pId == 5) {
        this.$router.push({
          path: "/Access?id=" + id + "&parentId=" + pId,
        });
      }
      this.$emit("getId", this.id);
    },
  },
};
</script>
<style lang="less" scoped>
ul {
  // background-color: #F2F2F2;
  li {
    padding: 8px 5%;
    list-style: none;
    color: #000;
    background-color: #f2f2f2;
    cursor: pointer;
  }
  li:hover {
    background-color: #fff;
    width: 100%;
    border-bottom: none;
  }
}
</style>