<template>
  <div class="helpones">
    <Nav @gethelpslist="gethelpslist" />
    <div class="helponescont">
      <div class="cont_left">
        <h4>Categories</h4>
        <router-link to="/Help"><h6>HELP CENTER</h6></router-link>
        <el-row class="tac">
          <el-col :span="12" style="width: 85%">
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
            >
              <el-submenu
                :index="index + ''"
                v-for="(item, index) in helpList"
                :key="index"
              >
                <template slot="title">
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    :index="index + '-' + sindex"
                    v-for="(items, sindex) in item.list"
                    :key="sindex"
                    ><router-link
                      :to="{
                        name: 'Attaching',
                        query: { id: items.article_id },
                      }"
                      >{{ items.article_title }}</router-link
                    ></el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="cont_right">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/helptemplate' }"
            >HELP CENTER</el-breadcrumb-item
          >
          <el-breadcrumb-item>TEMPLATES AND SAMPLES</el-breadcrumb-item>
          <el-breadcrumb-item style="color: red">{{
            title
          }}</el-breadcrumb-item>
        </el-breadcrumb> -->
        <h4 style="margin: 20px 0">{{ title }}</h4>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
import { getArticleIndexApi } from "../../api/index";
export default {
  components: { Footer, Nav },
  data() {
    return {
      title: "How to make a template",
      actialList: [],
      helpList: [],
    };
  },
  created() {
    this.getActialList();
    this.gethelpslist();
  },
  methods: {
    /**
     * 从nav组建中获取已经得到的新闻导航 数据
     */
    gethelpslist(e) {
      this.helpList = e;
    },
    handleOpen() {},
    handleClose() {},
    // 获取文章导航
    getActialList() {
      getArticleIndexApi().then((res) => {
        if (res && res.code && res.code == 1) {
          this.actialList = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
a {
  list-style: none;
  text-decoration: none;
  color: #000;
}
.el-menu-item {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.helpones {
  width: 100%;
  .helponescont {
    width: 80%;
    margin: 50px auto;
    display: flex;
    justify-content: space-around;
    .cont_left {
      width: 30%;
      h4 {
        border-bottom: 1px solid #e5e5e5;
        width: 85%;
        padding-bottom: 7px;
      }
      h6 {
        padding: 5px 0;
      }
      .el-menu {
        border-right: none;
      }
      .el-submenu .el-menu-item {
        height: 41px;
        line-height: 41px;
      }
    }
    .cont_right {
      width: 65%;
    }
  }
}
</style>