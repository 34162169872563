<template>
  <div class="hairless">
    <Nav />
    <div class="hairless_content">
      <div class="custom_img">
        <img src="../../../src/assets/index/looking.png" alt="" />
      </div>
      <div class="content_fonts">
        <!-- <h2>Looking for The Right Hair Loss & Thinning Hair Solutions?</h2>
        <h2>You Can Count on Us!</h2> -->
        <p>
          Hair loss or thinning hair can be an emotional experience for women of
          all ages whatever the causes are. It can erode self-esteem, causing an
          unwanted change to their image. That’s why more and more women with
          thinning hair problems are seeking different kinds of china wig
          products such as custom made hair pieces, custom wigs for alopecia,
          and other custom wigs products to improve their self-image as well as
          confidence.
        </p>
        <p>
          As one of the leading custom made hair pieces wholesalers and women’s
          toupee suppliers in the world with more than 100 hair wig designs, New
          Times Hair has helped more than 40,000 women suffering from hair
          thinning problems to achieve the appearance they’ve only dreamed
          about. Through our strong partnerships with numerous local custom made
          hair pieces suppliers around the world, we’ve helped return youthful
          beauty to so many women.
        </p>
        <p>
          Our custom made hair pieces have been praised by our clients’
          customers for many reasons. Customers rave about their natural look,
          comfort, durability, and material superiority. Aside from offering a
          wide range of stock custom made hair pieces in different hair colors
          and base sizes, we have a customization option for producing custom
          made hair pieces according to the users’ requests.
        </p>
      </div>
      <div class="tabs">
        <div class="womensmenu">
          <span @click="but(1)" :class="{ active: tabs === 1 }">
            <p>Beginning Stage</p>
          </span>
          <span @click="but(2)" :class="{ active: tabs === 2 }">
            <p>Developing Stage</p>
          </span>
          <span @click="but(3)" :class="{ active: tabs === 3 }">
            <p>Advanced Stage</p>
          </span>
          <span @click="but(4)" :class="{ active: tabs === 4 }">
            <p>Completely Bald Stage</p>
          </span>
          <span @click="but(5)" :class="{ active: tabs === 5 }">
            <p>Unpatterned Hair Loss</p>
          </span>
        </div>
        <div class="tabspan">
          <span v-if="tabs == 1" active>
            <div class="Beginning">
              <img src="../../../src/assets/index/Beginning.jpg" alt="" />
              <div class="begin">
                <p style="font-size: 19px">Beginning Stage</p>
                <p style="line-height: 25px">
                  At this stage, hair loss starts with a diffused pattern on the
                  middle line near the part. Women at this hair loss stage can
                  wear a small-sized hair topper or hair integration system with
                  large holes to add more volume to their hair.
                </p>
              </div>
            </div>
            <div class="disnav">
              <div
                class="navimgs"
                v-for="(item, index) in navimgs"
                :key="index"
              >
                <img :src="item.laceimg" alt="" class="laceimg" />
                <img :src="item.laceimg1" alt="" class="laceimg1" />
                <router-link to=""
                  ><p style="font-size: 15px">{{ item.fonts }}</p></router-link
                >
              </div>
            </div>
            <div class="Before">
              <h2>Before & After</h2>
              <div class="after">
                <img src="../../../src/assets/index/before.jpg" alt="" />
                <img src="../../../src/assets/index/sbeforejpg.jpg" alt="" />
              </div>
              <div class="after">
                <img
                  src="../../../src/assets/index/Getholesale.jpg"
                  alt=""
                  @click="img1"
                />
                <img
                  src="../../../src/assets/index/etwholesale.jpg"
                  alt=""
                  @click="img2"
                />
              </div>
            </div>
          </span>
          <span v-if="tabs == 2">
            <div class="Beginning">
              <img src="../../../src/assets/index/Beginning.jpg" alt="" />
              <div class="begin">
                <p style="font-size: 19px">Beginning Stage</p>
                <p style="line-height: 25px">
                  At this stage, hair loss starts with a diffused pattern on the
                  middle line near the part. Women at this hair loss stage can
                  wear a small-sized hair topper or hair integration system with
                  large holes to add more volume to their hair.
                </p>
              </div>
            </div>
            <div class="twoDeveloping">
              <img src="../../../src/assets/index/Developing.jpg" alt="" />
              <img src="../../../src/assets/index/Developing.jpg" alt="" />
            </div>
            <div class="disnav">
              <div
                class="navimgs"
                v-for="(item, index) in navimgs"
                :key="index"
              >
                <img :src="item.laceimg" alt="" class="laceimg" />
                <img :src="item.laceimg1" alt="" class="laceimg1" />
                <router-link to=""
                  ><p style="font-size: 15px">{{ item.fonts }}</p></router-link
                >
              </div>
            </div>
            <div class="Before">
              <h2>Before & After</h2>
              <div class="after">
                <img src="../../../src/assets/index/mones.jpg" alt="" />
                <img src="../../../src/assets/index/sdfbefore.jpg" alt="" />
              </div>
              <div class="after">
                <img
                  src="../../../src/assets/index/Getholesale.jpg"
                  alt=""
                  @click="img1"
                />
                <img
                  src="../../../src/assets/index/etwholesale.jpg"
                  alt=""
                  @click="img2"
                />
              </div>
            </div>
          </span>
          <span v-if="tabs == 3">内容三</span>
          <span v-if="tabs == 4">内容四</span>
          <span v-if="tabs == 5">内容五</span>
        </div>
      </div>
      <div class="videoss">
        <img src="../../../src/assets/index/bofang@2x.png" alt="" />
      </div>
      <!-- <div class="FAQ">
        <h2>FAQ</h2>
        <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are the differences between a toupee and a wig?
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                With more and more people choosing non-surgical hair loss
                solutions, the men’s hair piece wholesale market has seen a
                significant increase in the types of stock hairpieces that are
                designed to cater to people’s needs more precisely and
                specifically. Therefore, many terms in the male hairpiece world
                often get mistaken for one another. The most common pair of
                terms that people confuse with one another is “toupee” and
                “wig.”<br />

                Both toupee and wig products are supposed to be worn on people’s
                heads for the purpose of hiding hair loss, but there are quite a
                few ways in which they differ from one another. A toupee is
                usually a small hair piece made of real human hair or synthetic
                hair that is worn at the top of the wearer’s scalp to cover a
                bald area, while a wig is meant to offer complete coverage of
                the whole head. That is why toupees are typically a lot smaller
                than wigs. Due to the differences in hair loss patterns between
                men and women, toupees are more commonly used by men to cover
                the top area of the head, which is more prone to baldness, while
                wigs are more popular among women who experience hair thinning
                problems.<br />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What is the best men's stock hair systems wholesale?
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                Some placeholder content for the second accordion panel. This
                panel is hidden by default.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How canl get the right measurements for my clients?
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                And lastly, the placeholder content for the third and final
                accordion panel. This panel is hidden by default.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFOUR">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFOUR"
                  aria-expanded="false"
                  aria-controls="collapseFOUR"
                >
                  How canl get the right measurements for my clients?
                </button>
              </h2>
            </div>
            <div
              id="collapseFOUR"
              class="collapse"
              aria-labelledby="headingFOUR"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                How should l install a toupee for my clients?
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Why should l purchase men's stock hair systems wholesale from
                  New Times Hair?
                </button>
              </h2>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                And lastly, the placeholder content for the third and final
                accordion panel. This panel is hidden by default.
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="BECOMES">
        <div class="Become">
          <div class="Become_dis">
            <img
              src="../../../src/assets/index/Album3.jpg"
              style="height: 500px; width: 100%"
            />
            <div class="Become_bg">
              <h2>Become a Brand Ambassador</h2>
              <p
                style="
                  text-align: center;
                  font-weight: 600;
                  color: #f29d35;
                  font-size: 22px;
                "
              >
                Why join?
              </p>
              <ul>
                <li
                  style="font-weight: 600; opacity: 0.9; font-size: 20px"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  {{ item.li }}
                </li>
              </ul>
              <p style="opacity: 1">
                And most importantly – be a part of a supportive and uplifting
                community of hair replacement specialists worldwide!
              </p>

              <button @click="inquiryNow">Apply now</button>
            </div>
          </div>
        </div>
        <div class="Become">
          <div class="Become_dis">
            <img
              src="../../../src/assets/index/Company-Album41024x800.jpg"
              style="height: 500px; width: 100%"
            />
            <div class="Become_bg" style="background-color: #fff; opacity: 0.7">
              <h2>Get a Free Trial</h2>
              <ul>
                <li
                  v-for="(item, index) in listli"
                  :key="index"
                  style="opacity: 1"
                >
                  {{ item.li }}
                </li>
              </ul>
              <p style="opacity: 1">
                And most importantly – be a part of a supportive and uplifting
                community of hair replacement specialists worldwide!
              </p>
              <p style="text-align: center; font-weight: 600">
                It’s a rare opportunity, so don’t hesitate to get in before your
                competition!
              </p>
              <button @click="clickJoin">Apply now</button>
            </div>
          </div>
        </div>
      </div>
      <submit />
      <div class="top" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
      <div class="tops" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
import Submit from "../../components/submit.vue";
export default {
  components: {
    Footer,
    Nav,
    Submit,
  },
  data() {
    return {
      tabs: 1,
      one: "",
      Active: false,
      navimgs: [
        {
          laceimg: require("../../../src/assets/index/SPU.jpg"),
          laceimg1: require("../../../src/assets/index/Custom.jpg"),
          fonts:
            "ST5*3 Silk Hair Topper for Women With Medium-Light Remy Hair Wholesale",
        },
        {
          laceimg: require("../../../src/assets/index/SPU.jpg"),
          laceimg1: require("../../../src/assets/index/Custom.jpg"),
          fonts: "SPU4.75*2 Stock Clip-in Hair Topper With PU Base Wholesale",
        },
        {
          laceimg: require("../../../src/assets/index/SPU.jpg"),
          laceimg1: require("../../../src/assets/index/Custom.jpg"),
          fonts:
            "SP2.75*5 Silk Top Human Hair Wiglet for Thinning Hair Wholesale",
        },
        {
          laceimg: require("../../../src/assets/index/SPU.jpg"),
          laceimg1: require("../../../src/assets/index/Custom.jpg"),
          fonts:
            "NW5890 Custom Integration Hair System With Mono Top Wholesale",
        },
      ],
      list: [
        { li: "  FREE Hair Systems and Exciting Coupon Codes" },
        { li: " Exclusive WHOLESALE Discounts " },
        { li: "  Business Start-Up Guidance " },
        { li: "  Gain greater Social Media Exposure " },
      ],
      listli: [
        { li: "Get the best wholesale prices" },
        { li: "Special offer for sample orders" },
        { li: "Access to product experts" },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    clickJoin() {
      this.$router.push({
        path: "/Joinnow",
      });
    },
    //inquiryNow
    inquiryNow() {
      this.$router.push({ path: "/Inquirynow" });
    },
    but(index) {
      this.tabs = index;
      if (this.lay_type == 0) {
        this.lay_type = 1;
      } else {
        this.lay_type = 0;
      }
    },
    img1() {},
    img2() {},
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    submits() {},
    submit() {},
  },
};
</script>
  <style lang="less" scoped>
.hairless {
  .hairless_content {
    .custom_img {
      img {
        width: 100%;
      }
    }
    .content_fonts {
      width: 70%;
      margin: 40px auto;
    }
    .tabs {
      width: 70%;
      margin: 0 auto;
      text-align: center;
      .womensmenu {
        // background-color: #F2F2F2;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        p {
          // width:80%;
          margin-top: 10px;
          padding: 10px 3%;
          border: 1px solid #cfd5db;
          cursor: pointer;
          background-color: #fff;
          color: #000;
          border: 1px soild #fff;
          border-radius: 8px 8px;
        }
        p:hover {
          background-color: #f29d35;
          color: #fff;
          display: block;
        }
      }
    }
    .Beginning {
      display: flex;
      justify-content: space-around;
      img {
        width: 30%;
      }
      .begin {
        width: 44%;
        padding-top: 40px;
      }
    }
    .disnav {
      width: 100%;
      margin: 20px auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .navimgs {
        width: 22%;
        .laceimg1 {
          display: none;
          width: 100%;
        }
        .laceimg {
          width: 100%;
        }
        p {
          line-height: 25px;
        }
        button {
          color: #fff;
          border: 1px solid #012340;
          background-color: #012340;
          width: 100%;
          padding: 8px 0;
          margin: 5px 0;
        }
        button:hover {
          background-color: #f29d35;
          border: 1px solid #f29d35;
        }
        a {
          text-decoration: none;
          color: #012340;
        }
        a:hover {
          color: #f29d35;
        }
      }

      .navimgs:hover {
        .laceimg {
          display: none;
        }
        .laceimg1 {
          display: block;
        }
      }
    }
    .tabspan3 {
      width: 70%;
      margin: 0 auto;
    }
    .Before {
      width: 100%;

      .after {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        img {
          width: 49%;
        }
      }
    }
    .videoss {
      height: 600px;
      margin: 100px 0;
      background: url("../../../src/assets/index/banner_7@2x.png");
      width: 100%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      img {
        width: 3%;
        margin-left: 45%;
        margin-top: 220px;
      }
      img:hover {
        transform: scale(1.5); //放大图片
        cursor: pointer; //变小手
        transition: all 0.7s;
      }
    }
    .twoDeveloping {
      display: flex;
      justify-content: space-between;
      img {
        width: 48%;
      }
    }
    .FAQ {
      width: 70%;
      margin: 0 auto;
      h2 {
        padding: 20px 0;
        text-align: center;
      }
      button {
        color: #000;
        font-weight: 600;
      }
    }
    .BECOMES {
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .Become {
        // height: 500px;
        width: 49%;
        margin-top: 20px;
        // background: url();
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        // color: #012340;
        .Become_dis {
          position: relative;
        }
        .Become_bg {
          position: absolute;
          top: 25px;
          left: 5%;
          height: 450px;
          width: 90%;
          padding-top: 50px;
          button {
            border: none;
            border: 1px solid #0a2840;
            background-color: #0a2840;
            color: #fff;
            padding: 5px 3%;
            margin: 20px 0;
            margin-left: 40%;
            text-align: center;
            border-radius: 18px;
            opacity: 1;
          }
          button:hover {
            background-color: #f29d35;
            border: 1px solid #f29d35;
            border-radius: 18px;
          }
          h2 {
            text-align: center;
            padding: 20px 0;
            opacity: 1;
          }
        }
      }
    }
    .CONTACTs {
      display: none;
    }
    .CONTACT {
      margin: 20px 0;

      text-align: center;

      padding: 50px 0;
      width: 80%;
      margin: 0 auto;
      h2 {
        font-weight: 600;
      }
      .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        input {
          width: 45%;
          height: 50px;
          margin: 20px 0;
          border: none;
          border: 1px solid #cfd5db;
        }
      }
      textarea {
        border: none;
        border: 1px solid #cfd5db;
        width: 95%;
        height: 200px;
      }
      button {
        margin-top: 30px;
        background-color: #304e6a;
        border: 1px solid #304e6a;
        padding: 10px 1.5%;
        border-radius: 5px;
        color: #fff;
      }
      button:hover {
        background-color: #f9bd5a;
        border: 1px solid #f9bd5a;
      }
    }
    .top {
      img {
        width: 3%;
        height: 50px;
        position: fixed;
        z-index: 999;
        bottom: 50px;
        right: 50px;
      }
    }
    .tops {
      display: none;
    }
  }
}
</style>