<template>
  <div class="orderguide">
    <Nav />
    <div class="guidecontent">
      <!-- banner -->
      <div class="contentbanner">
        <img src="../../src/assets/index/0220622144038.png" alt="" />
      </div>
      <div style="width: 75%; margin: 0 auto">
        <div class="content_title">
          <div v-for="(item, index) in list" :key="index">
            <h2>{{ item.h2 }}</h2>
            <p>{{ item.p }}</p>
          </div>
        </div>
        <div class="steps" v-for="(item, index) in steps" :key="index">
          <button>{{ item.btn }}</button>
          <div class="bgc">
            {{ item.content }}
          </div>
        </div>
        <div class="threeimgs">
          <div class="threeimg" v-for="(item, index) in threeimg" :key="index">
            <img :src="item.img" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </div>
        <!-- 光头 -->
        <div class="fivesing">
          <p style="font-size: 20px">DIFFERENT HAIR LOSS AREAS</p>
          <div class="fives">
            <div class="fiveimg" v-for="(item, index) in fiveimg" :key="index">
              <img :src="item.img" alt="" />
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <!-- 假发 -->
        <div class="fivesing">
          <p style="font-size: 20px">BASE SIZE REFERENCE</p>
          <div class="fives">
            <div class="fiveimg" v-for="(item, index) in BASEs" :key="index">
              <img :src="item.img" alt="" />
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <!-- men -->
        <div class="fivesing">
          <p style="font-size: 20px">FRONT CONTOUR REFERENCE</p>
          <div class="fives">
            <div class="fiveimg" v-for="(item, index) in FRONTs" :key="index">
              <img :src="item.img" alt="" />
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <!-- 小山 -->
        <div class="moutening">
          <p style="font-size: 20px">SCALLOP SIZE CHART</p>
          <div class="fives">
            <div
              class="imgmouten"
              v-for="(item, index) in SCALLOPs"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="steps steps2" v-for="(item, index) in steps2" :key="index">
          <button>{{ item.btn }}</button>
          <div class="bgc">
            <h4>{{ item.h4 }}</h4>
            {{ item.content }}
          </div>
        </div>
        <!-- 表格 -->
        <div class="tables">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">Base Type</th>
                <th scope="col">Lace</th>
                <th scope="col">Skin</th>
                <th scope="col">Mono</th>
                <th scope="col">Silk Top</th>
                <th scope="col">Other</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Breathability</th>
                <td class="xing">
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                </td>
                <td class="xing">
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                </td>
                <td class="xing">
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                </td>
                <td class="xing">
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                </td>
                <td>Varies</td>
              </tr>
              <tr>
                <th scope="row">Lifespan</th>
                <td>About 3 months (Swiss lace 1 month)</td>
                <td>1-3 months; 3-6 months for knotted skin</td>
                <td>3-6 months</td>
                <td>10-12 months</td>
                <td>Varies</td>
              </tr>
              <tr>
                <th scope="row">Knots</th>
                <!-- <td colspan="2">Larry the Bird</td> -->
                <td>Yes</td>
                <td>No (if use V-looped hair or injected hair)</td>
                <td>Yes</td>
                <td>No</td>
                <td>Varies</td>
              </tr>
              <tr>
                <th scope="row">Price</th>
                <!-- <td colspan="2">Larry the Bird</td> -->
                <td class="xing">
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                </td>
                <td class="xing">
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                </td>
                <td class="xing">
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                </td>
                <td class="xing">
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                </td>
                <td class="xing">
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                  <img src="../assets/logoimgs/xing.png" alt="" />
                </td>
              </tr>
              <tr>
                <th scope="row">Stock or Custom</th>
                <!-- <td colspan="2">Larry the Bird</td> -->
                <td>Stock (ready for immediate shipment)</td>
                <td>Stock (ready for immediate shipment)</td>
                <td>Stock (ready for immediate shipment)</td>
                <td>Custom (much longer wait time)</td>
                <td>Custom (much longer wait time)</td>
              </tr>
              <tr>
                <th scope="row">Features</th>
                <!-- <td colspan="2">Larry the Bird</td> -->
                <td>
                  Breathable and natural. The best option for hot climates.
                </td>
                <td>
                  Realistic, knotless, easy to wear and take care of. Both skin
                  thickness and knotting method affect the lifespan - the
                  thinner the base, the shorter the lifespan, e.g., 0.03mm base
                  can last only a month.
                </td>
                <td>Strong, durable and cost-effective.</td>
                <td>Durable yet natural-looking. More costly.</td>
                <td>
                  A combination of 2 or more base materials. Can meet customers’
                  own needs. More costly.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fivesing">
          <p style="font-size: 20px">CHOOSE BY BASE TYPE</p>
          <div class="fives">
            <div
              class="CHOOSEs"
              v-for="(item, index) in CHOOSEs"
              :key="index"
              @click="CHOOSE(index)"
            >
              <img :src="item.img" alt="" />
              <button>{{ item.name }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg">
        <!--实现 tanchuang -->
        <button
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          Contact now
        </button>
      </div>
      <!-- Modal弹窗 -->
      <div
        class="modal fade"
        style="color: #000"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-scrollable"
          style="max-width: 800px"
        >
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
                <!-- 右上角关闭 -->
              </button>
            </div>
            <div class="modal-body" style="color: #000">
              <!-- input框 -->
              <div class="CONTACT">
                <h2>INQUIRIES & QUESTIONS</h2>
                <div class="inputs">
                  <!-- <input id="type" type="text" placeholder="请选择"> -->

                  <input type="text" v-model="one" id="" placeholder="Name*" />
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Email Address*"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Country*"
                    list="typelist"
                  />
                  <datalist id="typelist">
                    　　
                    <option v-for="(item, index) in country" :key="index">
                      {{ item.label }}
                    </option>
                  </datalist>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Phone/whatsapp*"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Business name"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    list="inputlist"
                    placeholder="Select your business role..*"
                  />
                  <datalist id="inputlist">
                    　　
                    <option v-for="(item, index) in inputlists" :key="index">
                      {{ item.name }}
                    </option>
                  </datalist>
                </div>
                <textarea
                  type=""
                  placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
                ></textarea>

                <button @click="submit">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 75%; margin: 10px auto">
        <div class="steps steps3" v-for="(item, index) in steps3" :key="index">
          <button>{{ item.btn }}</button>
          <div class="bgc">
            <h4>{{ item.h4 }}</h4>
            {{ item.content }}
          </div>
        </div>
        <p>
          1. Refer to the color ring. 2. Send us the hair sample (best option).
          3. Send us the old unit. (It is recommended to send us the hair sample
          in the case of a color change happening to the old unit.)
        </p>
        <div class="fourhairs">
          <div v-for="(item, index) in hairs" :key="index">
            <img :src="item.imgs" alt="" />
            <p>{{ item.p }}</p>
          </div>
        </div>
        <div class="steps steps4" v-for="(item, index) in steps4" :key="index">
          <button>{{ item.btn }}</button>
          <div class="bgc">
            <h4>{{ item.h4 }}</h4>
            <!-- {{ item.content }} -->
          </div>
        </div>
        <div v-for="(item, index) in table2" :key="index">
          <h2>{{ item.h2 }}</h2>
          <p>
            {{ item.tabletitle }}
          </p>
          <div class="tables2">
            <table class="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td scope="row" rowspan="4">{{ item.human }}</td>
                  <td>{{ item.Indian }}</td>
                  <td>
                    {{ item.Indiantd }}
                  </td>
                </tr>
                <tr>
                  <!-- <td scope="row">2</td> -->
                  <td>{{ item.remy }}</td>
                  <td>
                    {{ item.remytd }}
                  </td>
                </tr>
                <tr>
                  <!-- <td scope="row">3</td> -->
                  <td>
                    {{ item.virgin }}
                  </td>
                  <td>
                    {{ item.virgintd }}
                  </td>
                </tr>
                <tr>
                  <!-- <td scope="row" colspan="2">4</td> -->
                  <td>
                    {{ item.European }}
                  </td>
                  <td>
                    {{ item.Europeantd }}
                  </td>
                </tr>
                <tr>
                  <td scope="row" colspan="2">
                    {{ item.Synthetic }}
                  </td>
                  <!-- <td>Larry</td> -->
                  <td>
                    {{ item.Synthetictd }}
                  </td>
                </tr>
                <tr>
                  <td scope="row" colspan="2">
                    {{ item.Yak }}
                  </td>
                  <!-- <td>Larry</td> -->
                  <td>
                    {{ item.Yaktd }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="Watch">
          <div v-for="(item, index) in Watchlist" :key="index">
            <h4>{{ item.h4 }}</h4>
          </div>
        </div>
      </div>
    </div>
    <Footer />

    <div class="top" @click="toTop">
      <img
        style="width: 3%; height: 50px"
        src="../assets/index/xiala_un@2x.png"
        alt=""
      />
    </div>
    <div class="tops" @click="toTop">
      <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      Watchlist: [{ h4: "Watch the video below for a more detailed summary." }],
      list: [
        {
          h2: "5 steps you need to follow before ordering",
          p: "It is very important to find the right fit for your customers to keep them happy and satisfied, especially in the case of new customers placing an order from you for the first time! Below you can find 5 steps to stick to when trying to find your customer the best match:",
        },
      ],
      steps: [
        {
          btn: "STEP1",
          content:
            " Get an accurate measurement of the hair loss area or make a head template for your customer to calculate the size and front contour of the hair system.An accurate measurement helps find the ideal size for your customer’s hair system. Check out the videos below to learn more:",
        },
      ],
      threeimg: [
        {
          img: require("../assets/index/Custom.jpg"),
          name: "Scalp measurement for regular size",
        },
        {
          img: require("../assets/index/Custom.jpg"),
          name: "Scalp measurement for a full cap",
        },
        {
          img: require("../assets/index/Custom.jpg"),
          name: "Making a hair system template with the template replicator HSR-2",
        },
      ],
      fiveimg: [
        {
          img: require("../assets/logoimgs/Different-Hair-Loss-Areas1@2x.jpg"),
          name: "Scalp measurement for regular size",
        },
        {
          img: require("../assets/logoimgs/Different-Hair-Loss-Areas2@2x.jpg"),
          name: "Crown & swirl",
        },
        {
          img: require("../assets/logoimgs/Different-Hair-Loss-Areas3@2x.jpg"),
          name: "Crown & swirl",
        },
        {
          img: require("../assets/logoimgs/Different-Hair-Loss-Areas4@2x.jpg"),
          name: "Full head",
        },
        {
          img: require("../assets/logoimgs/Different-Hair-Loss-Areas1@2x.jpg"),
          name: "Alopecia areata",
        },
      ],
      BASEs: [
        {
          img: require("../assets/logoimgs/Base-Size-Reference1@2x.jpg"),
          name: 'Partial size < 4"X4" ',
        },
        {
          img: require("../assets/logoimgs/Base-Size-Reference2@2x.jpg"),
          name: 'Partial size < 4"X4" ',
        },
        {
          img: require("../assets/logoimgs/Base-Size-Reference3@2x.jpg"),
          name: 'Partial size < 4"X4" ',
        },
        {
          img: require("../assets/logoimgs/Base-Size-Reference1@2x.jpg"),
          name: 'Partial size < 4"X4" ',
        },
        {
          img: require("../assets/logoimgs/Base-Size-Reference3@2x.jpg"),
          name: 'Partial size < 4"X4" ',
        },
      ],
      FRONTs: [
        {
          img: require("../assets/logoimgs/Front-Contour-Reference1@2x.jpg"),
          name: "C",
        },
        {
          img: require("../assets/logoimgs/Front-Contour-Reference2@2x.jpg"),
          name: "CC",
        },
        {
          img: require("../assets/logoimgs/Front-Contour-Reference3@2x.jpg"),
          name: "A",
        },
        {
          img: require("../assets/logoimgs/s-Front-Contour-Reference4.png"),
          name: "AA",
        },
        {
          img: require("../assets/logoimgs/Front-Contour-Reference5@2x.jpg"),
          name: "B",
        },
        {
          img: require("../assets/logoimgs/Front-Contour-Reference6@2x.jpg"),
          name: "BB",
        },
      ],
      SCALLOPs: [
        {
          img: require("../assets/logoimgs/scallop-size-chart@2x-scaled.jpg"),
        },
      ],
      steps2: [
        {
          btn: "STEP2",
          h4: "Choose the right base type according to your customers’ needs.",
          content:
            "Different types of base designs come with their own characteristics. When recommending a base design to your customers, it is very important to be aware of what their needs are.Some customers really care about how natural their hair systems appear while some place a great deal of emphasis on the lifespan and affordability of their hair systems. Things like comfort level and ease of use can be important to many users as well.After learning more about your customer’s priorities, it will be very easy for you to figure out the right fit for them.",
        },
      ],
      CHOOSEs: [
        {
          img: require("../assets/logoimgs/CHOOSE-BY-BASE-TYPE1@2x.png"),
          name: "Lace Base",
        },
        {
          img: require("../assets/logoimgs/CHOOSE-BY-BASE-TYPE1@2x.png"),
          name: "Skin Base",
        },
        {
          img: require("../assets/logoimgs/CHOOSE-BY-BASE-TYPE1@2x.png"),
          name: "Mono Base",
        },
        {
          img: require("../assets/logoimgs/CHOOSE-BY-BASE-TYPE1@2x.png"),
          name: "Silk Top Base",
        },
        {
          img: require("../assets/logoimgs/CHOOSE-BY-BASE-TYPE1@2x.png"),
          name: "Other",
        },
        {
          img: require("../assets/logoimgs/CHOOSE-BY-BASE-TYPE1@2x.png"),
          name: "Womens Wig",
        },
      ],
      steps3: [
        {
          btn: "STEP3",
          h4: "Select the right hair color for your customers.",
          content:
            "Hair color is another important thing that affects the naturalness of your customers’ hair systems. Choosing the right color guarantees a realistic blending of their own hair and the hairpiece. There are typically 3 ways to select the hair color of a hair system.",
        },
      ],
      hairs: [
        {
          imgs: require("../assets/logoimgs/hair-color-@2x.jpg"),
          p: "Men's hair color ring",
        },
        {
          imgs: require("../assets/logoimgs/hair-color-2@2x.jpg"),
        },
        {
          imgs: require("../assets/logoimgs/hair-color-3@2x.jpg"),
          p: "Women's hair color ring",
        },
        {
          imgs: require("../assets/logoimgs/hair-color-4@2x.jpg"),
        },
      ],
      steps4: [
        {
          btn: "STEP4",
          h4: "Select the right hair type, length, density, wave, and direction for your customers.",
        },
      ],
      table2: [
        {
          h2: "Hair Type",
          tabletitle:
            "We have a few options of hair type to choose from. Each comes with its own characteristics so the ideal choice will depend on the customer’s needs and preferences. We can also use hair provided by you or your customers.",
          human: "Human hair",
          Indian: "Indian hair",
          Indiantd:
            "  The most common and popular choice we have as it’s very soft and smooth, highly adaptable to color and texture processing. Excellent value for money.",
          remy: "Indian remy hair",
          remytd:
            "Top-class human hair, super soft and silky. All the hair runs in the same direction, usually used in producinghigh-end hair systems.",
          virgin: " Chinese virgin hair",
          virgintd:
            "  Super strong and straight, can withstand heavy processing   without degrading the hair quality. Very sleek with low luster.",
          European: "European hair",
          Europeantd:
            "The finest and softest hair material available. Non-processed virgin hair with all cuticles intact and hair heading the same direction. Vulnerable to breakage though.",
          Synthetic: "Synthetic hair",
          Synthetictd:
            "The most common type of artificial hair as it is extremely resistant to oxidation and color change. The best option for  gray colors. Very sensitive to friction though.",
          Yak: "Yak",
          Yaktd:
            " Stronger and harder than human hair with shinier color tone.",
        },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      one: "",
      country: [],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    CHOOSE(index) {
      if (index == 0) {
        this.$router.push({
          path: "/Lace",
        });
      }
      if (index == 1) {
        this.$router.push({
          path: "/Skin",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/Mono",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/SilkTop",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/Custom",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/Women",
        });
      }
    },
    submit() {},
  },
};
</script>
<style lang="less" scoped>
.top {
  img {
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
  }
}
.tops {
  display: none;
}
.orderguide {
  .CONTACT {
    text-align: center;

    padding: 50px 0;
    width: 80%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      input {
        width: 45%;
        height: 50px;
        margin: 10px 0;
        border: none;
        border: 1px solid #cfd5db;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }
  .guidecontent {
    width: 100%;
    .contentbanner {
      img {
        width: 100%;
      }
    } // banner
    .content_title {
      margin: 20px auto;
      p {
        font-size: 15px;
        padding: 20px 0;
      }
    }
    .steps {
      display: flex;
      justify-content: space-around;
      margin: 20px 0;
      line-height: 30px;

      button {
        background-color: #f0ad4e;
        border: 1px solid #f0ad4e;
        border-radius: 5px;
        padding: 0 3%;
        height: 45px;
        color: #fff;
      }
      .bgc {
        width: 80%;
        background-color: #f2f2f2;
        padding: 10px 2%;
        font-size: 18px;
      }
    }
    .threeimgs {
      margin: 30px 0;
      display: flex;
      justify-content: space-around;
      .threeimg {
        width: 30%;
        text-align: center;
        img {
          width: 70%;
        }
        p {
          padding: 10px 0;
          color: #3e3e3e;
        }
      }
    }
    .fivesing {
      text-align: center;

      .fives {
        margin: 30px 0;
        display: flex;
        justify-content: space-around;
        .fiveimg {
          width: 20%;
          text-align: center;
          img {
            width: 95%;
          }
          p {
            padding: 10px 0;
            color: #3e3e3e;
          }
        }
      }
    }
    .moutening {
      text-align: center;

      .fives {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
    .steps2 {
      margin-top: 80px;
      line-height: 30px;
    }
    .tables {
      margin: 50px 0;
    }
    .xing {
      width: 15%;

      img {
        width: 10%;
      }
    }
    .CHOOSEs {
      img {
        width: 70%;
      }
      button {
        background-color: #012340;
        border: 1px solid #012340;
        padding: 5px 5%;
        margin-top: 10px;
        color: #fff;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
      }
    }
    .bg {
      height: 150px;
      width: 100%;
      background: url("../../src/assets/index/20220623094610.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      button {
        background-color: #012340;
        border: 1px solid #012340;
        color: #fff;
        border-radius: 25px;
        padding: 10px 3%;
        text-align: center;
        margin-top: 70px;
        margin-left: 70%;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
      }
    }
    .fourhairs {
      margin: 20px 0;
      display: flex;
      justify-content: space-around;
      div {
        text-align: center;
        width: 50%;
        img {
          width: 90%;
        }
      }
    }
    .tables2 {
      width: 80%;
      margin: 0 auto;
    }
  }
  .modal-header {
    color: #fff;
    height: 270px;
    width: 100%;
    background: url("../../src/assets/index/20220624115343.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 390px) {
  .top {
    display: none;
  }

  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
}
</style>