<template>
  <div class="New">
    <Nav />
    <div class="New_content">
      <div class="custom_img">
        <img src="../../../src/assets/index/asssaasaas.png" alt="" />
      </div>
      <div class="Best">
        <div class="Best_left">
          <div>
            <input type="text" name="" id="" placeholder="Search.." />
            <button>搜索</button>
            <div class="BLOG">
              <h3>BLOG</h3>
              <div v-for="(item, index) in list" :key="index" class="BLOG_div">
                <img :src="item.imgs" />
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
          <div class="POPULAR">
            <h3>POPULAR POSTS</h3>
            <div
              v-for="(item, index) in POPULARlist"
              :key="index"
              class="POPULAR_div"
            >
              <img :src="item.imgs" />
              <p>{{ item.title }}</p>
            </div>
          </div>
          <div class="INQUIRIES">
            <h3>INQUIRIES & QUESTIONS</h3>
            <div class="inputs">
              <input type="text" v-model="one" id="" placeholder="Name*" />
              <input type="text" name="" id="" placeholder="Email Address*" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Country*"
                list="typelist"
              />
              <datalist id="typelist">
                　　
                <option v-for="(item, index) in country" :key="index">
                  {{ item.label }}
                </option>
              </datalist>
              <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
              <input type="text" name="" id="" placeholder="Business name" />
              <input
                type="text"
                name=""
                id=""
                list="inputlist"
                placeholder="Select your business role..*"
              />
              <datalist id="inputlist">
                　　
                <option v-for="(item, index) in inputlists" :key="index">
                  {{ item.name }}
                </option>
              </datalist>
            </div>
            <textarea
              class="textarea"
              placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
            ></textarea>

            <button @click="submits">Submit</button>
          </div>
        </div>
        <div class="Best_right">
          <div v-for="(item, index) in rightlist" :key="index">
            <img :src="item.imgs" alt="" />
            <router-link to="/">
              <p style="font-size: 18px">{{ item.title }}</p></router-link
            >
            <p style="color: #bfadb5">{{ item.small }}</p>
            <p style="font-szie: 14px">{{ item.content }}</p>
            <router-link to="/"><p>Read More »</p></router-link>
          </div>
        </div>
      </div>
      <div class="top" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
      <div class="tops" @click="toTop">
        <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      one: "",
      list: [
        {
          imgs: require("../../../src/assets/index/hairpiece.jpg"),
          title: "Did Howie Mandel Ever Have Hair? Why is He Bald?",
        },
        {
          imgs: require("../../../src/assets/index/Bestextensions.jpg"),
          title:
            "Best Hair Extensions for Fine Thin Hair—Hairstylists Must Know",
        },
        {
          imgs: require("../../../src/assets/index/DoesToupee.jpg"),
          title: "Is Tim Mcgraw Bald? Does He Wear A Toupee?",
        },
        {
          imgs: require("../../../src/assets/index/causehairloss.jpg"),
          title:
            "Does Wearing a Wig Cause Hair Loss? Here is What We Should be Aware of",
        },
        {
          imgs: require("../../../src/assets/index/mcgraw.jpg"),
          title: "Did Howie Mandel Ever Have Hair? Why is He Bald?",
        },
        {
          imgs: require("../../../src/assets/index/mcgraw.jpg"),
          title: "Did Howie Mandel Ever Have Hair? Why is He Bald?",
        },
      ],
      rightlist: [
        {
          imgs: require("../../../src/assets/index/is-tim-mcgraw-bald.jpg"),
          title:
            "Best Hair Extensions for Fine Thin Hair—Hairstylists Must Know",
          small: "New Times Hair ////  05/20/2022",
          content:
            "As a very trendy and modern hair transformation solution, hair extensions are popular among female clients who desire a long, fluffy, beautiful full head of hair. They can help increase existing hair length, add volume, cover up a bad haircut, and incorporate new hair colors into your client’s hair. Choosing between different types of hair",
        },
        {
          imgs: require("../../../src/assets/index/Best-Wigs-for-Cancer-Patients.jpg"),
          title:
            "What is a Hair Topper and the Best Hair Toppers at New Times Hair",
          small: "New Times Hair //// 05/09/2022",
          content:
            "The word hair topper does not seem to be very familiar to many hair wig wearers. Despite that, it is a very favorable hair loss solution, just like hair systems since they have a lot in common. So what is a hair topper exactly? Unlike hair systems that are worn chiefly by men to hide",
        },
        {
          imgs: require("../../../src/assets/index/Hair-system-lifestyle.png"),
          title: "Hair System Tape vs. Glue",
          small: "New Times Hair ////  05/20/2022",
          content:
            "Once you ordered a glue-on hair system or wig from an online supplier, you will need to consider what kind of adhesive you should choose for the hair system installation on your client’s head. In the men’s hair system world, tape and glue adhesive are the most common tools for hair system and wig attachment.",
        },
        {
          imgs: require("../../../src/assets/index/GLUE.jpg"),
          title: "Hair System Tape vs. Glue",
          small: "New Times Hair ////  05/20/2022",
          content:
            "Once you ordered a glue-on hair system or wig from an online supplier, you will need to consider what kind of adhesive you should choose for the hair system installation on your client’s head. In the men’s hair system world, tape and glue adhesive are the most common tools for hair system and wig attachment.",
        },
        {
          imgs: require("../../../src/assets/index/asddsystem.jpg"),
          title: "Hair System Tape vs. Glue",
          small: "New Times Hair ////  05/20/2022",
          content:
            "Once you ordered a glue-on hair system or wig from an online supplier, you will need to consider what kind of adhesive you should choose for the hair system installation on your client’s head. In the men’s hair system world, tape and glue adhesive are the most common tools for hair system and wig attachment.",
        },
        {
          imgs: require("../../../src/assets/index/humanwig.png"),
          title: "Hair System Tape vs. Glue",
          small: "New Times Hair ////  05/20/2022",
          content:
            "Once you ordered a glue-on hair system or wig from an online supplier, you will need to consider what kind of adhesive you should choose for the hair system installation on your client’s head. In the men’s hair system world, tape and glue adhesive are the most common tools for hair system and wig attachment.",
        },
        {
          imgs: require("../../../src/assets/index/WigExtensions.png"),
          title: "Hair System Tape vs. Glue",
          small: "New Times Hair ////  05/20/2022",
          content:
            "Once you ordered a glue-on hair system or wig from an online supplier, you will need to consider what kind of adhesive you should choose for the hair system installation on your client’s head. In the men’s hair system world, tape and glue adhesive are the most common tools for hair system and wig attachment.",
        },
        {
          imgs: require("../../../src/assets/index/different-types-of-wigs.png"),
          title: "Hair System Tape vs. Glue",
          small: "New Times Hair ////  05/20/2022",
          content:
            "Once you ordered a glue-on hair system or wig from an online supplier, you will need to consider what kind of adhesive you should choose for the hair system installation on your client’s head. In the men’s hair system world, tape and glue adhesive are the most common tools for hair system and wig attachment.",
        },
      ],
      POPULARlist: [
        {
          imgs: require("../../../src/assets/index/Bestextensions.jpg"),
          title:
            "Best Hair Extensions for Fine Thin Hair—Hairstylists Must Know",
        },
        {
          imgs: require("../../../src/assets/index/introduction.jpg"),
          title:
            "Best Hair Extensions for Fine Thin Hair—Hairstylists Must Know",
        },
        {
          imgs: require("../../../src/assets/index/newrestoratio.png"),
          title:
            "Best Hair Extensions for Fine Thin Hair—Hairstylists Must Know",
        },
        {
          imgs: require("../../../src/assets/index/Bestextensions.jpg"),
          title:
            "Best Hair Extensions for Fine Thin Hair—Hairstylists Must Know",
        },
        {
          imgs: require("../../../src/assets/index/newrestoratio.png"),
          title:
            "Best Hair Extensions for Fine Thin Hair—Hairstylists Must Know",
        },
        {
          imgs: require("../../../src/assets/index/Bestextensions.jpg"),
          title:
            "Best Hair Extensions for Fine Thin Hair—Hairstylists Must Know",
        },
      ],
      country: [],
      listli: [
        { li: " FREE Hair Systems and Exciting Coupon Codes " },
        { li: " Exclusive WHOLESALE Discounts " },
        { li: "  Business Start-Up Guidance " },
        { li: "  Gain greater Social Media Exposure " },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    submits() {},
  },
};
</script>
<style lang="less" scoped>
.New {
  .New_content {
    .custom_img {
      img {
        width: 100%;
      }
    }
    .Best {
      width: 70%;
      margin: 40px auto;
      display: flex;
      justify-content: space-between;
      .Best_left {
        width: 30%;
        input {
          margin: 20px 0;
          padding: 5px 0;
          width: 80%;
          border: 1px solid #e1e5eb;
        }
        button {
          border: 1px solid #4268c1;
          background-color: #4268c1;
          color: #fff;
          padding: 6px 5%;
          margin-left: 2%;
          font-size: 13px;
        }
        button:hover {
          border: 1px solid #f29d35;
          background-color: #f29d35;
        }
        .BLOG {
          padding: 20px 0;
          background-color: #f2f2f2;
          h3 {
            padding-left: 5%;
          }
          .BLOG_div {
            width: 90%;
            margin: 20px auto;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dashed #cccccc;
            img {
              width: 36%;
              height: 88px;
              vertical-align: middle;
            }
            p {
              font-size: 16px;
              width: 55%;
              line-height: 28px;
            }
          }
        }
        .POPULAR {
          h3 {
            padding-left: 6%;
          }
          padding: 20px 0;
          width: 100%;
          margin: 40px 0;
          background-color: #f2f2f2;
          .POPULAR_div {
            display: flex;
            width: 90%;
            margin: 0 auto;
            justify-content: space-around;
            border-bottom: 1px dashed #cccccc;
            img {
              width: 36%;
              height: 88px;
              vertical-align: middle;
            }
            p {
              font-size: 16px;
              width: 55%;
              line-height: 28px;
            }
          }
        }
        .INQUIRIES {
          padding: 20px 0;
          width: 100%;
          background-color: #f2f2f2;
          h3 {
            padding-left: 5%;
          }
          input {
            margin: 10px 0;
            width: 85%;
            margin-left: 8%;
          }
          .textarea {
            width: 85%;
            margin-left: 8%;
          }
          button {
            margin: 20px 40%;
            font-size: 16px;
          }
        }
      }
      .Best_right {
        margin: 20px auto;
        a {
          text-decoration: none;
          color: #5075bf;
          font-size: 13px;
        }
        a:hover {
          color: #f39d35;
        }
        width: 60%;
        img {
          width: 100%;
          padding-bottom: 10px;
        }
      }
    }
    .top {
      img {
        width: 3%;
        height: 50px;
        position: fixed;
        z-index: 999;
        bottom: 50px;
        right: 50px;
      }
    }
    .tops {
      display: none;
    }
  }
}

@media screen and (max-width: 390px) {
}
</style>