<template>
  <div class="viewdetail">
    <Nav />
    <div class="dis">
      <!-- 左侧swiper -->
      <div class="all left">
        <!-- Swiper 上-->
        <div class="swiper-container gallery-top">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide spec-preview"
              v-for="(item, index) in list"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <!-- 放大 -->
              <!-- <div class="event" @mousemove="handler">  </div>
              <div class="big">
                 <img :src="item.img" alt="" ref="big" />
              </div>
             <div class="mask" ref="mask"></div> -->
            </div>
          </div>
          <div class="swiper-button-next swiper-button-white"></div>
          <div class="swiper-button-prev swiper-button-white"></div>
        </div>
        <!-- Swiper点击固定到某一图片 下-->
        <div class="swiper-container gallery-thumbs">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              style="border: 1px solid #000"
              v-for="(item, index) in list"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧 style -->
      <div class="right">
        <div
          v-for="(item, index) in listing"
          :key="index"
          class="right_listing"
        >
          <h3>{{ item.h3 }}</h3>
          <div>
            <img :src="item.img" alt="" />
            <img :src="item.img" alt="" />
            <img :src="item.img" alt="" />
            <img :src="item.img" alt="" />
            <img :src="item.img" alt="" />
            <a href="">{{ item.a }}</a>
          </div>
          <h5>{{ item.h5 }}</h5>
          <p>{{ item.content }}</p>
        </div>
        <div v-for="(item, index) in uls" :key="index">
          <ul>
            <li>{{ item.lis }}</li>
          </ul>
        </div>
        <button class="btn">Login to view prices</button>
        <p style="color: #f49d35; padding: 5px 5%">
          Get a sample: we have special preferential prices for the first test
          sheet of wholesale customers
          <span class="apply"><a href="">APPLY NOW</a></span>
        </p>
        <div v-for="(item, index) in size" :key="index">
          <h4 style="padding: 5px 5%">{{ item.h4 }}</h4>
          <!-- Hair Length: 16 button按钮 -->
          <div class="butdiv">
            <button
              @click="choice(index)"
              v-for="(item, index) in sizebtn"
              :key="index"
            >
              {{ item.name }}
            </button>
          </div>
        </div>
        <el-input-number
          size="mini"
          v-model="num"
          @change="handleChange"
          :min="1"
          :max="20"
          label="描述文字"
          style="margin: 10px 3%; width: 23%"
        >
        </el-input-number>
        <button class="elbtn">Add to Quote</button>
        <div style="margin-left: 3%">
          <h6>Payment method:</h6>
        </div>
        <div style="margin-left: 3%">
          <h6>Service:</h6>
        </div>
      </div>
    </div>
    <div class="downtab">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a
            class="nav-link active"
            id="nav-home-tab"
            data-toggle="tab"
            href="#nav-home"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
            >Description</a
          >
          <a
            class="nav-link"
            id="nav-profile-tab"
            data-toggle="tab"
            href="#nav-profile"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
            >Reviews (1)</a
          >
          <a
            class="nav-link"
            id="nav-contact-tab"
            data-toggle="tab"
            href="#nav-contact"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
            >Policy</a
          >
        </div>
      </nav>
      <!-- 选项卡切换 -->
      <div class="tab-content" id="nav-tabContent">
        <!-- 选项卡切换1 -->
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <!-- 表格 -->
          <table class="table table-bordered table-striped">
            <tbody>
              <tr v-for="(item, index) in tables" :key="index">
                <th>{{ item.th }}</th>
                <td>{{ item.td }}</td>
              </tr>
            </tbody>
          </table>
          <!-- <div>
                      <h4>{{item.h4}}</h4>
                      <p>{{item.p}}</p></div> -->
          <!-- 图片加内容 -->
          <div class="imgcont">
            <div v-for="(item, index) in ingcont" :key="index">
              <img :src="item.img" alt="" />
              <p>{{ item.h4 }}</p>
              <p style="text-align: left">{{ item.p }}</p>
            </div>
          </div>
        </div>
        <!-- 选项卡切换 2-->
        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div class="rewiew">
            <div class="rewiewleft">
              <div
                class="rewiewleft_div1"
                v-for="(item, index) in rewiewleft_div1"
                :key="index"
              >
                <h3>{{ item.title }}</h3>
                <div class="disdiv">
                  <img :src="item.img" alt="" />
                  <div
                    class="fontimg"
                    v-for="(item, index) in fontimg"
                    :key="index"
                  >
                    <p>{{ item.p1 }}</p>
                    <div>
                      <img :src="item.imgs" alt="" />
                      <img :src="item.imgs" alt="" />
                      <img :src="item.imgs" alt="" />
                      <img :src="item.imgs" alt="" />
                      <img :src="item.imgs" alt="" />
                    </div>
                    <p>{{ item.p2 }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="rewiewright">
              <div v-for="(item, index) in rights" :key="index">
                <h3>{{ item.h3 }}</h3>
                <p>{{ item.rightp1 }}</p>
                <p>{{ item.rightp2 }}</p>
                <div class="block">
                  <el-rate v-model="value1"></el-rate>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Your review *</label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-around;
                  margin: 20px 0;
                "
              >
                <label for="exampleFormControlTextarea1" style="width: 10%"
                  >Name*</label
                >
                <input
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  style="width: 60%"
                />
              </div>
              <div style="display: flex; justify-content: space-around">
                <label for="exampleFormControlTextarea1" style="width: 10%"
                  >Email *</label
                >
                <input
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  style="width: 60%"
                />
              </div>
              <div class="form-group form-check" style="padding-top: 30px">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1">
                  Save my name, email, and website in this browser for the next
                  time I comment.
                </label>
              </div>
              <button @click="submits">submit</button>
            </div>
          </div>
        </div>
        <!-- 选项卡切换 3-->
        <div
          class="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <div v-for="(item, index) in font" :key="index" class="fontss">
            <h4>{{ item.h4 }}</h4>
            <p>{{ item.p }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="box">
      放大镜功能
        <img src="../assets/index/Free-Hair-Curls-Long-Curly-Hair.jpg" alt="" class="small">
        <div class="yy"></div>
        <div class="big">
            <img src="../assets/index/Free-Hair-Curls-Long-Curly-Hair.jpg" alt="">
        </div>
    </div>  -->
    <div class="bgbg">
      <div v-for="(item, index) in bgbg" :key="index">
        <h4>{{ item.h4 }}</h4>
        <h4 style="color: #f29d35">{{ item.why }}</h4>
        <p>{{ item.p }}</p>
        <p>{{ item.p1 }}</p>
        <p>{{ item.p2 }}</p>
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          {{ item.but }}
        </button>
      </div>
    </div>
    <!-- Modal弹窗 -->
    <div
      class="modal fade"
      style="color: #000"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-scrollable"
        style="max-width: 800px"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <!-- 右上角关闭 -->
            </button>
          </div>
          <div class="modal-body" style="color: #000">
            <!-- input框 -->
            <div class="CONTACT">
              <h2>INQUIRIES & QUESTIONS</h2>
              <div class="inputs">
                <!-- <input id="type" type="text" placeholder="请选择"> -->

                <input type="text" v-model="one" id="" placeholder="Name*" />
                <input type="text" name="" id="" placeholder="Email Address*" />
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Country*"
                  list="typelist"
                />
                <datalist id="typelist">
                  　　
                  <option v-for="(item, index) in country" :key="index">
                    {{ item.label }}
                  </option>
                </datalist>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Phone/whatsapp*"
                />
                <input type="text" name="" id="" placeholder="Business name" />
                <input
                  type="text"
                  name=""
                  id=""
                  list="inputlist"
                  placeholder="Select your business role..*"
                />
                <datalist id="inputlist">
                  　　
                  <option v-for="(item, index) in inputlists" :key="index">
                    {{ item.name }}
                  </option>
                </datalist>
              </div>
              <textarea
                type=""
                placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
              ></textarea>

              <button @click="submit">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 style="text-align: center; padding: 20px 0">Related products</h3>
    <div class="Related">
      <div
        v-for="(item, index) in Related"
        :key="index"
        class="Relateds"
        @click="ging(index)"
      >
        <img :src="item.img" alt="" class="img" />
        <img :src="item.img1" alt="" class="img1" />
        <h6 style="padding: 20px 0">{{ item.h6 }}</h6>
      </div>
    </div>
    <div class="top" @click="toTop">
      <img
        style="width: 3%; height: 50px"
        src="../assets/index/xiala_un@2x.png"
        alt=""
      />
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      one: "",
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
      value1: null,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"], // 等同于 { 2: '#99A9BF', 4: { value: '#F7BA2A', excluded: true }, 5: '#FF9900' }
      num: 1,
      // swiper实例  实例上有各种方法
      swiperInstance: null,
      currentIndex: 0,
      // 上swiper
      list: [
        {
          img: require("../assets/index/Free-Hair-Curl.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curls-Long-Curly-Hair.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curls-Long-Curly-Hair-Topper-I.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curls-Long-Curly-Hair-Topper-I.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curls-Long-Curly-Hair-Topper-I.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curl.jpg"),
        },
      ],
      // swiper
      list2: [
        {
          img: require("../assets/index/Free-Hair-Curl.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curls-Long-Curly-Hair.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curls-Long-Curly-Hair-Topper-I.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curls-Long-Curly-Hair-Topper-I.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curls-Long-Curly-Hair-Topper-I.jpg"),
        },
        {
          img: require("../assets/index/Free-Hair-Curl.jpg"),
        },
      ],
      listing: [
        {
          h3: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          img: require("../assets/logoimgs/xing.png"),
          a: "(1 customer review)",
          h5: "Get Free Perming and Styling Services along with Your Purchase!",
          content:
            "Don’t let your clients compromise on quality. The IN6*6 Stock Silk Mono Hair Topper is designed with the perfect curls for turning heads. Crafted from the highest quality of human virgin hair, this stunning piece will instill confidence in any woman.",
        },
      ],
      uls: [
        {
          lis: "The silk top and the first two rows of hair stitched to the underside create an ultra-natural hairline.",
        },
        {
          lis: "A thin skin base coupled with a diamond lace patch provides an outstanding level of comfort. This also increases durability.",
        },
        {
          lis: "100% Chinese Remy hair, as soft and natural as your client’s own hair.",
        },
        {
          lis: "Multiple color options and available in a variety of hair lengths.",
        },
      ],
      size: [
        {
          h4: "Hair Length: 16",
          name: '16 "',
          name1: '18 "',
          name2: '20 "',
        },
      ],
      sizebtn: [
        {
          name: "16",
        },
        {
          name: "18",
        },
        {
          name: "20",
        },
      ],
      tables: [
        {
          th: "Weight",
          td: "0.115 kg",
        },
        {
          th: "Base Type",
          td: "Skin, Mono",
        },
        {
          th: "Base Size",
          td: '6"x6"',
        },
        {
          th: "Hair Type",
          td: "Chinese Remy hair",
        },
        {
          th: "Hair Length	",
          td: '16", 18", 20"',
        },
        {
          th: "Curl & Wave",
          td: "Natural Straight",
        },
        {
          th: "Density",
          td: "Medium",
        },
        {
          th: "Hair Color (NT COLOR RING)	",
          td: "NATURAL BLACK, #4, #22, #613, #1740T, #2020T",
        },
      ],
      ingcont: [
        {
          h4: "Blonde Hair Topper Wholesale",
          img: require("../assets/index/IN6×6-Hair-Topper-with-Ombre-Colors-6.jpg"),
          p: "We do inject ventilation on the top skin, creating a very realistic appearance, just like silk base toppers do. We also add under hair along the front edge to mask the appearance of the base.",
        },
        {
          h4: "Blonde Hair Topper Wholesale",
          img: require("../assets/index/IN6×6-Hair-Topper-with-Ombre-Colors-3.jpg"),
          p: "We do inject ventilation on the top skin, creating a very realistic appearance, just like silk base toppers do. We also add under hair along the front edge to mask the appearance of the base.",
        },
        {
          h4: "Blonde Hair Topper Wholesale",
          img: require("../assets/index/IN6×6-Silk-Mono-Wefted-Back-Clip-on-Hair-Topper-3.jpg"),
          p: "We do inject ventilation on the top skin, creating a very realistic appearance, just like silk base toppers do. We also add under hair along the front edge to mask the appearance of the base.",
        },
        {
          h4: "Blonde Hair Topper Wholesale",
          img: require("../assets/index/Free-Hair-Curls-Long-Curly-Hair-Topper-IN6×6-2.jpg"),
        },
      ],
      bgbg: [
        {
          h4: "Join us as an exclusive wholesaler",
          why: "Why join?",
          p: "Get the best wholesale prices",
          p1: "Special offer for sample orders",
          p2: "Access to product experts",
          but: "Apply now",
        },
      ],
      Related: [
        {
          img: require("../assets/index/ntw3-womens-mono.jpg"),
          img1: require("../assets/index/njc610-mono-with-pu-womens.jpg"),
          h6: "NTW3 Women’s Fine Welded Monofilament Wig With Lace Front Wholesale",
        },
        {
          img: require("../assets/index/ntw3-womens-mono.jpg"),
          img1: require("../assets/index/njc610-mono-with-pu-womens.jpg"),
          h6: "NTW3 Women’s Fine Welded Monofilament Wig With Lace Front Wholesale",
        },
        {
          img: require("../assets/index/ntw3-womens-mono.jpg"),
          img1: require("../assets/index/njc610-mono-with-pu-womens.jpg"),
          h6: "NTW3 Women’s Fine Welded Monofilament Wig With Lace Front Wholesale",
        },
      ],
      rewiewleft_div1: [
        {
          title:
            "1 review for IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          img: require("../assets/index/head-portrait-64x64.jpg"),
        },
      ],
      fontimg: [
        {
          p1: "Rated 5 out of 5Juan K. (verified owner) – 06/20/2021",
          imgs: require("../assets/logoimgs/xing.png"),
          p2: "The front hairline is so natural-looking with underhair, my customers are really happy with how their front is like",
        },
      ],
      rights: [
        {
          h3: "Add a review",
          rightp1:
            "Your email address will not be published. Required fields are marked *",
          rightp2: "Your rating *",
        },
      ],
      font: [
        {
          h4: "RETURN POLICY",
          p: "New Times Hair never stops providing the best products and services for our customers. If you are not satisfied with your order for any reason, including the order arriving damaged, you ordered the wrong size, or the product simply doesn’t meet your expectations, etc. you can ask for a return or exchange within 30 days from the purchase date.",
        },
        {
          h4: "Our Return/Exchange Procedure",
          p: "Step 1: Request a return/exchange via DM or Email and provide us with your order number, detailed reason for the return, and images of the damage if there is any.Step 2: Our customer service or sales representatives will authorize your request and send you the address to ship your item(s) back.Step 3: All returned items will be inspected when we receive them to check if they qualify for a return.*N/B: items that will not be approved for a return: stock orders that have been altered, worn, washed, damaged, or are not in their original packaging.Step 4: Once your return is accepted, we will process a refund to your original payment account or ship the item(s) you requested to you within 3-5 business days.",
        },
        {
          h4: "Please find answer to our most frequently asked questions below:",
          p: "New Times Hair never stops providing the best products and services for our customers. If you are not satisfied with your order for any reason, including the order arriving damaged, you ordered the wrong size, or the product simply doesn’t meet your expectations, etc. you can ask for a return or exchange within 30 days from the purchase date.",
        },
      ],
    };
  },
  //图片放大镜
  // created(){
  //    window.addEventListener('load', function () {
  //           var box = document.querySelector('.box');
  //           var yy = document.querySelector('.yy');
  //           var big = document.querySelector('.big');
  //           box.addEventListener('mouseover', function () {
  //               yy.style.display = 'block';
  //               big.style.display = 'block';
  //           })
  //           box.addEventListener('mouseout', function () {
  //               yy.style.display = 'none';
  //               big.style.display = 'none';
  //           })
  //           box.addEventListener('mousemove', function (e) {
  //               var x = e.pageX - box.offsetLeft;
  //               var y = e.pageY - box.offsetTop;
  //               var width = x - yy.offsetWidth / 2;
  //               var height = y - yy.offsetHeight / 2;
  //               if (width <= 0) {
  //                   width = 0;
  //               } else if (width >= box.offsetWidth - yy.offsetWidth) {
  //                   width = 100;
  //               }
  //               if (height <= 0) {
  //                   height = 0;
  //               } else if (height >= box.offsetHeight - yy.offsetHeight) {
  //                   height = box.offsetHeight - yy.offsetHeight;
  //               }
  //               yy.style.left = width + 'px';
  //               yy.style.top = height + 'px';
  //               var bigimg = document.querySelector('.big>img');
  //               // 大图片的移动距离=遮挡层移动距离*大图片最大移动距离/遮挡层最大移动距离
  //               var bigx = width * (bigimg.offsetWidth - big.offsetWidth) / (box.offsetWidth - yy.offsetWidth);
  //               var bigy = height * (bigimg.offsetHeight - big.offsetHeight) / (box.offsetHeight - yy.offsetHeight);
  //               bigimg.style.left = -bigx + 'px';
  //               bigimg.style.top = -bigy + 'px';
  //           })
  //       })

  // },
  computed: {
    ImageList() {
      return this.skuImageList[this.currentIndex] || {};
    },
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  mounted() {
    var swiperInstance = new Swiper(".swiper-container", {
      // config...
    });

    var galleryThumbs = new Swiper(".gallery-thumbs", {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
    var galleryTop = new Swiper(".gallery-top", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    });
  },
  methods: {
    //这里是放大方法的主要处理逻辑
    // handler(event) {
    //   let mask = this.$refs.mask;
    //   let big = this.$refs.big;
    //   let left = event.offsetX - mask.offsetWidth/2;
    //   let top = event.offsetY - mask.offsetHeight/2;
    //   //约束范围
    //   if (left <= 0) left = 0;
    //   if (left >= mask.offsetWidth) left = mask.offsetWidth;
    //   if (top <= 0) top = 0;
    //   if (top >= mask.offsetHeight) top = mask.offsetHeight;
    //   mask.style.left = left + "px";
    //   mask.style.top = top + "px";
    //   big.style.left = -2 * left + "px";
    //   big.style.top = -2 * top + "px";
    //   console.log(left);
    // },
    choice(index) {
      console.log(index, "66");
    },
    handleChange(value) {
      console.log(value);
    },
    submit() {},
    submits() {
      console.log("111");
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    ging(index) {
      if (index == 0) {
        this.$router.push({
          path: "/",
        });
      }
      console.log(index, "ging66");
    },
  },
};
</script>
<style lang="less">
//放大镜功能
//  .box {
//             position: relative;
//             width: 350px;
//             height: 350px;
//         }

//         .yy {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 250px;
//             height: 250px;
//             background-color: yellow;
//             opacity: 0.5;
//             border: 1px solid #333;
//             display: none;
//         }

//         .big {
//             position: absolute;
//             left: 360px;
//             top: 0px;
//             width: 500px;
//             height: 500px;
//             overflow: hidden;
//             display: none;
//         }

//         .big>img {
//             position: absolute;
//             top: 0;
//             left: 0;
//         }

.viewdetail {
  width: 100%;
  .dis {
    width: 75%;
    margin: 40px auto;
    display: flex;
    justify-content: space-around;
    .all {
      // background: #000;
      width: 55%;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 14px;
      color: #fff;
      margin: 0;
      padding: 0;
      img {
        width: 70%;
        height: 500px;
      }
    }
    .swiper-container {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
    .gallery-top {
      height: 80%;
      width: 100%;
      // border: #f00 1px solid;
      img {
        transform: scale(1.2);
        width: 100%;
        padding-left: 7%;
      }
    }
    .gallery-thumbs {
      height: 25%;
      box-sizing: border-box;
      padding: 10px 0;
    }
    .gallery-thumbs .swiper-slide {
      width: 20%;
      height: 100%;
      opacity: 0.4;
      img {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
      }
    }
    .gallery-thumbs .swiper-slide-thumb-active {
      opacity: 1;
    }
  }
  .right {
    width: 42%;
    //css如何添加滚动条
    overflow-y: scroll; /*只是y方向*/
    height: 610px;
    // border: 1px solid #f00;
    .right_listing {
      padding: 10px 2%;
      img {
        width: 4%;
      }
    }
    .btn {
      background-color: #012340;
      color: #fff;
      width: 100%;
      border: 1px solid #012340;
    }
    .btn:hover {
      background-color: #f29d35;
      border: 1px solid #f29d35;
    }
    .apply {
      color: #518ff7;
    }
    .butdiv {
      button {
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #cccccc;
        margin: 0 2%;
        padding: 5px 5%;
        color: #353c4e;
      }
      button:hover {
        border: 1px solid #353c4e;
        background-color: #353c4e;
        color: #fff;
      }
    }
    .elbtn {
      background-color: #8091a0;
      border: 1px solid #8091a0;
      padding: 4px 3%;
      color: #fff;
      margin-left: 2%;
      border-radius: 7px;
    }
  }
  .downtab {
    width: 75%;
    margin: 40px auto;
  }
  .imgcont {
    text-align: center;
    div {
      margin: 40px 0;
      p {
        padding: 20px 0;
      }
    }
    img {
      width: 90%;
    }
  }
  .rewiew {
    display: flex;
    justify-content: space-around;
    .rewiewleft {
      width: 50%;
      // border: #f00 1px solid;
      margin: 20px 0;
      .rewiewleft_div1 {
        color: #2a2a2a;

        .disdiv {
          margin: 25px 0;
          display: flex;
          justify-content: space-around;

          img {
            width: 7.5%;
            height: 45px;
          }

          .fontimg {
            width: 80%;
            img {
              width: 4%;
              height: 22px;
            }
          }
        }
      }
    }
    .rewiewright {
      color: #3e3e3e;
      width: 45%;
      border: #efefef 1px solid;
      margin: 20px 0;
      padding: 10px 2%;

      button {
        background-color: #012340;
        border: #012340 1px solid;
        padding: 10px 5%;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
  .fontss {
    margin-top: 20px;
  }

  .bgbg {
    height: 500px;
    line-height: 35px;
    width: 100%;
    background: url("../../src/assets/index/20220623160936.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #002548;
    div {
      width: 60%;
      margin: 0 auto;
      padding-top: 100px;
      text-align: center;
      p {
        font-size: 20px;
      }
      button {
        background-color: #012340;
        color: #fff;
        border: 1px solid #012340;
        padding: 5px 3%;
        margin-top: 10px;
        border-radius: 35px;
      }
    }
  }
  .Related {
    display: flex;
    justify-content: space-around;
    width: 75%;
    margin: 40px auto;
    .Relateds {
      width: 30%;
      text-align: left;
    }
    .img1 {
      display: none;
    }
    .Relateds:hover {
      .img1 {
        display: block;
      }
      .img {
        display: none;
      }
    }
  }
}
.mask {
  width: 50%;
  height: 50%;
  background-color: rgba(0, 255, 0, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}
.big {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -1px;
  left: 100%;
  border: 1px solid#aaa;
  overflow: hidden;
  z-index: 998;
  display: none;
  background: white;
  img {
    width: 200%;
    max-width: 200%;
    height: 200%;
    position: absolute;
    left: 0;
    top: 0mm;
  }
}
.modal-header {
  color: #fff;
  height: 270px;
  width: 100%;
  background: url("../../src/assets/index/GLUE.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.modal-dialog {
  max-width: 900px;
}
.CONTACT {
  text-align: center;

  padding: 50px 0;
  width: 80%;
  margin: 0 auto;
  h2 {
    font-weight: 600;
  }
  .inputs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    input {
      width: 45%;
      height: 50px;
      margin: 10px 0;
      border: none;
      border: 1px solid #cfd5db;
    }
  }
  textarea {
    border: none;
    border: 1px solid #cfd5db;
    width: 95%;
    height: 200px;
  }
  button {
    margin-top: 30px;
    background-color: #304e6a;
    border: 1px solid #304e6a;
    padding: 10px 1.5%;
    border-radius: 5px;
    color: #fff;
  }
  button:hover {
    background-color: #f9bd5a;
    border: 1px solid #f9bd5a;
  }
}

.top {
  img {
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
  }
}
.spec-preview {
  position: relative;
  width: 400px;
  height: 400px;
  border: 1px solid #ccc;

  img {
    width: 100%;
    height: 100%;
  }

  .event {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
  }

  .mask {
    width: 50%;
    height: 50%;
    background-color: rgba(171, 250, 171, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    display: none;
  }

  .big {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -1px;
    left: 100%;
    border: 1px solid #aaa;
    overflow: hidden;
    z-index: 998;
    display: none;
    background: white;

    img {
      width: 200%;
      max-width: 200%;
      height: 200%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .event:hover ~ .mask,
  .event:hover ~ .big {
    display: block;
  }
}
</style>