<template>
  <div class="Wholesale">
    <Nav />
    <div class="Wholesale_content">
      <div class="contentbanner">
        <img src="../assets/index/20220815160511.png" />
        <div class="position">
          <!-- <h1>NEW TIMES HAIR</h1> -->
        </div>
      </div>
      <div class="content_p">
        <p>
          We have a wide range of solutions that can be tailored to your hair
          replacement business’s particular needs. We aim to optimize and
          improve your supply chain, helping you gain a competitive advantage.
        </p>
        <p>
          Use our solution finder below to find the right solution for your
          business.
        </p>
      </div>
      <div class="Areyou">
        <h3>Are you a ...</h3>
        <!-- 选项卡切换 -->
        <div class="menu">
          <span @click="btn(1)" :class="{ show: tab === 1 }"
            >Wholesaler and Distributor</span
          >
          <span @click="btn(2)" :class="{ show: tab === 2 }"
            >Online Retailer</span
          >
          <span @click="btn(3)" :class="{ show: tab === 3 }"
            >Hair Stylist or Salon Owner</span
          >
        </div>
        <div class="content">
          <span v-if="tab == 1">
            <div class="content_dis">
              <div v-for="(item, index) in tablist" :key="index">
                <img :src="item.img" alt="" />
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </span>
          <span v-if="tab == 2">
            <div class="content_dis">
              <div v-for="(item, index) in tablist2" :key="index">
                <img :src="item.img" alt="" />
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </span>
          <span v-if="tab == 3">
            <div class="content_dis">
              <div v-for="(item, index) in tablist3" :key="index">
                <img :src="item.img" alt="" />
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </span>
        </div>
        <!-- 选项卡切换 -->
      </div>
      <div class="Areyous">
        <h3>Are you a ...</h3>
        <!-- 选项卡切换 -->
        <div class="menu">
          <span @click="btn(1)" :class="{ show: tab === 1 }"
            >Wholesaler and Distributor</span
          >
          <span @click="btn(2)" :class="{ show: tab === 2 }"
            >Online Retailer</span
          >
          <span @click="btn(3)" :class="{ show: tab === 3 }"
            >Hair Stylist or Salon Owner</span
          >
        </div>
        <div class="content">
          <span v-if="tab == 1">
            <div class="content_dis">
              <div v-for="(item, index) in tablist" :key="index">
                <img :src="item.img" alt="" />
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </span>
          <span v-if="tab == 2">
            <div class="content_dis">
              <div v-for="(item, index) in tablist2" :key="index">
                <img :src="item.img" alt="" />
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </span>
          <span v-if="tab == 3">
            <div class="content_dis">
              <div v-for="(item, index) in tablist3" :key="index">
                <img :src="item.img" alt="" />
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </span>
        </div>
        <!-- 选项卡切换 -->
      </div>
      <submitwho />
    </div>
    <div class="top" @click="toTop">
      <img
        style="width: 3%; height: 50px"
        src="../../src/assets/index/xiala_un@2x.png"
        alt=""
      />
    </div>
    <div class="tops" @click="toTop">
      <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
import submitwho from "../components/submitwho.vue";
export default {
  components: {
    Footer,
    Nav,
    submitwho,
  },
  data() {
    return {
      one: "",
      tab: 1,
      tablist: [
        {
          img: require("../../src/assets/index/are1.png"),
          title: "Extremely Competitive SVIP Wholesale Price",
          content:
            "TsingTao Hair WigsZone offers exclusive discount pricing on hair systems to clients who order in bulk. With our competitive wholesale prices, your service will stay ahead of the competition and your revenue will enjoy the benefits.",
        },
        {
          img: require("../../src/assets/index/are2.png"),
          title: "Special Discount for Sample Orders",
          content:
            "TsingTao Hair WigsZone offers a generous discount for your first sample order even if you only order 1 piece. This way you’ll have the opportunity to experience our hair systems with little cost and zero risk to you.",
        },
        {
          img: require("../../src/assets/index/are3.png"),
          title: "Consistent Top Product Quality",
          content:
            " We have a quality inspection system that guarantees consistent quality throughout production. By offering our products to your customers, your brand will build its reputation and your business can grow.",
        },
        {
          img: require("../../src/assets/index/are4.png"),
          title: "Exceptional Production Capacity",
          content:
            " In addition to an advanced supply chain system, we have a high production capacity that’s capable of producing up to 150,000 hair systems per year, while being unaffected by COVID-19.",
        },
        {
          img: require("../../src/assets/index/are1.png"),
          title: "Extremely Competitive SVIP Wholesale Price",
          content:
            "TsingTao Hair WigsZone offers exclusive discount pricing on hair systems to clients who order in bulk. With our competitive wholesale prices, your service will stay ahead of the competition and your revenue will enjoy the benefits.",
        },
        {
          img: require("../../src/assets/index/are2.png"),
          title: "Special Discount for Sample Orders",
          content:
            "TsingTao Hair WigsZone offers a generous discount for your first sample order even if you only order 1 piece. This way you’ll have the opportunity to experience our hair systems with little cost and zero risk to you.",
        },
        {
          img: require("../../src/assets/index/are3.png"),
          title: "Consistent Top Product Quality",
          content:
            " We have a quality inspection system that guarantees consistent quality throughout production. By offering our products to your customers, your brand will build its reputation and your business can grow.",
        },
        {
          img: require("../../src/assets/index/are4.png"),
          title: "Exceptional Production Capacity",
          content:
            " In addition to an advanced supply chain system, we have a high production capacity that’s capable of producing up to 150,000 hair systems per year, while being unaffected by COVID-19.",
        },
      ],
      tablist2: [
        {
          img: require("../../src/assets/index/are2.png"),
          title: "Extremely Competitive SVIP Wholesale Price",
          content:
            "TsingTao Hair WigsZone offers exclusive discount pricing on hair systems to clients who order in bulk. With our competitive wholesale prices, your service will stay ahead of the competition and your revenue will enjoy the benefits.",
        },
        {
          img: require("../../src/assets/index/are3.png"),
          title: "Special Discount for Sample Orders",
          content:
            "TsingTao Hair WigsZone offers a generous discount for your first sample order even if you only order 1 piece. This way you’ll have the opportunity to experience our hair systems with little cost and zero risk to you.",
        },
        {
          img: require("../../src/assets/index/are1.png"),
          title: "Consistent Top Product Quality",
          content:
            " We have a quality inspection system that guarantees consistent quality throughout production. By offering our products to your customers, your brand will build its reputation and your business can grow.",
        },
        {
          img: require("../../src/assets/index/are4.png"),
          title: "Exceptional Production Capacity",
          content:
            " In addition to an advanced supply chain system, we have a high production capacity that’s capable of producing up to 150,000 hair systems per year, while being unaffected by COVID-19.",
        },
        {
          img: require("../../src/assets/index/are1.png"),
          title: "Extremely Competitive SVIP Wholesale Price",
          content:
            "TsingTao Hair WigsZone offers exclusive discount pricing on hair systems to clients who order in bulk. With our competitive wholesale prices, your service will stay ahead of the competition and your revenue will enjoy the benefits.",
        },
        {
          img: require("../../src/assets/index/are2.png"),
          title: "Special Discount for Sample Orders",
          content:
            "TsingTao Hair WigsZone offers a generous discount for your first sample order even if you only order 1 piece. This way you’ll have the opportunity to experience our hair systems with little cost and zero risk to you.",
        },
        {
          img: require("../../src/assets/index/are3.png"),
          title: "Consistent Top Product Quality",
          content:
            " We have a quality inspection system that guarantees consistent quality throughout production. By offering our products to your customers, your brand will build its reputation and your business can grow.",
        },
        {
          img: require("../../src/assets/index/are4.png"),
          title: "Exceptional Production Capacity",
          content:
            " In addition to an advanced supply chain system, we have a high production capacity that’s capable of producing up to 150,000 hair systems per year, while being unaffected by COVID-19.",
        },
      ],
      tablist3: [
        {
          img: require("../../src/assets/index/are4.png"),
          title: "Extremely Competitive SVIP Wholesale Price",
          content:
            "TsingTao Hair WigsZone offers exclusive discount pricing on hair systems to clients who order in bulk. With our competitive wholesale prices, your service will stay ahead of the competition and your revenue will enjoy the benefits.",
        },
        {
          img: require("../../src/assets/index/are3.png"),
          title: "Special Discount for Sample Orders",
          content:
            "TsingTao Hair WigsZone offers a generous discount for your first sample order even if you only order 1 piece. This way you’ll have the opportunity to experience our hair systems with little cost and zero risk to you.",
        },
        {
          img: require("../../src/assets/index/are2.png"),
          title: "Consistent Top Product Quality",
          content:
            " We have a quality inspection system that guarantees consistent quality throughout production. By offering our products to your customers, your brand will build its reputation and your business can grow.",
        },
        {
          img: require("../../src/assets/index/are4.png"),
          title: "Exceptional Production Capacity",
          content:
            " In addition to an advanced supply chain system, we have a high production capacity that’s capable of producing up to 150,000 hair systems per year, while being unaffected by COVID-19.",
        },
        {
          img: require("../../src/assets/index/are1.png"),
          title: "Extremely Competitive SVIP Wholesale Price",
          content:
            "TsingTao Hair WigsZone offers exclusive discount pricing on hair systems to clients who order in bulk. With our competitive wholesale prices, your service will stay ahead of the competition and your revenue will enjoy the benefits.",
        },
        {
          img: require("../../src/assets/index/are2.png"),
          title: "Special Discount for Sample Orders",
          content:
            "TsingTao Hair WigsZone offers a generous discount for your first sample order even if you only order 1 piece. This way you’ll have the opportunity to experience our hair systems with little cost and zero risk to you.",
        },
        {
          img: require("../../src/assets/index/are3.png"),
          title: "Consistent Top Product Quality",
          content:
            " We have a quality inspection system that guarantees consistent quality throughout production. By offering our products to your customers, your brand will build its reputation and your business can grow.",
        },
        {
          img: require("../../src/assets/index/are4.png"),
          title: "Exceptional Production Capacity",
          content:
            " In addition to an advanced supply chain system, we have a high production capacity that’s capable of producing up to 150,000 hair systems per year, while being unaffected by COVID-19.",
        },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
      options: [
        {
          value: "选项1",
          label: "Stock hair system",
        },
        {
          value: "选项2",
          label: "Custom hair system",
        },
        {
          value: "选项3",
          label: "Afro toupee",
        },
      ],
      options2: [
        {
          value: "选项1",
          label: "Hair system for hair loss",
        },
        {
          value: "选项2",
          label: "Human hair wig",
        },
        {
          value: "选项3",
          label: "Hair topper",
        },
        {
          value: "选项4",
          label: "Hair extension",
        },
        {
          value: "选项5",
          label: "Full lace & lace front wig",
        },
        {
          value: "选项6",
          label: "Hair bundle",
        },
      ],
      value1: [],
      value2: [],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    btn(key) {
      this.tab = key;
    },
    submit() {},
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    submits() {
      // if(this.one == ""){
      //   alert("请填写姓名")
      //   return
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.tops {
  display: none;
}
.CONTACTs {
  display: none;
}
.top {
  img {
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
  }
}
.Areyous {
  display: none;
}
.Wholesale {
  width: 100%;
  background-color: #fff;
  .Wholesale_content {
    .contentbanner {
      position: relative;
      img {
        width: 100%;
        // background-size: 100% 100%;
        // height: 500px;
      }
      .position {
        text-shadow: #01244b 3px 3px;
        position: absolute;
        left: 35%;
        top: 250px;
        color: #fff;
        h1 {
          font-weight: 700;
        }
        p {
          font-size: 24px;
        }
      }
    }
    .content_p {
      width: 70%;
      margin: 60px auto;
    }
    .Areyou {
      width: 70%;
      margin: 0 auto;
      // padding: 40px;
      text-align: center;
      h3 {
        font-weight: 700;
      }
      .menu {
        display: flex;
        align-items: center;
      }
      .menu span {
        color: #444950;
        font-size: 18px;
        line-height: 24px;
        background: #f5f6f7;
        width: 30%;
        padding: 10px 2%;
        display: block;
        cursor: pointer;
      }
      .show {
        color: #fff !important;
        background: #012340 !important;
      }
      .content {
        .content_dis {
          padding: 40px 0 0px;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          div {
            width: 22%;

            img {
              width: 50%;
            }
            h5 {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .CONTACT {
      text-align: center;
      padding: 50px 0;
      width: 80%;
      margin: 0 auto;
      h2 {
        font-weight: 600;
      }
      .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        input {
          width: 45%;
          height: 50px;
          margin: 8px 0;
          border: none;
          border: 1px solid #cfd5db;
        }
      }
      textarea {
        border: none;
        border: 1px solid #cfd5db;
        width: 95%;
        height: 200px;
      }
      button {
        margin-top: 30px;
        background-color: #304e6a;
        border: 1px solid #304e6a;
        padding: 10px 1.5%;
        border-radius: 5px;
        color: #fff;
      }
      button:hover {
        background-color: #f9bd5a;
        border: 1px solid #f9bd5a;
      }
    }
    .eselect {
      width: 98%;
      display: flex;
      justify-content: space-around;
    }
  }
}
@media screen and (max-width: 390px) {
  .Areyou {
    display: none;
  }
  .Areyous {
    display: block;
    width: 90%;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
    h3 {
      font-weight: 700;
    }
    // .menu {
    //   // display: flex;
    //   // align-items: center;
    // }
    .menu span {
      color: #444950;
      font-size: 18px;
      line-height: 24px;
      background: #f5f6f7;
      width: 90%;
      padding: 10px 2%;
      display: block;
      cursor: pointer;
    }
    .show {
      color: #fff !important;
      background: #012340 !important;
    }
    .content {
      .content_dis {
        // padding: 40px 0;
        // display: flex;
        // justify-content: space-around;
        // flex-wrap: wrap;
        div {
          width: 90%;

          img {
            width: 50%;
          }
          h5 {
            padding: 10px 0;
          }
        }
      }
    }
  }
  .CONTACT {
    display: none;
  }
  .CONTACTs {
    text-align: center;
    display: block;
    padding: 50px 0;
    width: 90%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      input {
        width: 100%;
        height: 50px;
        border: none;
        border: 1px solid #cfd5db;
        margin: 10px 0;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }

  .top {
    display: none;
  }

  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
}
</style>
