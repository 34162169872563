<template>
  <div class="helpones">
    <Nav @gethelpslist="gethelpslist" />
    <div class="helponescont">
      <div class="cont_left">
        <h4>Categories</h4>
        <router-link to="/Attaching"><h6>HELP CENTER</h6></router-link>
        <el-row class="tac">
          <el-col :span="12" style="width: 85%">
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
            >
              <el-submenu
                :index="index + ''"
                v-for="(item, index) in helpList"
                :key="index"
              >
                <template slot="title">
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    :index="index + '-' + sindex"
                    v-for="(items, sindex) in item.list"
                    :key="sindex"
                    ><router-link
                      :to="{
                        name: 'Attaching',
                        query: { id: items.article_id },
                      }"
                      >{{ items.article_title }}</router-link
                    ></el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="cont_right">
        <el-row>
          <el-col
            :span="8"
            v-for="(o, index) in 5"
            :key="o"
            :offset="index > 10 ? 5 : 0"
            style="padding: 7.5px"
          >
            <el-card
              :body-style="{ padding: '0px' }"
              class="cardBody"
              shadow="hover"
            >
              <!-- <el-image :src="url"> </el-image> -->
              <!-- <el-image :src="require(url)">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image> -->
              <div style="padding: 14px">
                <span>好吃的汉堡</span>
                <div class="bottom clearfix">
                  <time class="time">123</time>
                  <el-button type="text" class="button">操作按钮</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
// import getArticleOneApi from "../../api/index ";
import { getArticleOneApi } from "../../api/index";
export default {
  components: { Footer, Nav },
  data() {
    return {
      title: "",
      id: null,
      content: "",
      helpList: [],
      url: "../../assets/404.jpg",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getArticleOne();
    this.gethelpslist();
  },
  methods: {
    /**
     * 从nav组建中获取已经得到的新闻导航 数据
     */
    gethelpslist(e) {
      this.helpList = e;
    },
    getArticleOne() {
      getArticleOneApi(this.id).then((res) => {
        if (res && res.code && res.code == 1 && res.data && res.data != "") {
          this.title = res.data.article_title;
          this.count = res.data.article_content;
        }
      });
    },
    // getArticleList() {
    //   articleListData = {}
    //   articleListData.category_id
    //   article(this.id).then((res) => {
    //     if (res && res.code && res.code == 1 && res.data && res.data != "") {
    //       this.title = res.data.article_title;
    //       this.count = res.data.article_content;
    //     }
    //   });
    // },
    handleOpen() {},
    handleClose() {},
  },
};
</script>
<style lang="less" scoped>
a {
  list-style: none;
  text-decoration: none;
  color: #000;
}
.helpones {
  width: 100%;
  .helponescont {
    width: 80%;
    margin: 50px auto;
    display: flex;
    justify-content: space-around;
    .cont_left {
      width: 30%;
      h4 {
        border-bottom: 1px solid #e5e5e5;
        width: 85%;
        padding-bottom: 7px;
      }
      h6 {
        padding: 5px 0;
      }
      .el-menu {
        border-right: none;
      }
      .el-submenu .el-menu-item {
        height: 41px;
        line-height: 41px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .cont_right {
      width: 65%;
    }
  }
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>