<template>
  <div class="lordhair">
        <Nav />
    <div class="contentbanner">
      <img src="../assets/index/20220622102444.png" />
      <!-- <div class="position">
                <h2>WHY WIGSZONE FOR YOUR HAIR REPLACEMENT?</h2> 
                </div> -->
    </div>
    <div class="listing">
      <div v-for="(item, index) in list" :key="index" class="list">
        <div>
          <div class="listdis">
            <img :src="item.img" alt="" />
            <h5>{{ item.title }}</h5>
          </div>
          <p>
            {{ item.content }}
          </p>
        </div>
        <img :src="item.imgs" alt="" />
      </div>
    </div>
     <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
      components: {
    Footer,
    Nav,
  },
  data() {
    return {
      list: [
        {
          img: require("../assets/logoimgs/dui.png"),
          title: "Non-Surgical Hair Replacement",
          content:
            "WigsZone cares deeply about people battling hair loss whatever their circumstances and our collection of medical wigs reflect our commitment to hair recovery amongst people suffering from illness or medical conditions.",
        },
        {
          img: require("../assets/logoimgs/dui.png"),
          title: "Ready To Wear",
          content:
            "WigsZone crafts products of the highest quality that only use 100% human hair (Indian hair, European hair, or cuticle intact virgin hair). We can also cut and style your hair system before shipment meaning it will be ready-to-wear the minute you receive it.",
        },
        {
          img: require("../assets/logoimgs/dui.png"),
          title: "Affordable Factory Prices",
          content:
            "WigsZone is a global online supplier offering hair replacement solutions at unbeatable prices as we are TsingTaoHair factory direct store and we have factory direct prices. We can therefore control quality and eliminate unnecessary costs and middleman charges. The result being that you will get the lowest possible prices with quality guaranteed.",
        },
        {
          img: require("../assets/logoimgs/dui.png"),
          title: "30-Day Money Back Guarantee",
          content:
            "Every hair system comes with a 30 days guarantee for any manufacturing mistakes or quality defects. So, if you're unsatisfied with your hair system in any way after receiving it then we will repair or remake it for free or give you a refund.",
        },
        {
          img: require("../assets/logoimgs/dui.png"),
          title: "Easy To Order Online",
          content:
            "We have developed a very user-friendly website that you can navigate freely. Our search tools and multiple filters and tags make it easy to find whatever it is you're looking for. Our online order form is highly visual and orders can be easily placed through it in a matter of minutes regardless of your specifications.",
        },
        {
          img: require("../assets/logoimgs/dui.png"),
          title: "Safe Online Payment",
          content:
            "WigsZone offers you a wide variety of payment options including major credit and debit cards, wire transfer, Western Union and PayPal. Our website is secured by PayPal and safeguarded by Norton from Symantec Company. Rest assured that your information is safe at all times.",
        },
        {
          img: require("../assets/logoimgs/dui.png"),
          title: "Fast Worldwide Free Shipping",
          content:
            "We have partnered with internationally trusted logistic service providers such as DHL, FedEx and UPS meaning we ship to over 200 countries around the world. What's more you can expect to receive your hair system in less than a week, maybe even just three days. Shipments are in unlabeled packages so no one need know you are purchasing a hair replacement system.",
        },
        {
          img: require("../assets/logoimgs/dui.png"),
          title: "Professional Customer Service",
          content:
            "WigsZone offers excellent, comprehensive customer service every step of the way. Before you order, feel free to take advantage of our live chat where our skilled and experienced professionals can give you sound advice. Once you’ve placed an order, our customer service representatives will continue to be on-hand to answer any further questions you may have.",
        },
        {
          img: require("../assets/logoimgs/dui.png"),
          title: "Frequent Order Status Updates",
          content:
            "You can always contact us to check your order status. We will keep you informed of your order and shipping status so there will never be a need to worry.",
        },
        {
          imgs: require("../assets/index/20220622111217.png"),
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.lordhair {
  .contentbanner {
    img {
      width: 100%;
      height: 700px;
      background-size: 100% 100%;
    }
  }
  .listing {
    width: 80%;
    margin: 0 auto;
    .list {
      margin: 25px 0;
      .listdis {
        padding: 10px 0;
        display: flex;
        justify-content: left;
        img {
          width: 3%;
        }
      }
    }
  }
}
</style>