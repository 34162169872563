<template>
  <div>
    <Nav />
    <div class="contact">
      <div class="contentbanner">
        <img src="../assets/index/banner@2x.png" />
        <div class="position">
          <h1>WE‘RE HERE, HOW CAN WE HELP?</h1>
        </div>
      </div>
      <div class="new">
        <h2>NEW TIMES HAIR BUSINESS HOURS</h2>
        <p>Please contact us at the following time</p>
        <div class="new_center">
          <ul class="ulleft">
            <li v-for="(item, index) in list" :key="index">{{ item.lis }}</li>
          </ul>
          <ul class="ulright">
            <li v-for="(item, index) in list2" :key="index">{{ item.lis }}</li>
          </ul>
        </div>
        <p class="p">
          Have a question during our non-business hours? Please leave a message
          on the form below or WhatsApp us. We promise to respond to you within
          12 hours.
        </p>
      </div>
      <submit />

      <div class="three">
        <div v-for="(item, index) in threes" :key="index">
          <img :src="item.img" alt="" />
          <h3>{{ item.title }}</h3>
          <p>{{ item.content }}</p>
          <router-link to=""
            ><h5>{{ item.xinxi }}</h5></router-link
          >
        </div>
      </div>
      <!-- Follow us on social media now! -->
      <div class="Follow">
        <div class="disFollow">
          <h1>Follow us on social media now!</h1>
          <div class="Follow_img">
            <router-link to="" class="Follow_img1">
              <img src="../../src/assets/index/bfff.png" alt="" class="a1" />
              <img src="../../src/assets/index/ffff.png" alt="" class="a2" />
            </router-link>
            <router-link to="" class="Follow_img2">
              <img src="../../src/assets/index/bxxxx.png" alt="" class="a1" />
              <img src="../../src/assets/index/xxxx.png" alt="" class="a2" />
            </router-link>
            <router-link to="" class="Follow_img3">
              <img src="../../src/assets/index/bvvvv.png" alt="" class="a1" />
              <img src="../../src/assets/index/vvvv.png" alt="" class="a2" />
            </router-link>
            <router-link to="" class="Follow_img4">
              <img src="../../src/assets/index/bqqqq.png" alt="" class="a1" />
              <img src="../../src/assets/index/qqqq.png" alt="" class="a2" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="Become">
        <div class="Become_dis">
          <img
            src="../../src/assets/index/Company-Album41024x800.jpg"
            style="height: 500px; width: 50%; margin-left: 22%"
          />
          <div class="Become_bg">
            <h2>Become a brand ambassador</h2>
            <ul>
              <li
                v-for="(item, index) in listli"
                :key="index"
                style="opacity: 1"
              >
                {{ item.li }}
              </li>
            </ul>
            <p style="opacity: 1">
              And most importantly – be a part of a supportive and uplifting
              community of hair replacement specialists worldwide!
            </p>
            <p style="text-align: center; font-weight: 600">
              It’s a rare opportunity, so don’t hesitate to get in before your
              competition!
            </p>
            <button @click="inquiryNow">Apply now</button>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
import Submit from "../components/submit.vue";
export default {
  components: {
    Footer,
    Nav,
    Submit,
  },
  data() {
    return {
      one: "",
      list: [
        { lis: "United Kingdom" },
        { lis: "United States" },
        { lis: "Australia" },
        { lis: "Dubai" },
        { lis: "India" },
      ],
      list2: [
        { lis: "(GMT) : 00:30am - 09:30am" },
        { lis: "(GMT-6) Central Standard Time: 6: 30pm-3：30am" },
        { lis: "(GMT-8) Pacific Standard Time: 4: 30pm-1：30am" },
        { lis: "(GMT+11) : 11: 30am-8: 30pm" },
        { lis: "(GMT+5:30) : 6: 00am-3: 00pm" },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
      threes: [
        {
          img: require("../../src/assets/index/Contact-us@2x.png"),
          title: "Email us",
          content:
            "If email is the preferred way of contact then shoot us a message.",
          xinxi: "info@newtimeshair.com",
        },
        {
          img: require("../../src/assets/index/Call-us@2x.png"),
          title: "WhatsApp us",
          content:
            "Contact us if you have any questions about the products or services we offer.",
          xinxi: "+86 18661939293",
        },
        {
          img: require("../../src/assets/index/Visit-us@2x (1).png"),
          title: "Visit us",
          content:
            "Our doors are open to anyone interested in our products or services.",
          xinxi: "Come check us out!.com",
        },
      ],
      listli: [
        { li: " FREE Hair Systems and Exciting Coupon Codes " },
        { li: " Exclusive WHOLESALE Discounts " },
        { li: "  Business Start-Up Guidance " },
        { li: "  Gain greater Social Media Exposure " },
      ],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
  },
  methods: {
    //inquiryNow
    inquiryNow() {
      this.$router.push({ path: "/Inquirynow" });
    },
    submit() {},
    submits() {},
  },
};
</script>
<style lang="less" scoped>
li {
  list-style: none;
}
.CONTACTs {
  display: none;
}
.contact {
  width: 100%;
  .contentbanner {
    position: relative;
    img {
      width: 100%;
      height: 700px;
    }
    .position {
      text-shadow: #01244b 3px 3px;
      position: absolute;
      left: 35%;
      top: 250px;
      color: #fff;
      h1 {
        font-weight: 700;
      }
      p {
        font-size: 24px;
      }
    }
  }
  .new {
    width: 70%;
    margin: 30px auto;
    text-align: center;
    .new_center {
      background-color: #f8f8f8;
      line-height: 45px;
      padding: 10px 2%;
      text-align: left;
      display: flex;
      justify-content: space-around;
      .ulleft {
        li {
          font-weight: 600;
          font-size: 19px;
        }
      }
      .ulright {
        padding-right: 30%;
        li {
          color: #9b9b9b;
          font-size: 18px;
        }
      }
    }
    .p {
      background-color: #454b5d;
      color: #fff;
      padding: 17px 1%;
    }
  }
  .CONTACT {
    text-align: center;

    padding: 50px 0;
    width: 80%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      input {
        width: 45%;
        height: 50px;
        margin: 20px 0;
        border: none;
        border: 1px solid #cfd5db;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }
  .three {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    div {
      width: 30%;
      text-align: center;
      img {
        width: 40%;
      }
      a {
        text-decoration: none;
        color: #012340;
      }
      a:hover {
        color: #f29d35;
      }
    }
  }
  .Follow {
    padding-top: 50px;
    .disFollow {
      background-color: #2e353d;
      color: #fff;
      padding: 20px 0;
      display: flex;
      justify-content: space-around;

      .Follow_img {
        display: flex;
        justify-content: space-around;
        img {
          width: 80%;
        }
        .Follow_img1 {
          .a1 {
            display: block;
          }
          .a2 {
            display: none;
          }
        }
        .Follow_img1:hover {
          .a2 {
            display: block;
          }
          .a1 {
            display: none;
          }
        }
        .Follow_img2 {
          .a1 {
            display: block;
          }
          .a2 {
            display: none;
          }
        }
        .Follow_img2:hover {
          .a2 {
            display: block;
          }
          .a1 {
            display: none;
          }
        }
        .Follow_img3 {
          .a1 {
            display: block;
          }
          .a2 {
            display: none;
          }
        }
        .Follow_img3:hover {
          .a2 {
            display: block;
          }
          .a1 {
            display: none;
          }
        }
        .Follow_img4 {
          .a1 {
            display: block;
          }
          .a2 {
            display: none;
          }
        }
        .Follow_img4:hover {
          .a2 {
            display: block;
          }
          .a1 {
            display: none;
          }
        }
      }
    }
  }
  .Become {
    // height: 500px;
    // width: 50%;
    // margin: 20px auto;
    // background: url();
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    // color: #012340;
    .Become_dis {
      position: relative;
    }
    .Become_bg {
      position: absolute;
      top: 25px;
      left: 25%;
      background-color: #fff;
      opacity: 0.7;
      width: 45%;
      padding-top: 50px;
      button {
        border: none;
        border: 1px solid #0a2840;
        background-color: #0a2840;
        color: #fff;
        padding: 5px 3%;
        margin: 20px 0;
        margin-left: 40%;
        text-align: center;
        border-radius: 18px;
        opacity: 1;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
        border-radius: 18px;
      }
      h2 {
        text-align: center;
        padding: 20px 0;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .CONTACT {
    display: none;
  }
  .CONTACTs {
    display: block;

    text-align: center;
    padding: 50px 0;
    width: 90%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      input {
        width: 100%;
        height: 50px;
        border: none;
        border: 1px solid #cfd5db;
        margin: 10px 0;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }
}
</style>